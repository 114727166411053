import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Card,
  Descriptions,
  Row,
  Col,
  Space,
  Image,
  Modal,
  message,
  Avatar,
} from "antd";
import { Link, useParams } from "react-router-dom";
import APICall from "../../../services/apiservices";
import moment from "moment";
const { Item } = Form;

const { confirm } = Modal;

const EmployeeDetails = () => {
  const params = useParams();
  const [visible, setVisible] = useState(false);

  const [empData, setEmpData] = useState({});
  const [bankName, setBankName] = useState("");
  const [bankAcc, setBankAcc] = useState("");
  const [accountName, setAccountName] = useState("");
  const [bankIfsc, setBankIfsc] = useState("");
  const [img, setImg] = useState();

  const showImage = (img) => {
    setImg(img);
    console.log(img);
    setVisible(true);
  };

  const getEmployee = async () => {
    const res = await APICall(`/employee/${params.employeeId}`, {}, 1);
    setEmpData(res.data);

    const bankLen = res.data.bankAccounts.length;
    console.log(res.data);
    if (bankLen > 0) {
      setBankName(res.data.bankAccounts[0].bankName);
      setBankAcc(res.data.bankAccounts[0].accountNumber);
      setBankIfsc(res.data.bankAccounts[0].ifscCode);
      setAccountName(res.data.bankAccounts[0].accountName);
    }

    console.log(res.data);
  };

  useEffect(() => {
    getEmployee();
  }, []);

  const handleResetpassword = () => {
    confirm({
      title: "Confirm Reset Password",
      content: " Are you sure you want to reset-password?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          const res = await APICall(
            `/user/${empData?.user?._id}/password`,
            {},
            3
          );
          if (res?.data?.data) {
            confirm({
              title: "Password Resete Successful",
              content: (
                <>
                  <Descriptions>
                    <Descriptions.Item label="Email">
                      {res?.data?.data?.email}
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions>
                    <Descriptions.Item label="Password">
                      {res?.data?.data?.password}
                    </Descriptions.Item>
                  </Descriptions>
                </>
              ),
              okType: "danger",
              okText: "Ok",
              cancelButtonProps: {
                style: { display: "none" }, // Hide the cancel button
              },
            });
          }
        } catch (error) {
          message.error(error.response.data.message);
        }
      },
    });
  };
  const handleSendPassword = () => {
    confirm({
      title: "Confirm Send Password",
      content: " Are you sure you want to send password?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          const res = await APICall(
            `/user/${empData?.user?._id}/password`,
            { sendEmail: true },
            3
          );
          message.success("Password sent successfully");
        } catch (error) {
          message.error(error);
        }
      },
    });
  };

  const handleDeactivate = async () => {
    confirm({
      title: `${
        empData?.user?.isActive ? "Disable" : "Enable"
      } employee account`,
      content: `Are you sure you want to ${
        empData?.user?.isActive ? "disable" : "enable"
      } employee ${empData?.user?.name}?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          let res;
          if (empData?.user?.isActive) {
            res = await APICall(`/user/${empData?.user?._id}/disable`, {}, 3);
          } else {
            res = await APICall(`/user/${empData?.user?._id}/enable`, {}, 3);
          }
          // setReload(true);
          if (res.data) {
            message.success(
              `Employee  ${
                empData?.user?.isActive ? "disabled" : "enabled"
              } successfully!`
            );
            getEmployee();
          }
        } catch (error) {
          // console.log(error);
          message.error(error.response.data.message);
        }
      },
    });
  };

  return (
    <Card
      title="Employee Details"
      extra={
        <Space>
          {empData?.user?.isActive && (
            <>
              <Link to={`/employees/${empData?._id}/offerLetter`}>
                <Button type="primary">Create Offer Letter</Button>
              </Link>
              <Button type="primary" onClick={handleResetpassword}>
                Reset Password
              </Button>
              <Button type="primary" onClick={handleSendPassword}>
                Send Password Via Email
              </Button>
              <Link to={`/employees/manage/${empData?._id}`}>
                <Button type="primary">Edit</Button>
              </Link>
            </>
          )}

          <Button type="primary" onClick={handleDeactivate}>
            {empData?.user?.isActive ? "Disable" : "Enable"}
          </Button>
        </Space>
      }
    >
      <Row gutter={10}>
        <Col span={24}>
          <Card
            title="Personal Information"
            bordered={false}
            style={{ marginBottom: "20px" }}
          >
            <Row gutter={10}>
              <Col span={3}>
                <Avatar
                  src={
                    empData?.user?.photoURL
                      ? empData?.user?.photoURL
                      : `https://xsgames.co/randomusers/avatar.php?g=pixel&key=1`
                  }
                  size={100}
                  onClick={() => {
                    showImage(empData?.user?.photoURL);
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                />
              </Col>
              <Col span={18}>
                <Descriptions layout="horizontal" bordered size="small">
                  <Descriptions.Item label="Name">
                    {empData?.user?.name}
                  </Descriptions.Item>

                  <Descriptions.Item label="Email">
                    {empData?.user?.email}
                  </Descriptions.Item>

                  <Descriptions.Item label="Date of birth">
                    {moment(empData?.user?.birthdate).format("DD/MM/YYYY")}
                  </Descriptions.Item>

                  <Descriptions.Item label="Aadhaar Number">
                    <Button
                      type="link"
                      onClick={() => {
                        showImage(empData?.aadhaarURL);
                      }}
                      size="small"
                    >
                      {empData?.user?.aadhaarId}
                    </Button>
                  </Descriptions.Item>

                  <Descriptions.Item label="Address">
                    {empData?.user?.address}
                  </Descriptions.Item>

                  <Descriptions.Item label="Mobile">
                    {empData?.user?.mobile}
                  </Descriptions.Item>

                  <Descriptions.Item label="Employee Qualification">
                    {empData?.qualification}
                  </Descriptions.Item>
                </Descriptions>
                <Image
                  preview={{
                    visible,
                    src: img,
                    onVisibleChange: (value) => {
                      setVisible(value);
                    },
                  }}
                />
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={24}>
          <Card
            title="Employee Information"
            bordered={false}
            style={{ marginBottom: "20px" }}
          >
            <Descriptions layout="horizonatal" bordered size="small">
              <Descriptions.Item label="Employee Code">
                {"FFSS/EMP/" +
                  empData?.employeeCode?.toString().padStart(4, "0")}
              </Descriptions.Item>

              <Descriptions.Item label="Date of Joining">
                {moment(empData?.joiningDate).format("DD/MM/YYYY")}
              </Descriptions.Item>

              <Descriptions.Item label="Salary per day">
                {empData?.salaryPerDay}
              </Descriptions.Item>

              <Descriptions.Item label="Employee Designation">
                {empData?.designation}
              </Descriptions.Item>

              <Descriptions.Item label="Salary">
                {empData?.salary}
              </Descriptions.Item>

              <Descriptions.Item label="Ot Rate Per Hour">
                {empData?.otRatePerHr}
              </Descriptions.Item>
              <Descriptions.Item label="Acess Type">
                {empData?.accessType}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>

        <Col span={24}>
          <Card
            title="Bank Account Information"
            bordered={false}
            style={{ marginBottom: "20px" }}
          >
            <Descriptions layout="horizontal" bordered size="small">
              <Descriptions.Item label="Bank Account Number">
                {bankAcc}
              </Descriptions.Item>

              <Descriptions.Item label="Account Holder Name">
                {accountName}
              </Descriptions.Item>

              <Descriptions.Item label="PAN Number">
                <Button
                  type="link"
                  onClick={() => {
                    showImage(empData?.panURL);
                  }}
                  size="small"
                >
                  {empData?.pan}
                </Button>
              </Descriptions.Item>

              <Descriptions.Item label="Bank Name">
                {bankName}
              </Descriptions.Item>

              <Descriptions.Item label="Bank IFSC Code">
                {bankIfsc}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col span={24}>
          <Card title="Documents" bordered={false}>
            <Descriptions layout="vertical" bordered size="small">
              <Descriptions.Item label="Aadhaar Card">
                <Image src={empData?.aadhaarURL} />
              </Descriptions.Item>

              <Descriptions.Item label="Pan Card">
                <Image src={empData?.panURL} />
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      </Row>
    </Card>
  );
};

export default EmployeeDetails;
