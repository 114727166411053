import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Row,
  Col,
  Card,
  message,
  Dropdown,
  Table,
  Modal,
  Radio,
} from "antd";
import dayjs from "dayjs";
import DebounceSelect from "../../../components/debounceSelect/DebounceSelect";
import TextArea from "antd/es/input/TextArea";
import APICall from "../../../services/apiservices";
import { useForm } from "antd/es/form/Form";
import { Select } from "antd";
import { LuPlus } from "react-icons/lu";
import moment from "moment";
const { confirm } = Modal;
const { Item } = Form;
const { Option } = Select;
const { RangePicker } = DatePicker;

const ManageAMC = ({ isEdit }) => {
  const [amcData, setAmcData] = useState();
  const [form] = Form.useForm();
  const [itemForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [value, setValue] = useState(undefined);
  const [employeeValue, setEmployeeValue] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVisits, setSelectedVisits] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [systems, setSystems] = useState([]);

  const showModal = () => {
    if (!form.getFieldValue("visitsCount")) {
      message.error("Visit count is required. Kindly update visit count.");
      return;
    }

    if (form.getFieldValue("visitsCount") == selectedVisits.length) {
      message.error("Kindly increase visits count to add more visits");
      return;
    }

    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    const formData = itemForm.getFieldsValue();
    console.log(formData);
    setEmployeeValue(undefined);
    if (
      selectedVisits.find(
        (v) =>
          moment(new Date(v.scheduledDate)).format("DD/MM/YYYY") ===
          moment(new Date(formData.scheduledDate)).format("DD/MM/YYYY")
      )
    ) {
      message.warning("Visit already added for selected date");
      return;
    }

    const visitsSelected = [...selectedVisits];

    if (!form.getFieldValue("contractDateRange")) {
      message.error("Please select contract date range");
      return;
    }
    const newVisits = generateVisits();
    console.log("new", newVisits);
    setSelectedVisits([...visitsSelected, formData, ...newVisits]);
    itemForm.resetFields();
  };

  const generateVisits = () => {
    const contractDateRange = form.getFieldValue("contractDateRange");
    const start = itemForm.getFieldValue("scheduledDate");
    const end = contractDateRange[1];
    const numberOfPartitions = form.getFieldValue("visitsCount") - 1;
    const differenceInDays = end.diff(start, "days");
    const daysPerPartition = differenceInDays / numberOfPartitions;
    const scheduledDates = [];
    for (let i = 0; i < numberOfPartitions; i++) {
      const partitionStart = start.add(i * daysPerPartition, "days");
      const partitionEnd = partitionStart.add(daysPerPartition, "days");
      scheduledDates.push({
        scheduledDate: partitionEnd,
        // description: `Visit ${++i + 1}`,
        //formate description like First Visit, Second Visit, Third Visit
        description: (() => {
          switch (i) {
            case 0:
              return "Second Visit";
            case 1:
              return "Third Visit";
            case 2:
              return "Fourth Visit";
            case 3:
              return "Fifth Visit";
            case 4:
              return "Sixth Visit";
            case 5:
              return "Seventh Visit";
            case 6:
              return "Eighth Visit";
            case 7:
              return "Ninth Visit";
            case 8:
              return "Tenth Visit";
            case 9:
              return "Eleventh Visit";
            case 10:
              return "Twelfth Visit";
            case 11:
              return "Thirteenth Visit";
            case 12:
              return "Fourteenth Visit";
            case 13:
              return "Fifteenth Visit";
            case 14:
              return "Sixteenth Visit";
            case 15:
              return "Seventeenth Visit";
            case 16:
              return "Eighteenth Visit";
            case 17:
              return "Nineteenth Visit";
            case 18:
              return "Twentieth Visit";
            case 19:
              return "Twenty-First Visit";
            case 20:
              return "Twenty-Second Visit";
            case 21:
              return "Twenty-Third Visit";
            case 22:
              return "Twenty-Fourth Visit";
            case 23:
              return "Twenty-Fifth Visit";
            case 24:
              return "Twenty-Sixth Visit";
            case 25:
              return "Twenty-Seventh Visit";
            case 26:
              return "Twenty-Eighth Visit";
            case 27:
              return "Twenty-Ninth Visit";
            case 28:
              return "Thirtieth Visit";
            case 29:
              return "Thirty-First Visit";
            case 30:
              return "Thirty-Second Visit";
            case 31:
              return "Thirty-Third Visit";
            case 32:
              return "Thirty-Fourth Visit";
            case 33:
              return "Thirty-Fifth Visit";
            case 34:
              return "Thirty-Sixth Visit";
            default:
              return "Invalid Visit";
          }
        })(),
      });
    }
    return scheduledDates;
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getAMCDetails = async () => {
    try {
      const response = await APICall(`/amc/${params.id}`, {}, 1);
      console.log(response);
      setAmcData(response.data);
      form.setFieldsValue({
        customerId: {
          label: response.data.customer.user.name,
          value: response.data.customer.user._id,
        },
        type: response.data.type,
        contractAmount: response.data.contractAmount,
        visitsCount: response.data.visitsCount,
        contactPersonId: {
          value: response.data.contactPerson._id,
          label: response.data.contactPerson.name,
        },
        contractDateRange: [
          dayjs(new Date(response.data.contractDate)),
          dayjs(new Date(response.data.expiryDate)),
        ],
        includeGST: response.data.includeGST,
        notes: response.data.notes,
        systemTypes: response.data.systemTypes.map((st) => {
          return {
            value: st.systemTypeId._id,
            label: st.systemTypeId.name,
          };
        }),
        workOrderNo: response.data.workOrderNo,
        duration: response.data.duration,
      });
      setSelectedVisits(response.data.visits);
      setIsLoading(false);
    } catch (error) {
      message.error(error.response.data.message);
      setIsLoading(false);
    }
  };

  const disabledDate = (currentDate) => {
    const contractDate = form.getFieldValue("contractDateRange");
    console.log(contractDate);
    const startDate = contractDate?.[0];
    const endDate = contractDate?.[1];
    return currentDate < startDate || currentDate > endDate;
  };

  const getSystems = async () => {
    try {
      const response = await APICall(
        `/organization/649bff0f87a35d7bececa3d8/systemType`,
        {},
        1
      );
      setSystems(response.data);
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  useEffect(() => {
    getSystems();
    if (isEdit) {
      setIsLoading(true);
      getAMCDetails();
    }
  }, []);

  const onFinish = async (values) => {
    if (selectedVisits.length != values.visitsCount) {
      message.error(`Please add ${values.visitsCount} visits`);
      return;
    }
    setIsLoading(!isLoading);
    if (isEdit) {
      const response = await APICall(`/amc/${params.id}`, values, 3);
      if (response.data) {
        setIsLoading(false);
        message.success("AMC has been updated successfully.");
        navigate("/amcs");
      }
      try {
      } catch (error) {
        setIsLoading(false);
        message.error(error.response.data.message);
      }
    } else {
      try {
        const data = { ...values };
        console.log("GST", values.includeGST);
        data.systemTypes = data.systemTypes.map((st) => ({ _id: st }));
        data.visits = selectedVisits;
        data.contractDate = data.contractDateRange[0];
        data.expiryDate = data.contractDateRange[1];
        delete data.contractDateRange;
        const response = await APICall(`/amc`, data, 2);
        setIsLoading(false);
        if (response.data) {
          message.success("AMC has been created successfully.");
          navigate(`/amcs`);
        }
      } catch (error) {
        setIsLoading(false);
        message.error(error.response.data.message);
      }
    }
  };

  const [customers, setCustomers] = useState([]);
  const [items, setItems] = useState([]);

  const fetchCustomerList = async (searchValue) => {
    const res = await APICall(
      `/customer?page=1&limit=30&query=${searchValue}`,
      {},
      1
    );
    return res.data.documents.map((customer) => ({
      label: customer.user.name,
      value: customer._id,
      data: customer,
    }));
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "name",
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Scheduled Date",
      dataIndex: "scheduledDate",
      render: (_, record) => {
        return moment(new Date(record.scheduledDate)).format("DD/MM/YYYY");
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (_, record) => {
        return record.description;
      },
    },
    {
      title: "Assigned To",
      dataIndex: "assignedTo",
      render: (_, record, index) => {
        return record?.data?.data?.user?.name;
      },
    },
    {
      title: "Actions",
      key: "actions",
      width: "15%",
      render: (_, record) => (
        <Dropdown
          menu={{
            items: [
              {
                key: 1,
                label: (
                  <span
                    style={{ color: "red" }}
                    onClick={() => {
                      if(params.id) {
                        message.warning('Cannot remove a visit')
                        return;
                      }
                      confirm({
                        title: `Remove ${record?.data?.name}`,
                        content: `Do you want to remove ${moment(
                          new Date(record?.data?.scheduledDate)
                        ).format("DD/MM/YYYY")} from quotation table?`,
                        okText: "Yes",
                        okType: "danger",
                        cancelText: "No",
                        onOk: () => {
                          const visitsSelected = [...selectedVisits];
                          setSelectedVisits([
                            ...visitsSelected.filter(
                              (v) => v.scheduledDate !== record.scheduledDate
                            ),
                          ]);
                          message.success("Removed visit!");
                        },
                        onCancel: () => {
                          // message.info("Delete canceled");
                        },
                      });
                    }}
                  >
                    Remove
                  </span>
                ),
              },
            ],
          }}
          placement="bottom"
        >
          <Button type="link">Actions</Button>
        </Dropdown>
      ),
      align: "center",
    },
  ];

  const validatePercentage = (_, value) => {
    if (!value) return Promise.resolve();

    const percentageRegex = /^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/;

    if (!percentageRegex.test(value)) {
      return Promise.reject("Please enter a valid percentage");
    }
    return Promise.resolve();
  };

  return (
    <>
      <Row gutter={10}>
        <Col span={24}>
          <Card title={params.id ? 'Edit AMC' : "Create AMC"}>
            <Form
              form={form}
              layout="vertical"
              // wrapperCol={{
              //     span: 20,
              // }}
              onFinish={onFinish}
              initialValues={{ includeGST: true }}
            >
              <Row gutter={16}>
                <Col span={24}>
                  <Card title="AMC Details" bordered={false}>
                    <Row gutter={10}>
                      <Col span={8}>
                        <Form.Item
                          name="customerId"
                          label="Select Customer"
                          rules={[
                            {
                              required: true,
                              message: "Please select the customer",
                            },
                          ]}
                        >
                          <DebounceSelect
                            value={value}
                            placeholder="e.g. John Doe"
                            fetchOptions={fetchCustomerList}
                            onChange={(newValue, data) => {
                              setValue(newValue);
                              setSelectedCustomer(data.data);
                              form.setFieldValue("contactPersonId", undefined);
                            }}
                            style={{ width: "100%" }}
                            tabIndex={1}
                          />
                        </Form.Item>

                        <Form.Item
                          name="type"
                          label="Select Type"
                          rules={[
                            {
                              required: true,
                              message: "Please select the type",
                            },
                          ]}
                        >
                          <Select
                            allowClear
                            style={{
                              width: "100%",
                            }}
                            placeholder="e.g. Comprehensive"
                            // onChange={handleChange}
                            options={[
                              {
                                label: "Comprehensive",
                                value: "Comprehensive",
                              },
                              {
                                label: "Non-Comprehensive",
                                value: "Non-Comprehensive",
                              },
                            ]}
                          />
                        </Form.Item>
                        <Item
                          label="AMC Contract Amount"
                          name="contractAmount"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Contract Amount",
                            },
                          ]}
                        >
                          <Input placeholder="e.g. 18000" type="number" />
                        </Item>

                        <Item
                          label="Number Of Visits"
                          name="visitsCount"
                          rules={[
                            {
                              required: true,
                              message: "Please enter number of visits",
                            },
                          ]}
                        >
                          <Input disabled={params.id} placeholder="e.g. 3" type="number" />
                        </Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          name="contactPersonId"
                          label="Select Contact Person"
                        >
                          <Select
                            // mode="multiple"
                            allowClear
                            style={{
                              width: "100%",
                            }}
                            placeholder="Please select"
                            // onChange={handleChange}
                            options={selectedCustomer?.contactPersons?.map(
                              (c) => ({ label: c.name, value: c._id })
                            )}
                          />
                        </Form.Item>

                        <Item
                          label="Contract & Expiry Date"
                          name="contractDateRange"
                          rules={[
                            {
                              required: true,
                              message: "Please select contract date",
                            },
                          ]}
                        >
                          <RangePicker format={'DD-MM-YYYY'} style={{ width: "100%" }} />
                        </Item>

                        <Item
                          label="With/Without GST"
                          name="includeGST"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Radio.Group name="includeGST" defaultValue={true}>
                            <Radio value={true}>With GST</Radio>
                            <Radio value={false}>Without GST</Radio>
                          </Radio.Group>
                        </Item>

                        <Item label="Notes" name="notes">
                          <TextArea
                            rows={3}
                            placeholder="e.g. Third floor near flat no. 34"
                            defaultValue={""}
                          />
                        </Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          name="systemTypes"
                          label="Select Systems"
                          rules={[
                            {
                              required: true,
                              message: "Please select the systems",
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            allowClear
                            style={{
                              width: "100%",
                            }}
                            placeholder="Please select"
                            // onChange={handleChange}
                            options={systems?.map((s) => ({
                              label: s.name,
                              value: s._id,
                            }))}
                          />
                        </Form.Item>
                        <Form.Item
                          name="workOrderNo"
                          label="Work Order No"
                          rules={[
                            {
                              required: true,
                              message: "Please enter work order no.",
                            },
                          ]}
                        >
                          <Input placeholder="e.g. 122" />
                        </Form.Item>

                        <Item
                          label="AMC Duration (in months)"
                          name="duration"
                          rules={[
                            {
                              required: true,
                              message: "Please enter number of months",
                            },
                          ]}
                        >
                          <Input disabled={params.id} placeholder="e.g. 12" type="number" />
                        </Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              <br />

              <Card
                title="Visits"
                extra={
                  <>
                    <Button
                      type="primary"
                      icon={<LuPlus style={{ paddingTop: "2px" }} />}
                      onClick={showModal}
                      tabIndex={7}
                      disabled={params.id}
                    >
                      Add Visit
                    </Button>
                  </>
                }
              >
                <Table
                  loading={isLoading}
                  columns={columns}
                  dataSource={selectedVisits}
                  size="large"
                />
              </Card>
              <Item style={{ marginTop: "20px", marginLeft: "20px" }}>
                <Button type="primary" htmlType="submit" tabIndex={8}>
                  {isEdit ? "Save" : "Create"}
                </Button>
              </Item>
            </Form>
          </Card>
        </Col>
      </Row>
      <Modal
        title="Add Visit"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        okText="Add"
      >
        <Form
          form={itemForm}
          layout="vertical"
          onFinish={(value) => console.log(value)}
          initialValues={{
            description: `First Visit`,
          }}
        >
          <Form.Item name="description" label="Enter Description">
            <Input placeholder="e.g. First visit" tabIndex={10} disabled />
          </Form.Item>
          <Row gutter={8}>
            {/* <Col span={12}>
              <Item label="Data" name="data" hidden></Item>

              <Form.Item
                name="assignedTo"
                label="Select Assigned Employee"
                rules={[
                  {
                    required: true,
                    message: "Please select the employee",
                  },
                ]}
              >
                <DebounceSelect
                  value={value}
                  placeholder="e.g. John Doe"
                  fetchOptions={fetchEmployeeList}
                  onChange={(newValue, data) => {
                    setEmployeeValue(newValue);
                    itemForm.setFieldsValue({ data: data });
                  }}
                  style={{ width: "100%" }}
                  tabIndex={11}
                />
              </Form.Item>
            </Col> */}

            <Col span={12}>
              <Item
                label="Scheduled Date"
                name="scheduledDate"
                rules={[
                  {
                    required: true,
                    message: "Please select scheduled date",
                  },
                ]}
              >
                <DatePicker
                  format={'DD-MM-YYYY'}
                  // disabledDate={disabledDate}
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                />
              </Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ManageAMC;
