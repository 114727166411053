import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Row,
  Col,
  Card,
  message,
  Select,
} from "antd";
import { Link, useParams, useNavigate } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import APICall from "../../../services/apiservices";
import { useForm } from "antd/es/form/Form";
import { Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import axios from "axios";
import moment from "moment";
const { Item } = Form;

const ManageEmployee = ({ isEdit }) => {
  const [form] = useForm();
  const { employeeId } = useParams();
  const navigate = useNavigate();
  const [profileFileId, setProfileFileId] = useState();
  const [adharFileId, setadhaarFileId] = useState();
  const [panFileId, setPanFileId] = useState();
  // const [employee, setEmployee] = useState({});
  const params = useParams();
  const getEmployeeData = async (employeeId) => {
    const response = await APICall(`/employee/${employeeId}`, {}, 1);
    console.log(response.data);
    form.setFieldsValue({
      name: response.data.user.name,
      email: response.data.user.email,
      birthdate: dayjs(new Date(response?.data?.user?.birthdate)),
      aadhaarId: response.data.user.aadhaarId,
      address: response.data.user.address,
      mobile: response.data.user.mobile,
      qualification: response.data.qualification,
      accountNumber: response?.data?.bankAccounts[0]?.accountNumber,
      bankName: response?.data?.bankAccounts[0]?.bankName,
      accountName: response?.data?.bankAccounts[0]?.accountName,
      ifscCode: response?.data?.bankAccounts[0]?.ifscCode,
      employeeCode: response.data.employeeCode,
      joiningDate: dayjs(new Date(response?.data?.joiningDate)),
      salaryPerDay: response.data.salaryPerDay,
      designation: response.data.designation,
      salary: response.data.salary,
      otRatePerHr: response.data.otRatePerHr,
      pan: response.data.pan,
      accessType: response.data.user.employee.accessType,
    });
  };

  const onFinish = async (values) => {
    console.log(profileFileId);
    console.log(adharFileId);
    console.log(panFileId);
    try {
      if (isEdit) {
        const res = await APICall(
          `/employee/${params.employeeId}`,
          {
            ...values,
            photoFileId: profileFileId,
            aadhaarFileId: adharFileId,
            panFileId: panFileId,
            salaryPerDay: 0,
          },
          3
        );
        navigate("/employees");
        message.success("Employee has been updated!");
        console.log(res);
      } else {
        console.log(values);
        const res = await APICall(
          "/employee",
          {
            ...values,
            photoFileId: profileFileId,
            aadhaarFileId: adharFileId,
            panFileId: panFileId,
            isActive: true,
            salaryPerDay: 0,
          },
          2
        );
        message.success("Employee has been created!");
        navigate("/employees");
        console.log(res);
      }
    } catch (e) {
      message.error(e?.response?.data?.message || "Something went wrong!");
    }
  };

  useEffect(() => {
    if (isEdit) getEmployeeData(employeeId);
  }, []);

  const handleProfileUpload = async (file) => {
    const fileData = {
      requirement: [
        {
          order: 0,
          extension: file.name.substring(file.name.lastIndexOf(".") + 1),
        },
      ],
      module: "Employee",
    };

    const fileResponse = await APICall("/file", fileData, 2);
    await axios.put(fileResponse.data.data[0].url, file);

    setProfileFileId(fileResponse.data.data[0].id);

    return false;
  };

  const handleAadhaarUpload = async (file) => {
    const fileData = {
      requirement: [
        {
          order: 0,
          extension: file.name.substring(file.name.lastIndexOf(".") + 1),
        },
      ],
      module: "Employee",
    };

    const fileResponse = await APICall("/file", fileData, 2);
    await axios.put(fileResponse.data.data[0].url, file);

    setadhaarFileId(fileResponse.data.data[0].id);

    return false;
  };

  const handlePanUpload = async (file) => {
    const fileData = {
      requirement: [
        {
          order: 0,
          extension: file.name.substring(file.name.lastIndexOf(".") + 1),
        },
      ],
      module: "Employee",
    };

    const fileResponse = await APICall("/file", fileData, 2);
    await axios.put(fileResponse.data.data[0].url, file);

    setPanFileId(fileResponse.data.data[0].id);

    return false;
  };

  const validateAadharNumber = (_, value) => {
    if (value.length !== 12) {
      return Promise.reject("Input must have exactly 12 characters");
    }
    return Promise.resolve();
  };
  const validateMobileNumber = (_, value) => {
    if (value.length !== 10) {
      return Promise.reject("Input must have exactly 10 characters");
    }
    return Promise.resolve();
  };
  const validateAccountNumber = (_, value) => {
    if (value.length < 9 || value.length > 13) {
      return Promise.reject("Input length must be between 9 and 13 characters");
    }
    return Promise.resolve();
  };
  const validateIfscCode = (_, value) => {
    if (value.length < 7 || value.length > 10) {
      return Promise.reject("Input length must be between 9 and 13 characters");
    }
    return Promise.resolve();
  };
  const validateEmail = (_, value) => {
    const emailRegex = /^[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(value)) {
      return Promise.reject("Please enter a valid email address");
    }
    return Promise.resolve();
  };

  function disabledDate(current) {
    const now = moment();
    return current && current < now.startOf("day");
  }

  function disabledBirthDates(current) {
    const now = moment();
    return (
      current &&
      (current > now.endOf("day") ||
        current > now.subtract(14, "years").startOf("day"))
    );
  }
  return (
    <Row className="scroll-container">
      <Col span={24}>
        <Card title={isEdit ? "Edit Employee" : "Create Employee"}>
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Row gutter={10}>
              <Col span={24}>
                <Card
                  title="Personal Information"
                  bordered={false}
                  style={{ marginBottom: "20px" }}
                >
                  <Row gutter={20}>
                    <Col span={8}>
                      <Item
                        label="Name"
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the employee name",
                          },
                        ]}
                      >
                        <Input placeholder="e.g. Rahul Kumar" tabIndex={1} />
                      </Item>

                      <Item
                        label="Date of Birth"
                        name="birthdate"
                        rules={[
                          {
                            required: true,
                            message: "Please select the date of birth",
                          },
                        ]}
                      >
                        <DatePicker
                          format={'DD-MM-YYYY'}
                          // disabledDate={disabledBirthDates}
                          placeholder="e.g. 15/08/1998"
                          style={{ width: "100%" }}
                          tabIndex={4}
                        />
                      </Item>
                    </Col>

                    <Col span={8}>
                      <Item
                        label="Mobile"
                        name="mobile"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the employee mobile number",
                          },
                          {
                            validator: validateMobileNumber,
                          },
                        ]}
                      >
                        <Input
                          placeholder="e.g. 7744953400"
                          type="number"
                          tabIndex={2}
                        />
                      </Item>

                      <Item
                        label="Employee Qualification"
                        name="qualification"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the employee qualification",
                          },
                        ]}
                      >
                        <Input placeholder="e.g. BA, 12th, etc." tabIndex={5} />
                      </Item>
                    </Col>

                    <Col span={8}>
                      <Item
                        label="Email"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the employee email",
                          },
                          {
                            validator: validateEmail,
                          },
                        ]}
                      >
                        <Input
                          placeholder="e.g. rahul.kumar@gmail.com"
                          tabIndex={3}
                        />
                      </Item>

                      <Item
                        label="Aadhaar Number"
                        name="aadhaarId"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the Aadhaar number",
                          },
                          {
                            validator: validateAadharNumber,
                          },
                        ]}
                      >
                        <Input
                          placeholder="e.g. 1234 5678 9012"
                          type="number"
                        />
                      </Item>
                    </Col>
                  </Row>
                  <Row gutter={20}>
                    <Col span={16}>
                      <Item
                        label="Address"
                        name="address"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the employee address",
                          },
                        ]}
                      >
                        <TextArea
                          rows={3}
                          placeholder="e.g. 12th Road, Kumar Nagar, Shah Wasti, Pune, Maharashtra 411001"
                        />
                      </Item>
                    </Col>
                    <Col spam={8}>
                      <Item
                        label="Profile Image"
                        name="file"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please enter the Profile Image",
                        //   },
                        // ]}
                      >
                        <Upload
                          beforeUpload={handleProfileUpload}
                          multiple={false}
                        >
                          <Button icon={<UploadOutlined />}>
                            Click to Upload
                          </Button>
                        </Upload>
                      </Item>
                    </Col>
                  </Row>
                  <Row gutter={20}>
                    <Col span={6}>
                      <Item label="Upload Aadhaar" name="aadhaarFile">
                        <Upload
                          beforeUpload={handleAadhaarUpload}
                          multiple={false}
                        >
                          <Button icon={<UploadOutlined />}>
                            Click to Upload
                          </Button>
                        </Upload>
                      </Item>
                    </Col>
                    <Col spam={6}>
                      <Item label="Upload Pan" name="panFile">
                        <Upload beforeUpload={handlePanUpload} multiple={false}>
                          <Button icon={<UploadOutlined />}>
                            Click to Upload
                          </Button>
                        </Upload>
                      </Item>
                    </Col>
                  </Row>
                </Card>
                <Card
                  title="Employee Information"
                  bordered={false}
                  style={{ marginBottom: "20px" }}
                >
                  <Row gutter={20}>
                    <Col span={8}>
                      <Item
                        label="Employee Designation"
                        name="designation"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the employee designation",
                          },
                        ]}
                      >
                        <Input placeholder="e.g. Service Coordinator" />
                      </Item>

                      {/* <Item
                        label="Salary per day"
                        name="salaryPerDay"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the salary per day",
                          },
                        ]}
                      >
                        <Input placeholder="e.g. 1200" type="number" />
                      </Item> */}
                      <Item
                        label="Salary"
                        name="salary"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the employee salary",
                          },
                        ]}
                      >
                        <Input placeholder="e.g. 22000" type="number" />
                      </Item>
                    </Col>

                    <Col span={8}>
                      <Item
                        label="Date of Joining"
                        name="joiningDate"
                        rules={[
                          {
                            required: true,
                            message: "Please select the date of joining",
                          },
                        ]}
                      >
                        <DatePicker
                          format={'DD-MM-YYYY'}
                          // disabledDate={disabledDate}
                          placeholder="e.g. 15/08/2020"
                          style={{ width: "100%" }}
                        />
                      </Item>
                      <Item
                        label="Ot Rate Per Hour"
                        name="otRatePerHr"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the ot rate per hour",
                          },
                        ]}
                      >
                        <Input placeholder="e.g. 1200" type="number" min={1} />
                      </Item>
                    </Col>
                    <Col span={8}>
                      <Item
                        label="Access Type"
                        name="accessType"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the employee salary",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Selecte access type"
                          options={[
                            {
                              label: "Admin",
                              value: "Admin",
                            },
                            {
                              label: "General Employee",
                              value: "General Employee",
                            },
                          ]}
                        />
                      </Item>
                    </Col>
                  </Row>
                </Card>
                <Card title="Bank Account Information" bordered={false}>
                  <Row gutter={20}>
                    <Col span={8}>
                      <Item
                        label="Bank Name"
                        name="bankName"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please enter the bank name",
                        //   },
                        // ]}
                      >
                        <Input placeholder="e.g. HDFC Bank" />
                      </Item>
                      <Item
                        label="Account Holder Name"
                        name="accountName"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please enter the account holder name",
                        //   },
                        // ]}
                      >
                        <Input placeholder="e.g. Rahul Vivek Mishra" />
                      </Item>
                    </Col>

                    <Col span={8}>
                      <Item
                        label="Bank IFSC Code"
                        name="ifscCode"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please enter the bank IFSC code",
                        //   },
                        //   {
                        //     validator: validateIfscCode,
                        //   },
                        // ]}
                      >
                        <Input placeholder="e.g. HDFC00123" />
                      </Item>
                      <Item
                        label="PAN Number"
                        name="pan"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please enter the pan number",
                        //   },
                        //   {
                        //     validator: validateAccountNumber,
                        //   },
                        // ]}
                      >
                        <Input placeholder="e.g. ABCTY1234D" />
                      </Item>
                    </Col>
                    <Col span={8}>
                      <Item
                        label="Bank Account Number"
                        name="accountNumber"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please enter the bank account number",
                        //   },
                        //   {
                        //     validator: validateAccountNumber,
                        //   },
                        // ]}
                      >
                        <Input
                          placeholder="e.g. 987412320004001"
                          type="number"
                          maxLength={13}
                        />
                      </Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>

            <Item style={{ marginTop: "20px", marginLeft: "20px" }}>
              <Button type="primary" htmlType="submit">
                {isEdit ? "Save" : "Create"}
              </Button>
            </Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default ManageEmployee;
