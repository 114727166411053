import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../features/Dashboard";
import MainLayout from "../../components/Layout/MainLayout";
import Amc from "../features/Amc";
import SurveyDetails from "../features/Surveys";
import AmcDetails from "../features/amc/AmcDetails";
import Contact from "../features/Contact";
import ActiveAMC from "../features/ActiveAMC";
import Reports from "../features/Reports";

import FormA from "../../superadmin/features/reports/FormA";
import FormB from "../../superadmin/features/reports/FormB";
import TrainingCertificateForm from "../../superadmin/features/reports/TrainingCertificate";
import TestingCertificateForm from "../../superadmin/features/reports/TestingCertificateForm";
import InstallationCertificateForm from "../../superadmin/features/reports/InstallationCertificateForm";
import AmcExport from "../features/amc/AmcExport";

const CutomerRoutes = () => {
  return (
    <Routes>
      <Route
        path="/dashboard"
        element={
          <MainLayout>
            <Dashboard />
          </MainLayout>
        }
      />
      <Route
        path="/surveys"
        element={
          <MainLayout>
            <SurveyDetails />
          </MainLayout>
        }
      />
      <Route
        path="/amcs"
        element={
          <MainLayout>
            <Amc />
          </MainLayout>
        }
      />
      <Route
        path="/amcs/active"
        element={
          <MainLayout>
            <ActiveAMC />
          </MainLayout>
        }
      />

      <Route
        path="/amcs/:amcId"
        element={
          <MainLayout>
            <AmcDetails />
          </MainLayout>
        }
      />
      <Route
        path="/amcs/:amcId/export"
        element={
          <MainLayout>
            <AmcExport />
          </MainLayout>
        }
      />
      <Route
        path="/reports"
        element={
          <MainLayout>
            <Reports />
          </MainLayout>
        }
      />

      <Route
        path="/view/formA/:id"
        element={
          <MainLayout>
            <FormA view />
          </MainLayout>
        }
      />
      <Route
        path="/view/formB/:id"
        element={
          <MainLayout>
            <FormB view />
          </MainLayout>
        }
      />

      <Route
        path="/view/trainingCertificate/:id"
        element={
          <MainLayout>
            <TrainingCertificateForm view />
          </MainLayout>
        }
      />

      <Route
        path="/view/testingCertificate/:id"
        element={
          <MainLayout>
            <TestingCertificateForm view />
          </MainLayout>
        }
      />

      <Route
        path="/view/installationCertificate/:id"
        element={
          <MainLayout>
            <InstallationCertificateForm view />
          </MainLayout>
        }
      />

      <Route
        path="/contact"
        element={
          <MainLayout>
            <Contact />
          </MainLayout>
        }
      />
    </Routes>
  );
};

export default CutomerRoutes;
