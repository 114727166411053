import React, { useEffect, useState } from "react";
import {
  Card,
  Descriptions,
  Button,
  message,
  Spin,
  Row,
  Form,
  Modal,
  Space,
  Col,
  Select,
  DatePicker,
} from "antd";

import { Link, useParams, useNavigate } from "react-router-dom";
import APICall from "../../../services/apiservices";
import moment from "moment";
import DebounceSelect from "../../../components/debounceSelect/DebounceSelect";
import dayjs from "dayjs";
import downloadXLSX from "../../../employee/features/survey/export";
import fireExtExport from "../../../employee/features/survey/fireExtExport";
const { confirm } = Modal;
const SurveyDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();
  const [surveyData, setSurveyData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [editSurvey, setEditSurvey] = useState(false);
  const [initialCustomer, setInitialCustomer] = useState();
  const [initialEmployee, setInitialEmployee] = useState();
  const [customer, setCustomer] = useState(undefined);
  const [employee, setEmployee] = useState(undefined);

  const getSurveyDetails = async () => {
    try {
      const response = await APICall(`/survey/${params.surveyId}`, {}, 1);
      console.log(response.data);
      setSurveyData(response.data.data);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      message.error(error.message);
    }
  };

  const handleEditSurvey = async () => {
    const formData = form.getFieldsValue();
    setIsLoading(!isLoading);

    if (!(typeof formData.customerId === "string")) {
      formData.customerId = formData.customerId.value;
    }

    if (!(typeof formData.employeeId === "string")) {
      formData.employeeId = formData.employeeId.value;
    }

    try {
      const response = await APICall(`/survey/${params.surveyId}`, formData, 3);
      if (response?.data) {
        message.success("Survey has been updated successfully.");
        getSurveyDetails();
        setEditSurvey(false);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const handleDelete = (record) => {
    confirm({
      title: "Confirm Delete",
      content: `Are you sure you want to delete survey?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          const response = await APICall(`/survey/${params.surveyId}`, {}, 4);
          message.success("Survey deleted successfully!");
          navigate("/surveys");
        } catch (error) {
          message.error(error.response.data.message);
        }
      },
    });
  };

  const fetchCustomerList = async (searchValue, initialValues) => {
    if (initialValues) {
      return initialValues.map((i) => ({
        label: `${i.customerCode + " " + i.user.name}`,
        value: i._id,
      }));
    }
    const res = await APICall(
      `/customer?page=1&limit=30&query=${searchValue}`,
      {},
      1
    );
    return res.data.documents.map((customer) => ({
      label: customer.user.name,
      value: customer._id,
    }));
  };

  const fetchEmployeeList = async (searchValue) => {
    const res = await APICall(
      `/employee?page=1&limit=30&query=${searchValue}`,
      {},
      1
    );
    return res.data.documents.map((employee) => ({
      label: employee.user.name,
      value: employee._id,
    }));
  };

  const requirementsArray = {
    adaptor: {
      name: "Adaptor",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hoseBox: {
      name: "Hose Box",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    branchPipe: {
      name: "Branch Pipe",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hosePipe: {
      name: "Hose Pipe",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hydrantValve: {
      name: "Hydrant Valve",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hoseReel: {
      name: "Hose Reel",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    fireExtinguishers: {
      name: "Fire Extinguisher",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hoseBoxGlass: {
      name: "Hose Box Glass",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    keyGlass: {
      name: "Key Glass",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hydrantValveWheel: {
      name: "Hydrant Valve Wheel",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hoseReelBallValve: {
      name: "Hose Reel Ball Valve",
      existing: 0,
      good: 0,
      requirement: 0,
    },
  };

  const exportSurvey = async (surveyId) => {
    try {
      const res = await APICall(`/survey/${surveyId}/conducted`, {}, 1);
      console.log(res);
      if (!res?.data?.survey?.isFireExtinguisher) {
        const finalRequirements = JSON.parse(JSON.stringify(requirementsArray));
        const exportData = {
          organization: {
            name: "Fire Fight Safety Solutions",
            address:
              "Sr. No.85, Office No.7A/204, Kalpataru Estate, Jawalkar Nagar,Pimple Gurav, Pune - 411061",
            mobile: "9970548822",
            email: "info@ffss.in",
          },
          customer: {
            name: res?.data?.survey?.customer?.user?.name,
          },
          employee: {
            name: res?.data?.survey?.employee?.user?.name,
          },
          date: moment(res?.data?.survey?.surveyDate).format("DD/MM/YYYY"),
          buildings: res.data.surveyBuildings.map((sb) => {
            const areas = sb?.newFloors.map((nf, index) => ({
              area: nf?.floor.name,
              srNo: index + 1,
              equipments: nf?.equipments ?? {
                adaptor: "NA",
                hoseBox: "NA",
                branchPipe: "NA",
                hosePipe: "NA",
                hydrantValve: "NA",
                hoseReel: "NA",
                fireExtinguishers: "NA",
                hoseBoxGlass: "NA",
                keyGlass: "NA",
                hydrantValveWheel: "NA",
                hoseReelBallValve: "NA",
              },
            }));
            const requirements = JSON.parse(JSON.stringify(requirementsArray));
            areas.map((area) => {
              Object.keys(area.equipments).map((k) => {
                if (area.equipments[k] == "OK") {
                  requirements[k].existing = requirements[k].existing + 1;
                  requirements[k].good = requirements[k].good + 1;
                  finalRequirements[k].existing =
                    finalRequirements[k].existing + 1;
                  finalRequirements[k].good = finalRequirements[k].good + 1;
                } else if (area.equipments[k] == "Not OK") {
                  requirements[k].existing = requirements[k].existing + 1;
                  requirements[k].requirement = requirements[k].requirement + 1;
                  finalRequirements[k].existing =
                    finalRequirements[k].existing + 1;
                  finalRequirements[k].requirement =
                    finalRequirements[k].requirement + 1;
                }
              });
            });
            return {
              building: sb?.building?.name,
              notes: sb?.information?.notes ?? "",
              inCharge: sb?.information?.inCharge ?? "",
              areas,
              requirements,
            };
          }),
        };
        exportData.requirements = finalRequirements;
        downloadXLSX(exportData, "Survey");
      } else {
        console.log(res);
        const exportData = {
          organization: {
            name: "Fire Fight Safety Solutions",
            address:
              "Sr. No.85, Office No.7A/204, Kalpataru Estate, Jawalkar Nagar,Pimple Gurav, Pune - 411061",
            mobile: "9970548822",
            email: "info@ffss.in",
          },
          customer: {
            name: res?.data?.survey?.customer?.user?.name,
          },
          employee: {
            name: res?.data?.survey?.employee?.user?.name,
          },
          date: moment(res?.data?.survey?.surveyDate).format("DD/MM/YYYY"),

          buildings: res.data.surveyBuildings.map((sb) => {
            const areas = sb?.newFloors.map((floor, index) => ({
              srNo: index + 1,
              area: floor?.floor.name,
              fireExtinguisherInfo: {
                type: floor?.fireExtinguisherInfo?.type ?? "NA",
                capacity: floor?.fireExtinguisherInfo?.capacity ?? "NA",
                pressure: floor?.fireExtinguisherInfo?.pressure ?? "NA",
                weight: floor?.fireExtinguisherInfo?.weight ?? "NA",
                serialNo: floor?.fireExtinguisherInfo?.serialNo ?? "NA",
                installationRefillingDate: floor?.fireExtinguisherInfo
                  ?.installationRefillingDate
                  ? moment(
                      floor?.fireExtinguisherInfo?.installationRefillingDate
                    ).format("DD/MM/YYYY")
                  : "NA",
                refillingDueDate: floor?.fireExtinguisherInfo?.refillingDueDate
                  ? moment(
                      floor?.fireExtinguisherInfo?.refillingDueDate
                    ).format("DD/MM/YYYY")
                  : "NA",
                freeAccess: floor?.fireExtinguisherInfo?.freeAccess ?? "NA",
                identificationTag:
                  floor?.fireExtinguisherInfo?.identificationTag ?? "NA",
                forABCPressureGuage:
                  floor?.fireExtinguisherInfo?.forABCPressureGuage ?? "NA",
                hoseTightFitting:
                  floor?.fireExtinguisherInfo?.hoseTightFitting ?? "NA",
                isSafetyPinGood:
                  floor?.fireExtinguisherInfo?.isSafetyPinGood ?? "NA",
                forCO2HoseCondition:
                  floor?.fireExtinguisherInfo?.forCO2HoseCondition ?? "NA",
                forCO2HornHandle:
                  floor?.fireExtinguisherInfo?.forCO2HornHandle ?? "NA",
                isFEOK: floor?.fireExtinguisherInfo?.isFEOK ?? "NA",
                remark: floor?.fireExtinguisherInfo?.remark ?? "NA",
              },
            }));
            return {
              building: sb?.building?.name,
              notes: sb?.information?.notes ?? "",
              inCharge: sb?.information?.inCharge ?? "",
              areas,
            };
          }),
        };

        fireExtExport(exportData, "Survey");
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  useEffect(() => {
    setIsLoading(true);
    getSurveyDetails();
  }, []);

  return (
    <>
      {isLoading ? (
        <Row
          justify="center"
          align="middle"
          style={{ position: "absolute", top: "50%", left: "50%" }}
        >
          <Spin tip={"Loading"} />
        </Row>
      ) : (
        <Card
          title="Survey Details"
          extra={
            <Space>
              <Link to="/surveys">
                <Button type="primary" ghost>
                  Back
                </Button>
              </Link>
              <Button
                type="primary"
                onClick={() => {
                  setInitialCustomer({
                    label: surveyData?.customer?.user?.name,
                    value: surveyData?.customerS?._id,
                  });

                  setInitialEmployee({
                    label: surveyData?.employee?.user?.name,
                    value: surveyData?.empsloyee?._id,
                  });

                  form.setFieldsValue({
                    type: surveyData?.type,
                    surveyDate: dayjs(surveyData?.surveyDate),
                    customerId: {
                      label: surveyData?.customer?.user?.name,
                      value: surveyData?.customer._id,
                    },
                    employeeId: {
                      label: surveyData?.employee?.user?.name,
                      value: surveyData?.employee?._id,
                    },
                  });
                  setEditSurvey(true);
                }}
              >
                Edit
              </Button>
              {surveyData?.isConducted && (
                <Button
                  type="primary"
                  onClick={() => {
                    exportSurvey(surveyData._id);
                  }}
                >
                  Export
                </Button>
              )}
              <Button type="primary" onClick={handleDelete}>
                Delete
              </Button>
            </Space>
          }
        >
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item label="Survey Number">
              {surveyData?.surveyNo}
            </Descriptions.Item>
            <Descriptions.Item label="Survey Type">
              {surveyData?.type}
            </Descriptions.Item>
            <Descriptions.Item label="Customer">
              {surveyData?.customer?.user?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Survey Date">
              {moment(surveyData?.surveyDate).format("DD/MM/YYYY")}
            </Descriptions.Item>
            <Descriptions.Item label="Conducted By">
              {surveyData?.employee?.user?.name}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      )}
      <Modal
        title="Edit Survey"
        open={editSurvey}
        okText={"Save Survey"}
        onCancel={() => {
          setEditSurvey(false);
          form.resetFields();
        }}
        onOk={form.submit}
        // onOk={handleEditSurvey}
      >
        <Form
          form={form}
          onFinish={handleEditSurvey}
          okText="Save"
          layout="vertical"
          onCancel={() => {
            setEditSurvey(!editSurvey);
          }}
        > 
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="customerId"
                label="Customer"
                rules={[
                  {
                    required: true,
                    message: "Please select customer",
                  },
                ]}
              >
                <DebounceSelect
                  value={customer}
                  placeholder="Select Customer"
                  fetchOptions={fetchCustomerList}
                  defaultValue={initialCustomer}
                  onChange={(newValue) => {
                    setCustomer(newValue);
                  }}
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item
                name="employeeId"
                label="Employee"
                rules={[
                  {
                    required: true,
                    message: "Please select employee",
                  },
                ]}
              >
                <DebounceSelect
                  value={employee}
                  placeholder="Select Employee"
                  fetchOptions={fetchEmployeeList}
                  defaultValue={initialEmployee}
                  onChange={(newValue) => {
                    setEmployee(newValue);
                  }}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="type"
                label="Type"
                rules={[
                  {
                    required: true,
                    message: "Please select the type",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select Type"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={[
                    {
                      value: "industrial",
                      label: "Industrial",
                    },
                    {
                      value: "residential",
                      label: "Residential",
                    },
                  ]}
                  tabIndex={1}
                />
              </Form.Item>

              <Form.Item
                name="surveyDate"
                label="Date"
                rules={[
                  {
                    required: true,
                    message: "Please select date",
                  },
                ]}
              >
                <DatePicker format={'DD-MM-YYYY'} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default SurveyDetails;
