import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  Table,
  Button,
  Tag,
  message,
  Descriptions,
  Space,
  Modal,
  Form,
  Input,
  DatePicker,
  Dropdown,
  Select,
  InputNumber,
} from "antd";
import APICall from "../../../services/apiservices";
import moment from "moment";
import downloadXLSX from "../../../employee/features/survey/export";
import downloadFireExtinguisherXLSX from "../../../employee/features/survey/fireExtExport";
import DebounceSelect from "../../../components/debounceSelect/DebounceSelect";
import dayjs from "dayjs";
import { Link, useNavigate, useParams } from "react-router-dom";
const { confirm } = Modal;
const { Item } = Form;

const LeadDetails = () => {
  const [interactionForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [leadDetails, setLeadDetails] = useState([]);
  const { leadId } = useParams();
  const navigate = useNavigate();
  const [leadInterations, setLeadInterations] = useState([]);
  const [openAddInteractionModal, setOpenAddInteractionModal] = useState(false);
  const [interactionId, setInteractionId] = useState();

  const getLeadDetails = async () => {
    try {
      const response = await APICall(`/lead/${leadId}`, {}, 1);
      setLeadDetails(response?.data);
      setIsLoading(false);
    } catch (error) {
      message.error("Failed to fetch lead details");
      setIsLoading(false);
    }
  };

  const getLeadInteractions = async () => {
    try {
      const response = await APICall(`/lead/${leadId}/interaction`, {}, 1);
      setLeadInterations(response?.data?.documents);
      setIsLoading(false);
    } catch (error) {
      message.error("Failed to fetch lead interactions");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getLeadDetails();
    getLeadInteractions();
  }, []);

  const handleDelete = async () => {
    confirm({
      title: "Confirm Delete",
      content: `Are you sure you want to delete?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        // Delete logic goes here
        const res = await APICall(`/lead/${leadId}`, {}, 4);
        message.success("Lead deleted successfully!");
        navigate("/lead");
      },
      onCancel: () => {
        message.info("Delete canceled");
      },
    });
  };

  const handleInteractionFormSubmit = async (formData) => {
    try {
      if (interactionId) {
        const response = await APICall(
          `/lead/${leadId}/interaction/${interactionId}`,
          formData,
          3
        );
        if (response.data) {
          interactionForm.resetFields();
          setInteractionId(null);
          message.success("Interaction has been updated successfully.");
          setOpenAddInteractionModal(!openAddInteractionModal);
          getLeadInteractions();
        }
        return;
      }
      const response = await APICall(`/lead/${leadId}/interaction`, formData, 2);
      if (response.data) {
        interactionForm.resetFields();
        message.success("Interaction has been added successfully.");
        setOpenAddInteractionModal(!openAddInteractionModal);
        getLeadInteractions();
      }
    } catch (error) {
      message.error(error.response.data.errors.body[0].message);
    }
  };

  const interactionColumns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Interaction Type",
      dataIndex: "interactionType",
      key: "interactionType",
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
    },
    {
      title: "Outcome",
      dataIndex: "outcome",
      key: "outcome",
    },
    {
      title: "Current Interest Score",
      key: "currentInterestScore",
      dataIndex: "currentInterestScore",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Dropdown
          menu={{
            items: [
              {
                label: "Edit",
                onClick: () => {
                  setInteractionId(record._id);
                  interactionForm.setFieldsValue({
                    interactionType: record.interactionType,
                    notes: record.notes,
                    outcome: record.outcome,
                    currentInterestScore: record.currentInterestScore,
                  });
                  console.log(record, interactionForm.getFieldsValue())
                  setOpenAddInteractionModal(!openAddInteractionModal);
                },
              },

              {
                label: "Delete",
                onClick: () => {
                  confirm({
                    title: "Confirm Delete",
                    content: `Are you sure you want to delete?`,
                    okText: "Yes",
                    okType: "danger",
                    cancelText: "No",
                    onOk: async () => {
                      // Delete logic goes here
                      const res = await APICall(
                        `/lead/${leadId}/interaction/${record._id}`,
                        {},
                        4
                      );
                      message.success("Interaction deleted successfully!");
                      getLeadInteractions();
                    },
                    onCancel: () => {
                      message.info("Delete canceled");
                    },
                  });
                },
              },
            ],
          }}
        >
          <Button type="primary">Actions</Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Card
          title="Lead Details"
          loading={isLoading}
          extra={
            <Space>
              <Link to="/lead">
                <Button type="primary" ghost>
                  Back
                </Button>
              </Link>
              <Link to={`/lead/manage/${leadId}`}>
                <Button type="primary">Edit</Button>
              </Link>
              {/* <Link to={`/lead/${leadId}`}>
                <Button type="primary">Export</Button>
              </Link> */}
              <Button type="primary" onClick={handleDelete}>
                Delete
              </Button>
            </Space>
          }
        >
          <Descriptions bordered layout="horizontal" style={{ width: "100%" }}>
            <Descriptions.Item label="Lead Number">
              {leadDetails?.leadNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Lead Name">
              {leadDetails?.leadName}
            </Descriptions.Item>
            <Descriptions.Item label="Source">
              {leadDetails?.source}
            </Descriptions.Item>
            <Descriptions.Item label="Type">
              {leadDetails?.type}
            </Descriptions.Item>
            <Descriptions.Item label="Last Interaction Date">
              {dayjs(leadDetails?.date).format("DD-MM-YYYY")}
            </Descriptions.Item>
            <Descriptions.Item label="Lead Quality">
              {leadDetails?.leadQuality}
            </Descriptions.Item>
            <Descriptions.Item label="Interest Score">
              {leadDetails?.interestScore}
            </Descriptions.Item>
            <Descriptions.Item label="Assigned Employee">
              {leadDetails?.employee?.user?.name}
            </Descriptions.Item>
          </Descriptions>
          <Card
            title="Lead Interactions"
            type="inner"
            extra={
              <Button
                type="primary"
                onClick={() =>
                  setOpenAddInteractionModal(!openAddInteractionModal)
                }
              >
                Add Interaction
              </Button>
            }
            style={{
              marginTop: 16,
            }}
          >
            <Table columns={interactionColumns} dataSource={leadInterations} />
            <Modal
              title={`${interactionId ? 'Update' : 'Add'} Interaction`}
              open={openAddInteractionModal}
              onCancel={() =>
                setOpenAddInteractionModal(!openAddInteractionModal)
              }
              onOk={() => interactionForm.submit()}
              okText={`${interactionId ? 'Update' : 'Add'}`}
            >
              <Form
                form={interactionForm}
                onFinish={handleInteractionFormSubmit}
                layout="vertical"
              >
                <Row gutter={16}>
                  <Col span={24}>
                    <Item label="Interaction Type" name="interactionType">
                      <Select
                        placeholder="Select Interaction Type"
                        options={[
                          {
                            label: "Call",
                            value: "Call",
                          },
                          {
                            label: "Email",
                            value: "Email",
                          },
                          {
                            label: "WhatsApp",
                            value: "WhatsApp",
                          },
                        ]}
                      />
                    </Item>
                  </Col>
                  <Col span={24}>
                    <Item label="Notes" name="notes">
                      <Input.TextArea rows={3} placeholder="e.g. note" />
                    </Item>
                  </Col>
                  <Col span={24}>
                    <Item label="Outcome" name="outcome">
                      <Select
                        placeholder="Select Outcome"
                        options={[
                          {
                            label: "Interested",
                            value: "Interested",
                          },
                          {
                            label: "Not Interested",
                            value: "Not Interested",
                          },
                          {
                            label: "Callback Requested",
                            value: "Callback Requested",
                          },
                          {
                            label: "Follow-up Required",
                            value: "Follow-up Required",
                          },
                          {
                            label: "Converted",
                            value: "Converted",
                          },
                          {
                            label: "Lost",
                            value: "Lost",
                          },
                          {
                            label: "No Response",
                            value: "No Response",
                          },
                          {
                            label: "Referred",
                            value: "Referred",
                          },
                        ]}
                      />
                    </Item>
                  </Col>
                  <Col span={24}>
                    <Item
                      label="Current Interest Score"
                      name="currentInterestScore"
                    >
                      <InputNumber
                        placeholder="e.g. Call"
                        controls={false}
                        style={{ width: "100%" }}
                      />
                    </Item>
                  </Col>
                </Row>
              </Form>
            </Modal>
          </Card>
        </Card>
      </Col>
    </Row>
  );
};

export default LeadDetails;
