import React, { useEffect, useState } from "react";
import { LuPlus } from "react-icons/lu";
import {
  Row,
  Card,
  Col,
  Table,
  Space,
  Button,
  message,
  Modal,
  Input,
} from "antd";

import { Link } from "react-router-dom";
import APICall from "../../services/apiservices";
import moment from "moment";
const { Search } = Input;

const LicenseCopies = () => {
  const [licenseCopies, setLicenseCopies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tableParams, setTableParams] = useState({
    filters: {
      searchTerm: "",
    },
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
  });

  const fetchList = async () => {
    const page = tableParams.pagination.current;
    const limit = tableParams.pagination.pageSize;
    const searchTerm = tableParams.filters.searchTerm || "";
    const response = await getLeads(searchTerm, page, limit);
    setLicenseCopies(response.documents);
    setIsLoading(false);

    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: response.total,
      },
    });
  };

  useEffect(() => {
    fetchList();
  }, [JSON.stringify(tableParams)]);

  const getLeads = async (searchTerm, page, limit) => {
    try {
      if (searchTerm === undefined) searchTerm = "";
      const response = await APICall(
        `/licenseCopy?page=${page}&limit=${limit}&query=${searchTerm}`,
        {},
        1
      );
      return response.data;
    } catch (error) {
      message.error("failed to get license copy data");
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => {
        return (
          tableParams.pagination.pageSize *
            (tableParams.pagination.current - 1) +
          index +
          1
        );
      },
    },
    {
      title: "Customer",
      dataIndex: ["customer", 'user', 'name'],
      key: "customer",
    },
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
      render: (text, record) => moment(text).format("DD/MM/YYYY"),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Link to={`/licenseCopy/details/${record._id}`}>
          <Button type="primary" ghost>
            View
          </Button>
        </Link>
      ),
      align: "center",
      fixed: "right",
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setLicenseCopies([]);
    }
  };

  const onSearch = (value) => {
    setTableParams({
      ...tableParams,
      filters: {
        ...tableParams.filters,
        searchTerm: value,
      },
    });
  };

  return (
    <Row>
      <Col span={24}>
        <Card
          title="License Copy Management"
          extra={
            <Row justify={"end"} align="top" style={{ paddingBottom: "10px" }}>
              <Space>
                <Search
                  style={{
                    width: 220,
                  }}
                  placeholder="Search by customer"
                  allowClear
                  enterButton
                  size="middle"
                  onSearch={onSearch}
                />
                <Link to="/licenseCopy/manage">
                  <Button
                    type="primary"
                    icon={<LuPlus style={{ paddingTop: "2px" }} />}
                  >
                    Create License Copy
                  </Button>
                </Link>
              </Space>
            </Row>
          }
        >
          <Table
            dataSource={licenseCopies}
            columns={columns}
            loading={isLoading}
            onChange={handleTableChange}
            pagination={tableParams.pagination}
            scroll={{ x: "1000px" }}
            size="small"
          />
        </Card>
      </Col>
    </Row>
  );
};

export default LicenseCopies;
