import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
  Image,
  Font,
  pdf,
} from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
  },
  letterheader: {
    // height: "150px",
    // paddingHorizontal: 16,
    // position: "absolute",
    top: 0,
    left: 0,
    right: 0,
  },
  title: {
    alignItems: "center",
    fontFamily: "Times-Bold",
    justifyContent: "center",
    paddingHorizontal: 32,
  },
  body: {
    fontFamily: "Times-Bold",
    textAlign: "left",
    fontSize: 12,
    paddingHorizontal: 32,
  },
  header: {
    flexDirection: "row",
    paddingBottom: 20,
    fontSize: 8,
    fontFamily: "Times-Roman",
    paddingHorizontal: 32,
  },
  headerSectionLeft: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "center",
  },
  headerSectionRight: {
    flex: 1,
    alignItems: "flex-end",
    justifyContent: "center",
  },
  letterfooter: {
    position: "absolute",
    bottom: 0,
    left: 0,
    height: "100px",
    right: 0,
  },
});

function getYearString() {
  const currentYear = new Date().getFullYear();
  const yearString = `${currentYear.toString().slice(-2)}-${(currentYear + 1)
    .toString()
    .slice(-2)}`;
  return yearString;
}

// Create Document Component
const FormAPDF = ({
  letterheader,
  letterfooter,
  referenceNumber,
  headingDate,
  customer,
  organization,
  place,
  footerDate,
}) => (
  <Page size="A4" style={styles.page}>
    ?
    {letterheader && (
      <View style={styles.letterheader}>{<Image src={letterheader} />}</View>
    )}
    <View style={{ ...styles.header, paddingTop: letterheader ? 16 : 64 }}>
      <View style={styles.headerSectionLeft}>
        <Text
          style={{ fontFamily: "Times-Bold", paddingBottom: 5, fontSize: 10 }}
        >
          Reference Number:{" "}
          <Text style={{ fontFamily: "Times-Roman" }}>
            FFSS/{getYearString()}/{referenceNumber}
          </Text>
        </Text>
      </View>
      <View style={styles.headerSectionRight}>
        <Text
          style={{ fontFamily: "Times-Bold", paddingBottom: 5, fontSize: 10 }}
        >
          Date: <Text style={{ fontFamily: "Times-Roman" }}>{headingDate}</Text>
        </Text>
      </View>
    </View>
    <View style={styles.title}>
      <Text style={{ ...styles.title, fontSize: 18, paddingBottom: 5 }}>
        FORM A
      </Text>
      <Text style={{ ...styles.title, fontSize: 10, paddingBottom: 5 }}>
        [See section 3(3) and rules 4 (1)]
      </Text>
      <Text
        style={{
          ...styles.title,
          fontSize: 12,
          paddingBottom: 12,
          paddingHorizontal: 110,
          textAlign: "center",
        }}
      >
        Certificate by the Licensed Agency regarding the compliance Of the Fire
        prevention and Life Safety
      </Text>
      <Text
        style={{
          ...styles.title,
          fontSize: 20,
          paddingBottom: 12,
          textDecoration: "underline",
        }}
      >
        CERTIFICATE
      </Text>
    </View>
    <View style={styles.body}>
      <Text
        style={{
          paddingBottom: 12,
          fontFamily: "Times-Roman",
          textAlign: "justify",
        }}
      >
        Certified that I/We have executed the works Towards compliance in
        relation to Fire Prevention and Life Safety Measures to be provided and
        Performed other related activities required to be carried out , in the
        following building or premises, under the provisions of the Maharashtra
        Fire Prevention and Life Safety Measures Act, 2006 ( Mah. III of 2007 ).
      </Text>
      <Text style={{ textAlign: "center", fontSize: 13, paddingBottom: 4 }}>
        {customer?.name}
      </Text>
      <Text style={{ textAlign: "center", fontSize: 11, paddingBottom: 12 }}>
        {customer?.address}
      </Text>
      <Text
        style={{
          paddingBottom: 18,
          fontFamily: "Times-Roman",
          textAlign: "justify",
        }}
      >
        The details of the work and relate activities which I or we have
        executed or performed are mentioned in the list appended herewith.
      </Text>
      <Text style={{ paddingBottom: 8 }}>
        Place: <Text style={{ fontFamily: "Times-Roman" }}>{place}</Text>
      </Text>
      <Text style={{ paddingBottom: 18 }}>
        Date: <Text style={{ fontFamily: "Times-Roman" }}>{footerDate}</Text>
      </Text>
      <Text style={{ fontSize: 10, paddingBottom: 8 }}>
        Address of the Licensed Agency:-
      </Text>
      <Text style={{ fontSize: 10, paddingBottom: 4 }}>{organization?.name}</Text>
      <Text style={{ fontSize: 10, paddingBottom: 8, marginRight: 250 }}>
        {organization?.address}
      </Text>
      <Text style={{ fontSize: 10, paddingBottom: 8 }}>License No.:-</Text>
      {organization?.licenseNumbers.map((n, index) => (
        <Text style={{ fontSize: 10, paddingBottom: 8 }}>{`${
          index + 1
        }) ${n}`}</Text>
      ))}
      <Text style={{ textAlign: "right", fontSize: 10, paddingBottom: 16 }}>
        Authorised Signature
      </Text>
      <Text
        style={{ textAlign: "center", fontFamily: "Times-Roman", fontSize: 6 }}
      >
        (Note:- For each building a separate certificate in Form 'A' Should be
        necessary)
      </Text>
    </View>
    {letterfooter && (
      <View style={styles.letterfooter}>{<Image src={letterfooter} />}</View>
    )}
  </Page>
);

export default FormAPDF;
