import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  Button,
  message,
  Descriptions,
  Space,
  Modal,
  Form,
} from "antd";
import APICall from "../../../services/apiservices";
import dayjs from "dayjs";
import { Link, useNavigate, useParams } from "react-router-dom";
const { confirm } = Modal;
const { Item } = Form;

const TaskDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [taskDetails, setTaskDetails] = useState([]);
  const { taskId } = useParams();
  const navigate = useNavigate();

  const getTaskDetails = async () => {
    try {
      const response = await APICall(`/task/${taskId}`, {}, 1);
      setTaskDetails(response?.data);
      setIsLoading(false);
    } catch (error) {
      message.error("Failed to fetch task details");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTaskDetails();
  }, []);

  const handleDelete = async () => {
    confirm({
      title: "Confirm Delete",
      content: `Are you sure you want to delete?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        // Delete logic goes here
        const res = await APICall(`/lead/${taskId}`, {}, 4);
        message.success("Task deleted successfully!");
        navigate("/task");
      },
      onCancel: () => {
        message.info("Delete canceled");
      },
    });
  };

  return (
    <Row>
      <Col span={24}>
        <Card
          title="Task Details"
          loading={isLoading}
          extra={
            <Space>
              <Link to="/task">
                <Button type="primary" ghost>
                  Back
                </Button>
              </Link>
              <Link to={`/task/manage/${taskId}`}>
                <Button type="primary">Edit</Button>
              </Link>
              <Button type="primary" onClick={handleDelete}>
                Delete
              </Button>
            </Space>
          }
        >
          <Descriptions bordered layout="horizontal" style={{ width: "100%" }}>
            <Descriptions.Item label="Title">
              {taskDetails?.title}
            </Descriptions.Item>
            <Descriptions.Item label="Employee">
              {taskDetails?.employee?.user?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Status">
              {taskDetails?.status}
            </Descriptions.Item>
            <Descriptions.Item label="Expected Completion Date">
              {dayjs(taskDetails?.expectedCompletionDate).format("DD-MM-YYYY")}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Col>
    </Row>
  );
};

export default TaskDetails;
