import {
  Button,
  Row,
  Col,
  Card,
  Form,
  Modal,
  Radio,
  Breadcrumb,
  message,
  Input,
  InputNumber,
  DatePicker,
} from "antd";

import APICall from "../../../services/apiservices";
import { Link, useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import React from "react";
import { useGeolocated } from "react-geolocated";
import dayjs from "dayjs";

const { confirm } = Modal;

const FireExtinguisherEquipments = () => {
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const [floorEquipments, setFloorEquipments] = useState({});
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
      },
      userDecisionTimeout: null,
    });

  const location = useLocation();

  const params = useParams();
  const { customerName, buildingName, floor, pagename } = queryString.parse(
    location.search
  );

  useEffect(() => {
    getEquipmentData();
  }, []);

  const getEquipmentData = async () => {
    const res = await APICall(
      `/${pagename === "Visits" ? "visits" : "survey"}/${pagename === "Visits" ? params.visitId : params.surveyId
      }/conducted/building/${params.buildingId}`,
      {},
      1
    );

    const temp = res.data.data?.floors.map((floor) => {
      if (floor.floorId === params.areaId) {
        form.setFieldsValue({
          type: floor.fireExtinguisherInfo.type,
          capacity: floor.fireExtinguisherInfo.capacity,
          pressure: floor.fireExtinguisherInfo.pressure,
          weight: floor.fireExtinguisherInfo.weight,
          serialNo: floor.fireExtinguisherInfo.serialNo,
          installationRefillingDate: floor.fireExtinguisherInfo
            .installationRefillingDate
            ? dayjs(floor.fireExtinguisherInfo.installationRefillingDate)
            : null,
          refillingDueDate: floor.fireExtinguisherInfo.refillingDueDate
            ? dayjs(floor.fireExtinguisherInfo.refillingDueDate)
            : null,
          freeAccess: floor.fireExtinguisherInfo.freeAccess,
          identificationTag: floor.fireExtinguisherInfo.identificationTag,
          forABCPressureGuage: floor.fireExtinguisherInfo.forABCPressureGuage,
          hoseTightFitting: floor.fireExtinguisherInfo.hoseTightFitting,
          isSafetyPinGood: floor.fireExtinguisherInfo.isSafetyPinGood,
          forCO2HoseCondition: floor.fireExtinguisherInfo.forCO2HoseCondition,
          forCO2HornHandle: floor.fireExtinguisherInfo.forCO2HornHandle,
          isFEOK: floor.fireExtinguisherInfo.isFEOK,
          remark: floor.fireExtinguisherInfo.remark,
        });
        console.log(floor.equipments);
      }
    });
  };
  const breadCrumbItems = [
    {
      label: pagename,
      to: `/${pagename.toLowerCase()}`,
    },
    {
      label: customerName,
      to: `/surveys`,
    },
    {
      label: "Buildings",
      to: -2,
    },
    {
      label: buildingName,
    },
    {
      label: "Areas",
      to: -1,
    },
    {
      label: floor,
    },
    {
      label: "Fire Extinguisher",
    },
  ];

  const information = [
    {
      name: "Type",
      key: "type",
      type: "text",
      placeholder: "e.g. ABC, CO2",
    },
    {
      name: "Capacity",
      key: "capacity",
      type: "number",
      addonAfter: "KG",
    },
    {
      name: "Pressure",
      key: "pressure",
      type: "number",
      addonAfter: "Bar",
    },
    {
      name: "Weight",
      key: "weight",
      type: "number",
      addonAfter: "KG",
    },
    {
      name: "Serial No",
      key: "serialNo",
      type: "text",
      placeholder: "e.g. 1244, 1562",
    },
    {
      name: "Installation Year/Refilling Date",
      key: "installationRefillingDate",
      type: "date",
    },
    {
      name: "Refilling Due Date",
      key: "refillingDueDate",
      type: "date",
    },
    {
      name: "Free Access",
      key: "freeAccess",
      type: "radio",
      options: ["Yes", "No"],
      initialValue: "No",
    },
    {
      name: "Identification Tag",
      key: "identificationTag",
      type: "radio",
      options: ["Yes", "No"],
      initialValue: "No",
    },
    {
      name: "For ABC type FE - Check Press Gauge - Green",
      key: "forABCPressureGuage",
      type: "radio",
      options: ["OK", "Not OK", "NA"],
      initialValue: "NA",
    },
    {
      name: "Hose for tight fitting and Blockage",
      key: "hoseTightFitting",
      type: "radio",
      options: ["OK", "Not OK", "NA"],
      initialValue: "NA",
    },
    {
      name: "Check Safety Pin is in Good condition",
      key: "isSafetyPinGood",
      type: "radio",
      options: ["OK", "Not OK", "NA"],
      initialValue: "NA",
    },
    {
      name: "For CO2- Check Hose condition, Tightening",
      key: "forCO2HoseCondition",
      type: "radio",
      options: ["OK", "Not OK", "NA"],
      initialValue: "NA",
    },
    {
      name: "For CO2- Check for Horn & Handle",
      key: "forCO2HornHandle",
      type: "radio",
      options: ["OK", "Not OK", "NA"],
      initialValue: "NA",
    },
    {
      name: "Confirm FE is ok & ready for use",
      key: "isFEOK",
      type: "radio",
      options: ["OK", "Not OK", "NA"],
      initialValue: "NA",
    },
    {
      name: "Remark",
      key: "remark",
      type: "text",
      placeholder: "e.g. OK",
      initialValue: "",
    },
  ];

  const onFinish = async (formData) => {
    confirm({
      title: "Do you want save ?",
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        try {
          const res = await APICall(
            `/${pagename === "Visits" ? "visits" : "survey"}/${pagename === "Visits" ? params.visitId : params.surveyId
            }/setIsFireExtinguisher`,
            { isFireExtinguisher: true },
            3
          );
          const response = await APICall(
            `/${pagename === "Visits" ? "visits" : "survey"}/${pagename === "Visits" ? params.visitId : params.surveyId
            }/conducted/building/${params.buildingId}/floor/${params.areaId}`,
            { fireExtinguisherInfo: formData, isConducted: true },
            3
          );
          if (response?.status) {
            message.success(
              `${pagename === "Visits" ? "Visit" : "Survey"} submited.`
            );
            navigate(-1);
          }
        } catch (error) {
          message.error(
            `Failed to save ${pagename === "Visits" ? "visit" : "survey"}.`
          );
        }
      },
    });
  };

  const renderRadioGroup = (options = []) => (
    <Radio.Group>
      {options.map((option) => (
        <Radio value={option}>{option}</Radio>
      ))}
    </Radio.Group>
  );

  return (
    <>
      <Form
        form={form}
        name="elementForm"
        onFinish={onFinish}
        layout="vertical"
      >
        <Card
          title={
            <Row>
              <Col
                lg={24}
                md={24}
                sm={20}
                xs={0}
              >
                <Breadcrumb separator=">">
                  {breadCrumbItems.map((item, index) => (
                    <Breadcrumb.Item key={index}>
                      {item.to ? (
                        <Link to={item.to}>{item.label}</Link>
                      ) : (
                        item.label
                      )}
                    </Breadcrumb.Item>
                  ))}
                </Breadcrumb>
              </Col>
            </Row>
          }
          style={{ width: "100%" }}
        >
          <Row gutter={16}>
            {information.map((equipment, index) => (
              <Col
                lg={12}
                md={12}
                sm={12}
                xs={24}
                key={index}
              >
                <Form.Item
                  label={equipment.name}
                  name={equipment.key}
                  initialValue={equipment.initialValue}
                >
                  {equipment.type === "radio" ? (
                    renderRadioGroup(equipment.options)
                  ) : equipment.type === "number" ? (
                    <InputNumber
                      addonAfter={equipment.addonAfter}
                      placeholder="e.g. 6, 3, 5.6"
                    />
                  ) : equipment.type === "date" ? (
                    <DatePicker format={'DD-MM-YYYY'} placeholder={dayjs().format("DD/MM/YYYY")} />
                  ) : (
                    <Input placeholder={equipment.placeholder} />
                  )}
                </Form.Item>
              </Col>
            ))}
          </Row>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {/* <Link to={`/surveys/${customerName}/${buildingName}/areas`}>
                </Link> */}
              Save
            </Button>
          </Form.Item>
        </Card>
      </Form>
    </>
  );
};

export default FireExtinguisherEquipments;
