import React, { useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Tag,
  message,
  Input,
  Dropdown,
  Descriptions,
} from "antd";

import { useState } from "react";
import downloadXLSX from "../../employee/features/survey/export";
import APICall from "../../services/apiservices";
import dayjs from "dayjs";
import moment from "moment/moment";
import { Link } from "react-router-dom";
const { Search } = Input;
const userData = JSON.parse(localStorage.getItem("userData"));

const Amc = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [amc, setAMC] = useState([]);

  const [tableParams, setTableParams] = useState({
    filters: {
      searchTerm: "",
    },
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
  });

  const getAMC = async (searchTerm, page, limit) => {
    try {
      if (searchTerm === undefined) searchTerm = "";
      const response = await APICall(
        `/amc?customerId=${userData.customer._id}&page=${page}&limit=${limit}&query=${searchTerm}`,
        {},
        1
      );

      return response.data;
    } catch (error) {
      message.error("Failed to fetch survey details");
      setIsLoading(false);
    }
  };

  const fetchList = async () => {
    setIsLoading(true);
    const page = tableParams.pagination.current;
    const limit = tableParams.pagination.pageSize;
    const searchTerm = tableParams.filters.searchTerm || "";
    const response = await getAMC(searchTerm, page, limit);
    console.log(response);
    setAMC(response?.documents);
    setIsLoading(false);

    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: response.total,
      },
    });
  };

  useEffect(() => {
    fetchList();
  }, [JSON.stringify(tableParams)]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setAMC([]);
    }
  };

  const onSearch = (value) => {
    setTableParams({
      ...tableParams,
      filters: {
        ...tableParams.filters,
        searchTerm: value,
      },
    });
  };

  const columns = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => {
        return (
          tableParams.pagination.pageSize *
            (tableParams.pagination.current - 1) +
          index +
          1
        );
      },
    },
    {
      title: "AMC Number.",
      key: "number",
      render: (_, record) => {
        return "AMC" + record.amcNo.toString().padStart(4, "0");
      },
    },
    {
      title: "Type",
      key: "type",
      render: (_, record) => {
        return (
          <Tag color={record.type === "Comprehensive" ? "blue" : "green"}>
            {record.type}
          </Tag>
        );
      },
    },
    {
      title: "Duration (Months)",
      key: "duration",
      render: (_, record) => {
        return record.duration;
      },
    },
    {
      title: "Total Visits",
      key: "totalVisits",
      render: (_, record) => {
        return record.visitsCount;
      },
    },
    {
      title: "Contract Date",
      key: "date",
      render: (_, record) => {
        return dayjs(record.contractDate).format("DD/MM/YYYY");
      },
    },
    {
      title: "Expiry Date",
      key: "date",
      render: (_, record) => {
        return dayjs(record.expiryDate).format("DD/MM/YYYY");
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Link to={`/amcs/${record._id}`}>
          <Button type="primary">View</Button>
        </Link>
      ),
      align: "center",
      fixed: "right",
    },
  ];

  return (
    <>
      <Row style={{ marginTop: "1rem" }}>
        <Col span={24}>
          <Card
            title={"AMC"}
            extra={
              <Search
                style={{
                  width: 220,
                }}
                placeholder="Search"
                allowClear
                size="middle"
                onSearch={onSearch}
                enterButton
              />
            }
          >
            <Table
              columns={columns}
              dataSource={amc}
              size="small"
              onChange={handleTableChange}
              // pagination={pagi}
              loading={isLoading}
              scroll={{ x: "1000px" }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Amc;
