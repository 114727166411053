import React from "react";
import { LuUser } from "react-icons/lu";
import {
  MdOutlineDashboard,
  MdOutlineEngineering,
  MdOutlineProductionQuantityLimits,
} from "react-icons/md";
import { BsFiletypeDocx } from "react-icons/bs";
import { GoGear } from "react-icons/go";
import { BiDoorOpen } from "react-icons/bi";
import { RiSurveyLine } from "react-icons/ri";
import { PiPhoneDisconnect } from "react-icons/pi";
import {
  FaUsersBetweenLines,
  FaUserFriends,
  FaPoll,
  FaQuestionCircle,
  FaRegComments,
  FaEye,
} from "react-icons/fa";
import { TbFileReport } from "react-icons/tb";
import { AiOutlineUsergroupDelete, AiOutlineEye, AiOutlineDollarCircle, AiOutlinePaperClip, AiOutlineContacts, AiOutlineOrderedList } from "react-icons/ai";
import { BsListUl } from "react-icons/bs";
import { HiOutlineCurrencyDollar, HiOutlineCurrencyRupee } from "react-icons/hi";
import { VscTools } from "react-icons/vsc";
import { PiRadioactive } from "react-icons/pi";
import { Link, useLocation } from "react-router-dom";
import { Divider, Layout, Menu } from "antd";
import { useEffect, useState } from "react";
import { useAuth } from "../../auth/AuthProvider";

const { Sider } = Layout;

const superAdminMenu = [
  {
    label: (
      <Link style={{ textDecoration: "none" }} to="/dashboard">
        Dashboard
      </Link>
    ),
    key: "dashboard",
    icon: <MdOutlineDashboard style={{ fontSize: "20px" }} />,
  },
  {
    label: "Employee",
    key: "employees",
    icon: <MdOutlineEngineering style={{ fontSize: "20px" }} />,
    children: [
      {
        label: <Link to="/employees/manage">Create Employee</Link>,
        key: "createEmployee",
      },
      {
        label: <Link to="/employees">Employee Management</Link>,
        key: "activeEmployee",
      },

      {
        label: <Link to="/employees/attendance">Attendence</Link>,
        key: "empAttendence",
      },
      {
        label: <Link to="/employees/inactive">Inactive Employee</Link>,
        key: "inactiveEmployee",
      },
      {
        label: <Link to="/employees/salary">Salary</Link>,
        key: "employeeSalary",
      },
    ],
  },
  {
    label: "Customers",
    key: "customers",
    icon: <LuUser style={{ fontSize: "20px" }} />,
    children: [
      {
        label: (
          <Link style={{ textDecoration: "none" }} to="/customers/manage">
            Create Customer
          </Link>
        ),
        key: "createCustomer",
      },
      {
        label: (
          <Link style={{ textDecoration: "none" }} to="/customers">
            Customer Management
          </Link>
        ),
        key: "activeCustomers",
      },

      {
        label: (
          <Link style={{ textDecoration: "none" }} to="/customers/inactive">
            Inactive Customers
          </Link>
        ),
        key: "inactiveCustomers",
      },
    ],
  },
  {
    label: "Items",
    key: "items",
    icon: <MdOutlineProductionQuantityLimits style={{ fontSize: "20px" }} />,
    children: [
      {
        label: <Link to="/items/manage">Create Items</Link>,
        key: "createItems",
      },
      {
        label: <Link to="/items">Item Management</Link>,
        key: "itemDetails",
      },
    ],
  },
  {
    label: "AMC",
    key: "amc",
    icon: <VscTools style={{ fontSize: "20px" }} />,
    children: [
      {
        label: <Link to="/amcs/manage">Create AMC</Link>,
        key: "amcCreate",
      },
      {
        label: <Link to="/amcs">AMC Management</Link>,
        key: "amcManagement",
      },
    ],
  },
  {
    label: "Quotation",
    key: "quotation",
    icon: <HiOutlineCurrencyDollar style={{ fontSize: "20px" }} />,
    children: [
      {
        label: <Link to="/quotations/manage">Create Quotation</Link>,
        key: "createQuotation",
      },
      {
        label: <Link to="/quotations">Quotation Management</Link>,
        key: "quotationDetails",
      },
    ],
  },
  {
    label: "Surveys",
    key: "surveys",
    icon: <RiSurveyLine style={{ fontSize: "20px" }} />,
    children: [
      {
        label: <Link to="/surveys">Upcoming Survey</Link>,
        key: "assignedSurveys",
        icon: <FaPoll style={{ fontSize: "20px" }} />,
      },

      {
        label: <Link to="/surveys/completed">Completed Survey</Link>,
        key: "compltedSurveys",
        icon: <FaPoll style={{ fontSize: "20px" }} />,
      },
    ],
  },

  {
    label: "Reports",
    key: "reports",
    icon: <TbFileReport style={{ fontSize: "20px" }} />,
    children: [
      {
        label: (
          <Link style={{ textDecoration: "none" }} to="/reports/formA">
            Form A
          </Link>
        ),
        key: "formA",
        icon: <BsFiletypeDocx style={{ fontSize: "20px" }} />,
      },
      {
        label: (
          <Link style={{ textDecoration: "none" }} to="/reports/formB">
            Form B
          </Link>
        ),
        key: "formB",
        icon: <BsFiletypeDocx style={{ fontSize: "20px" }} />,
      },
      {
        label: (
          <Link
            style={{ textDecoration: "none" }}
            to="/reports/trainingCertificate"
          >
            Traning Certificate
          </Link>
        ),
        key: "trainingCertificate",
        icon: <BsFiletypeDocx style={{ fontSize: "20px" }} />,
      },
      {
        label: (
          <Link
            style={{ textDecoration: "none" }}
            to="/reports/installationCertificate"
          >
            Installation Report
          </Link>
        ),
        key: "installationCertificate",
        icon: <BsFiletypeDocx style={{ fontSize: "20px" }} />,
      },
      {
        label: (
          <Link
            style={{ textDecoration: "none" }}
            to="/reports/testCertificate"
          >
            Test Certificate
          </Link>
        ),
        key: "testCertificate",
        icon: <BsFiletypeDocx style={{ fontSize: "20px" }} />,
      },
      // {
      //   label: (
      //     <Divider dashed />
      //   ),
      //   key: "testCertificates",
      // },
    ],
  },

  {
    label: "PO",
    key: "purchaseOrder",
    icon: <AiOutlineDollarCircle style={{ fontSize: "20px" }} />,
    children: [
      {
        label: <Link to="/purchaseOrder/manage">Create PO</Link>,
        key: "purchaseOrderCreate",
      },
      {
        label: <Link to="/purchaseOrder">PO Management</Link>,
        key: "purchaseOrderManagement",
      },
    ],
  },

  {
    label: "BOQ",
    key: "boq",
    icon: <AiOutlinePaperClip style={{ fontSize: "20px" }} />,
    children: [
      {
        label: <Link to="/boq/manage">Create BOQ</Link>,
        key: "boqCreate",
      },
      {
        label: <Link to="/boq">BOQ Management</Link>,
        key: "boqManagement",
      },
      {
        label: <Link to="/boqItems/manage">Create BOQ Item</Link>,
        key: "boqItemsCreate",
      },
      {
        label: <Link to="/boqItems">BOQ Items Management</Link>,
        key: "boqItemsManagement",
      },
    ],
  },

  {
    label: "Lead",
    key: "lead",
    icon: <AiOutlineContacts style={{ fontSize: "20px" }} />,
    children: [
      {
        label: <Link to="/lead/manage">Create Lead</Link>,
        key: "leadCreate",
      },
      {
        label: <Link to="/lead">Lead Management</Link>,
        key: "leadManagement",
      },
    ],
  },

  {
    label: "Task Diary",
    key: "task",
    icon: <AiOutlineOrderedList style={{ fontSize: "20px" }} />,
    children: [
      {
        label: <Link to="/task/manage">Create Task</Link>,
        key: "taskCreate",
      },
      {
        label: <Link to="/task">Task Diary</Link>,
        key: "taskManagement",
      },
    ],
  },

  {
    label: "Cash Ledger",
    key: "cashLedger",
    icon: <HiOutlineCurrencyRupee style={{ fontSize: "20px" }} />,
    children: [
      {
        label: <Link to="/cashLedger/manage?type=Sell">Create Sell Cash Ledger</Link>,
        key: "cashLedgerCreate",
      },
      {
        label: <Link to="/cashLedger/manage?type=Purchase">Create Purchase Cash Ledger</Link>,
        key: "cashLedgerCreate",
      },
      {
        label: <Link to="/cashLedger?type=Sell">Sell Cash Ledger Management</Link>,
        key: "cashLedgerManagement",
      },
      {
        label: <Link to="/cashLedger?type=Purchase">Purchase Cash Ledger Management</Link>,
        key: "cashLedgerManagement",
      },
    ],
  },
  {
    label: "License Copy",
    key: "licenseCopy",
    icon: <VscTools style={{ fontSize: "20px" }} />,
    children: [
      {
        label: <Link to="/licenseCopy/manage">Create License Copy</Link>,
        key: "licenseCopyCreate",
      },
      {
        label: <Link to="/licenseCopy">License Copy Management</Link>,
        key: "licenseCopyManagement",
      },
    ],
  },
  {
    label: (
      <Link style={{ textDecoration: "none" }} to="/settings">
        Settings
      </Link>
    ),
    key: "settings",
    icon: <GoGear style={{ fontSize: "20px" }} />,
  }
];

const AdminMenu = [
  {
    label: (
      <Link style={{ textDecoration: "none" }} to="/dashboard">
        Dashboard
      </Link>
    ),
    key: "dashboard",
    icon: <MdOutlineDashboard style={{ fontSize: "20px" }} />,
  },
  {
    label: "Employee",
    key: "employees",
    icon: <MdOutlineEngineering style={{ fontSize: "20px" }} />,
    children: [
      {
        label: <Link to="/employees/manage">Create Employee</Link>,
        key: "createEmployee",
      },
      {
        label: <Link to="/employees">Employee Management</Link>,
        key: "activeEmployee",
      },

      {
        label: <Link to="/employees/attendance">Attendence</Link>,
        key: "empAttendence",
      },
      {
        label: <Link to="/employees/inactive">Inactive Employee</Link>,
        key: "inactiveEmployee",
      },
      {
        label: <Link to="/employees/salary">Salary</Link>,
        key: "employeeSalary",
      },
    ],
  },
  {
    label: "Customers",
    key: "customers",
    icon: <LuUser style={{ fontSize: "20px" }} />,
    children: [
      {
        label: (
          <Link style={{ textDecoration: "none" }} to="/customers/manage">
            Create Customer
          </Link>
        ),
        key: "createCustomer",
      },
      {
        label: (
          <Link style={{ textDecoration: "none" }} to="/customers">
            Customer Management
          </Link>
        ),
        key: "activeCustomers",
      },

      {
        label: (
          <Link style={{ textDecoration: "none" }} to="/customers/inactive">
            Inactive Customers
          </Link>
        ),
        key: "inactiveCustomers",
      },
    ],
  },
  {
    label: "Items",
    key: "items",
    icon: <MdOutlineProductionQuantityLimits style={{ fontSize: "20px" }} />,
    children: [
      {
        label: <Link to="/items/manage">Create Items</Link>,
        key: "createItems",
      },
      {
        label: <Link to="/items">Item Management</Link>,
        key: "itemDetails",
      },
    ],
  },
  {
    label: "AMC",
    key: "amc",
    icon: <VscTools style={{ fontSize: "20px" }} />,
    children: [
      {
        label: <Link to="/amcs/manage">Create AMC</Link>,
        key: "amcCreate",
      },
      {
        label: <Link to="/amcs">AMC Management</Link>,
        key: "amcManagement",
      },
    ],
  },
  {
    label: "Quotation",
    key: "quotation",
    icon: <HiOutlineCurrencyDollar style={{ fontSize: "20px" }} />,
    children: [
      {
        label: <Link to="/quotations/manage">Create Quotation</Link>,
        key: "createQuotation",
      },
      {
        label: <Link to="/quotations">Quotation Management</Link>,
        key: "quotationDetails",
      },
    ],
  },
  {
    label: "Surveys",
    key: "surveys",
    icon: <RiSurveyLine style={{ fontSize: "20px" }} />,
    children: [
      {
        label: <Link to="/surveys">Upcoming Survey</Link>,
        key: "assignedSurveys",
        icon: <FaPoll style={{ fontSize: "20px" }} />,
      },

      {
        label: <Link to="/surveys/completed">Completed Survey</Link>,
        key: "compltedSurveys",
        icon: <FaPoll style={{ fontSize: "20px" }} />,
      },
    ],
  },

  {
    label: "Reports",
    key: "reports",
    icon: <TbFileReport style={{ fontSize: "20px" }} />,
    children: [
      {
        label: (
          <Link style={{ textDecoration: "none" }} to="/reports/formA">
            Form A
          </Link>
        ),
        key: "formA",
        icon: <BsFiletypeDocx style={{ fontSize: "20px" }} />,
      },
      {
        label: (
          <Link style={{ textDecoration: "none" }} to="/reports/formB">
            Form B
          </Link>
        ),
        key: "formB",
        icon: <BsFiletypeDocx style={{ fontSize: "20px" }} />,
      },
      {
        label: (
          <Link
            style={{ textDecoration: "none" }}
            to="/reports/trainingCertificate"
          >
            Traning Certificate
          </Link>
        ),
        key: "trainingCertificate",
        icon: <BsFiletypeDocx style={{ fontSize: "20px" }} />,
      },
      {
        label: (
          <Link
            style={{ textDecoration: "none" }}
            to="/reports/installationCertificate"
          >
            Installation Report
          </Link>
        ),
        key: "installationCertificate",
        icon: <BsFiletypeDocx style={{ fontSize: "20px" }} />,
      },
      {
        label: (
          <Link
            style={{ textDecoration: "none" }}
            to="/reports/testCertificate"
          >
            Test Certificate
          </Link>
        ),
        key: "testCertificate",
        icon: <BsFiletypeDocx style={{ fontSize: "20px" }} />,
      },
    ],
  },

  {
    label: (
      <Link style={{ textDecoration: "none" }} to="/settings">
        Settings
      </Link>
    ),
    key: "settings",
    icon: <GoGear style={{ fontSize: "20px" }} />,
  },
];

const employeeMenu = [
  {
    label: (
      <Link style={{ textDecoration: "none" }} to="/dashboard">
        Dashboard
      </Link>
    ),
    key: "dashboard",
    icon: <MdOutlineDashboard style={{ fontSize: "20px" }} />,
  },
  {
    label: "Survey",
    key: "surveys",
    icon: <RiSurveyLine style={{ fontSize: "20px" }} />,
    children: [
      {
        label: <Link to="/surveys">Assigned Surveys</Link>,
        key: "assignedSurveys",
        icon: <RiSurveyLine style={{ fontSize: "20px" }} />,
      },
      {
        label: <Link to="/surveys/completed">Completed Surveys</Link>,
        key: "compltedSurveys",
        icon: <RiSurveyLine style={{ fontSize: "20px" }} />,
      },
    ],
  },
  {
    label: "AMC Visits",
    key: "visits",
    icon: <AiOutlineEye style={{ fontSize: "20px" }} />,
    children: [
      {
        label: <Link to="/visits">Upcoming AMC Visits </Link>,
        key: "upcomingVisits",
        icon: <RiSurveyLine style={{ fontSize: "20px" }} />,
      },
      {
        label: <Link to="/visits/completed">Completed AMC Visits</Link>,
        key: "compltedVisits",
        icon: <RiSurveyLine style={{ fontSize: "20px" }} />,
      },
    ],
  },
  {
    label: <Link to="/attendance">Attendance</Link>,
    key: "attendance",
    icon: <AiOutlineUsergroupDelete style={{ fontSize: "20px" }} />,
  },
];

const customerMenu = [
  {
    label: (
      <Link style={{ textDecoration: "none" }} to="/dashboard">
        Dashboard
      </Link>
    ),
    key: "dashboard",
    icon: <MdOutlineDashboard style={{ fontSize: "20px" }} />,
  },
  {
    label: <Link to="/surveys">Survey</Link>,
    key: "surveys",
    icon: <RiSurveyLine style={{ fontSize: "20px" }} />,
  },
  {
    label: "AMC",
    key: "amc",
    icon: <VscTools style={{ fontSize: "20px" }} />,
    children: [
      {
        label: <Link to="/amcs/active">Active AMC</Link>,
        key: "activeAMC",
        icon: <PiRadioactive style={{ fontSize: "20px" }} />,
      },
      {
        label: <Link to="/amcs">All AMC</Link>,
        key: "amcManagement",
        icon: <BsListUl style={{ fontSize: "20px" }} />,
      },
    ],
  },
  {
    label: <Link to="/reports">Reports</Link>,
    key: "reports",
    icon: <TbFileReport style={{ fontSize: "20px" }} />,
  },
  {
    label: (
      <Link style={{ textDecoration: "none" }} to="/contact">
        Contact Us
      </Link>
    ),
    key: "contact",
    icon: <PiPhoneDisconnect style={{ fontSize: "20px" }} />,
  },
];

const SiteSidebar = ({ active }) => {
  const { userData } = useAuth();
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const rootSubmenuKeys = [
    "employees",
    "customers",
    "items",
    "amc",
    "quotation",
    "surveys",
    "visits",
    "attendance",
    "reports",
  ];

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    let tempSelectedKeys = [];
    let tempOpenKeys = [];
    switch (location.pathname.split("/").slice(0, 3).join("/")) {
      case "/dashboard":
        tempSelectedKeys = ["dashboard"];
        break;
      case "/employees":
        tempSelectedKeys = ["employees", "activeEmployee"];
        tempOpenKeys = ["employees"];
        break;
      case "/employees":
        tempSelectedKeys = ["employees", "activeEmployee"];
        tempOpenKeys = ["employees"];
        break;
      case "/reports":
        tempSelectedKeys = ["reports"];
        break;
      case "/employees/inactive":
        tempSelectedKeys = ["employees", "inactiveEmployee"];
        tempOpenKeys = ["employees"];
        break;
      case "/employees/salary":
        tempSelectedKeys = ["employees", "employeeSalary"];
        tempOpenKeys = ["employees"];
        break;
      case "/employees/manage":
        tempSelectedKeys = ["employees", "createEmployee"];
        tempOpenKeys = ["employees"];
        break;
      case "/employees/attendance":
        tempSelectedKeys = ["employees", "empAttendence"];
        tempOpenKeys = ["employees"];
        break;
      case "/customers":
        tempSelectedKeys = ["customers", "activeCustomers"];
        tempOpenKeys = ["customers"];
        break;
      case "/customers/inactive":
        tempSelectedKeys = ["customers", "inactiveCustomers"];
        tempOpenKeys = ["customers"];
        break;
      case "/customers/manage":
        tempSelectedKeys = ["customers", "createCustomer"];
        tempOpenKeys = ["customers"];
        break;
      case "/items":
        tempSelectedKeys = ["items", "itemDetails"];
        tempOpenKeys = ["items"];
        break;
      case "/items/manage":
        tempSelectedKeys = ["items", "createItems"];
        tempOpenKeys = ["items"];
        break;
      case "/amcs":
        tempSelectedKeys = ["amc", "amcManagement"];
        tempOpenKeys = ["amc"];
        break;
      case "/amcs/manage":
        tempSelectedKeys = ["amc", "amcCreate"];
        tempOpenKeys = ["amc"];
        break;
      case "/amcs/active":
        tempSelectedKeys = ["amc", "activeAMC"];
        tempOpenKeys = ["amc"];
        break;
      case "/amcs/details":
        tempSelectedKeys = ["amc", "amcManagement"];
        tempOpenKeys = ["amc"];
        break;
      case "/quotations":
        tempSelectedKeys = ["quotation", "quotationDetails"];
        tempOpenKeys = ["quotation"];
        break;
      case "/quotations/manage":
        tempSelectedKeys = ["quotation", "createQuotation"];
        tempOpenKeys = ["quotation"];
        break;
      case "/surveys":
        tempSelectedKeys = ["surveys", "assignedSurveys"];
        tempOpenKeys = ["surveys"];
        break;
      case "/surveys/completed":
        tempSelectedKeys = ["surveys", "compltedSurveys"];
        tempOpenKeys = ["surveys"];
        break;
      case "/visits":
        tempSelectedKeys = ["visits", "upcomingVisits"];
        tempOpenKeys = ["visits"];
        break;
      case "/visits/completed":
        tempSelectedKeys = ["visits", "compltedVisits"];
        tempOpenKeys = ["visits"];
        break;
      case "/attendance":
        tempSelectedKeys = ["attendance", "AttendanceDetails"];
        tempOpenKeys = ["attendance"];
        break;
      case "/surveys/manage":
        tempSelectedKeys = ["surveys", "surveyQuotation"];
        tempOpenKeys = ["surveys"];
        break;
      case "/reports/formA":
        tempSelectedKeys = ["reports", "formA"];
        tempOpenKeys = ["reports"];
        break;
      case "/reports/formB":
        tempSelectedKeys = ["reports", "formB"];
        tempOpenKeys = ["reports"];
        break;
      case "/reports/trainingCertificate":
        tempSelectedKeys = ["reports", "trainingCertificate"];
        tempOpenKeys = ["reports"];
        break;
      case "/reports/installationCertificate":
        tempSelectedKeys = ["reports", "installationCertificate"];
        tempOpenKeys = ["reports"];
        break;
      case "/reports/testCertificate":
        tempSelectedKeys = ["reports", "testCertificate"];
        tempOpenKeys = ["reports"];
        break;

      case "/settings":
        tempSelectedKeys = ["settings"];
        break;
      case "/contact":
        tempSelectedKeys = ["contact"];
        break;
      default:
        break;
    }
    setSelectedKeys(tempSelectedKeys);
    setOpenKeys(tempOpenKeys);
  }, [location.pathname]);

  useEffect(() => {
    if (userData?.employee) {
      switch (userData?.employee?.accessType) {
        case "Admin":
          setMenuItems(AdminMenu);
        case "Super Admin":
          setMenuItems(superAdminMenu);
          break;
        case "General Employee":
          setMenuItems(employeeMenu);
      }
    } else {
      setMenuItems(customerMenu);
    }
  }, [userData]);

  return (
    <Sider width={250} breakpoint="lg" collapsedWidth="0">
      <Menu
        mode="inline"
        defaultSelectedKeys={active}
        style={{ height: "100%", borderRight: 0, backgroundColor: "#fffaf7" }}
        items={menuItems}
        selectedKeys={selectedKeys}
        onOpenChange={onOpenChange}
        openKeys={openKeys}
      />
    </Sider>
  );
};

export default SiteSidebar;
