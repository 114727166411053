import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Form,
  Input,
  Button,
  Row,
  Col,
  Space,
  Image,
  Upload,
  message,
} from "antd";
import APICall from "../../../services/apiservices";
import axios from "axios";

const ManageItems = ({ isEdit }) => {
  const [itemData, setItemData] = useState();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [img, setImg] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [photoFileId, setPhotoFileId] = useState();
  const [photoURL, setPhotoURL] = useState();

  const showImage = (img) => {
    setImg(img);
    console.log(img);
    setVisible(true);
  };

  const navigate = useNavigate();

  const params = useParams();

  const getItemDetails = async () => {
    try {
      console.log(params.id);
      const response = await APICall(`/item/${params.id}`, {}, 1);
      setItemData(response.data);
      setImg(response.data.photoURL);
      setIsLoading(false);
    } catch (error) {
      message.error(error.response.data.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isEdit) {
      setIsLoading(true);
      getItemDetails();
    }
  }, []);

  useEffect(() => {
    if (itemData) {
      const initialValues = {
        name: itemData?.name,
        hsn: itemData?.hsn,
        price: itemData?.price,
        sgst: itemData?.sgst,
        cgst: itemData?.cgst,
        description: itemData?.description,
        photo: itemData?.photo,
      };
      form.setFieldsValue(initialValues);
    }
  }, [itemData]);

  const onFinish = async (values) => {
    setIsLoading(!isLoading);
    if (isEdit) {
      const response = await APICall(
        `/item/${params.id}`,
        { ...values, photoFileId: photoFileId },
        3
      );
      if (response.data) {
        setIsLoading(false);
        message.success("Item has been updated successfully.");
        navigate("/items");
      }
      try {
      } catch (error) {
        setIsLoading(false);
        message.error(error.response.data.message);
      }
    } else {
      try {
        const response = await APICall(
          `/item`,
          { ...values, photoFileId: photoFileId },
          2
        );
        setIsLoading(false);
        if (response.data) {
          message.success("Item has been created successfully.");
        }
        navigate("/items");
      } catch (error) {
        setIsLoading(false);
        message.error(error.response.data.message);
      }
    }
  };

  const handleFileUpload = async (file, fileType) => {
    const fileData = {
      requirement: [
        {
          order: 0,
          extension: file.name.substring(file.name.lastIndexOf(".") + 1),
        },
      ],
      module: "Item",
    };

    const fileResponse = await APICall("/file", fileData, 2);
    await axios.put(fileResponse.data.data[0].url, file);
    setPhotoFileId(fileResponse.data.data[0].id);
    showImage(await getBase64(file));
    return false;
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const validatePercentage = (_, value) => {
    if (!value) return Promise.resolve();

    const percentageRegex = /^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/;

    if (!percentageRegex.test(value)) {
      return Promise.reject("Please enter a valid percentage");
    }
    return Promise.resolve();
  };

  return (
    <Card
      title={isEdit ? "Edit Item" : "Create Item"}
      // extra={
      //   <>
      //     <Link to="/items">
      //       <Button type="primary">Back</Button>
      //     </Link>
      //   </>
      // }
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please enter the name of the item",
                },
              ]}
            >
              <Input placeholder="e.g. CNG Cylindar (14 KG)" tabIndex={1} />
            </Form.Item>

            <Form.Item
              label="SGST (%)"
              name="sgst"
              rules={[
                {
                  validator: validatePercentage,
                },
              ]}
            >
              <Input placeholder="9" type="number" min={1} tabIndex={5} />
            </Form.Item>

            <Form.Item name="description" label="Description">
              <Input.TextArea
                placeholder="Enter description of the item"
                tabIndex={6}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="hsn"
              label="HSN"
              rules={[
                { required: true, message: "Please enter the HSN of the item" },
              ]}
            >
              <Input placeholder="e.g. 52456" tabIndex={2} />
            </Form.Item>

            <Form.Item
              label="CGST (%)"
              name="cgst"
              rules={[
                {
                  validator: validatePercentage,
                },
              ]}
            >
              <Input placeholder="9" type="number" tabIndex={6} min={1} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="price"
              label="Price (₹)"
              rules={[
                {
                  required: true,
                  message: "Please enter the price of the item",
                },
                {
                  pattern: /^[0-9]+(\.[0-9]{1,2})?$/,
                  message: "Please enter a valid price",
                },
              ]}
            >
              <Input placeholder="e.g. 1400" tabIndex={3} />
            </Form.Item>

            <Form.Item
              name="image"
              label="Photo"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please select an image",
              //   },
              // ]}
            >
              <Space>
                <Upload
                  name="productPhoto"
                  accept=".jpg, .png"
                  beforeUpload={handleFileUpload}
                  multiple={false}
                  onRemove={() => {
                    setImg(null);
                    setPhotoFileId(null);
                  }}
                >
                  <Button tabIndex={5}>Click to Upload</Button>
                </Upload>
                {isEdit && (
                  <Button
                    type="link"
                    onClick={() => {
                      showImage(itemData.photoURL);
                    }}
                  >
                    Click Here To Preview
                  </Button>
                )}
              </Space>
              {isEdit && (
                <Image
                  preview={{
                    visible,
                    src: img,
                    onVisibleChange: (value) => {
                      setVisible(value);
                    },
                  }}
                />
              )}
            </Form.Item>

            {img && <Image height={50} width={50} src={img} />}
          </Col>
        </Row>

        <Form.Item style={{ marginTop: "20px" }}>
          <Button
            type="primary"
            htmlType="submit"
            tabIndex={7}
            //  style={{ float: "right" }}
          >
            {isEdit ? "Save" : "Create"}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default ManageItems;
