import React from "react";
import { utils, write } from "xlsx-js-style";

const allBorder = {
  left: {
    style: "thin",
    color: {
      rgb: "000000",
    },
  },
  right: {
    style: "thin",
    color: {
      rgb: "000000",
    },
  },
  bottom: {
    style: "thin",
    color: {
      rgb: "000000",
    },
  },
  top: {
    style: "thin",
    color: {
      rgb: "000000",
    },
  },
};

const organizationHeaderStyle = {
  alignment: {
    horizontal: "center",
    vertical: "center",
    wrapText: true,
  },
  font: {
    bold: true,
    sz: 28,
    name: "Times New Roman",
  },
  fill: {
    fgColor: { rgb: "C2D69B" },
  },
  border: allBorder,
};

const organizationSubHeaderStyle = {
  alignment: {
    horizontal: "center",
    vertical: "center",
    wrapText: true,
  },
  font: {
    bold: true,
    sz: 14,
    name: "Times New Roman",
  },
  fill: {
    fgColor: { rgb: "C2D69B" },
  },
  border: allBorder,
};

const boldStyle = {
  font: {
    bold: true,
    sz: 12,
    name: "Times New Roman",
  },
  border: allBorder,
};

const centerAlignment = {
  alignment: {
    horizontal: "center",
    vertical: "center",
    wrapText: true,
  },
};

const columnTitleStyle = {
  ...boldStyle,
  ...centerAlignment,
  fill: {
    fgColor: { rgb: "00b0f0" },
  },
};

const getFinalArray = (array, placeholder = "", style) => {
  const totalLength = 13;
  const finalArray = [...array];
  const otherArray = [];
  if (finalArray.length <= totalLength)
    for (let i = finalArray.length; i < totalLength; i++)
      otherArray.push({ v: placeholder, s: style });
  const newFinalArray = [...finalArray, ...otherArray];
  return newFinalArray;
};

const alphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'];

const getBuildingSheet = ({
  organization,
  customer,
  systems,
  termsAndConditions,
}) => {
    const systemsRows = [];

    systems.map((system, systemIndex) => {
        systemsRows.push(getFinalArray([]))
        systemsRows.push(getFinalArray([
            {
                v: alphabets[systemIndex],
                s: { ...columnTitleStyle, alignment: {} },
            },
            {
                v: system.name,
                s: { ...columnTitleStyle, alignment: {} },
            },
        ], "", columnTitleStyle))

        system.items.map((item, index) => {
            systemsRows.push(getFinalArray([
                { v: `${index + 1}` ?? '', s: {alignment: { wrapText: true, vertical: "top", horizontal: "left" }}},
                { v: item.name ?? '', s: { ...centerAlignment, alignment: { wrapText: true, vertical: "top", horizontal: "left" } },},
                { v: item.make ?? '', s: centerAlignment},
                { v: item.unit ?? '', s: centerAlignment },
                { v: item.quantity ?? '', s: centerAlignment },
                { v: item.materialRate ?? '', s: centerAlignment },
                { v: item.materialTotal ?? '', s: centerAlignment },
                { v: item.labourRate ?? '', s: centerAlignment },
                { v: item.labourTotal ?? '', s: centerAlignment },
                { v: item.purchaseRate ?? '', s: centerAlignment },
                { v: item.internalmaterialTotal ?? '', s: centerAlignment },
                { v: item.labourInternalRate ?? '', s: centerAlignment },
                { v: item.internallabourTotal ?? '', s: centerAlignment }
            ]))
            if(item.variations && item.variations.length > 0) {
              item.variations.map((variation, subIndex) => {
                    systemsRows.push(getFinalArray([
                        { v: `${index + 1}.${subIndex + 1}` ?? '', s: {alignment: { wrapText: true, vertical: "top", horizontal: "left" }}},
                        { v: variation.name ?? '', s: { ...centerAlignment, alignment: { wrapText: true, vertical: "top", horizontal: "left" } },},
                        { v: variation.make ?? '', s: centerAlignment },
                        { v: variation.unit ?? '', s: centerAlignment },
                        { v: variation.quantity ?? '', s: centerAlignment },
                        { v: variation.materialRate ?? '', s: centerAlignment },
                        { v: variation.materialTotal ?? '', s: centerAlignment },
                        { v: variation.labourRate ?? '', s: centerAlignment },
                        { v: variation.labourTotal ?? '', s: centerAlignment },
                        { v: variation.purchaseRate ?? '', s: centerAlignment },
                        { v: variation.internalmaterialTotal ?? '', s: centerAlignment },
                        { v: variation.labourInternalRate ?? '', s: centerAlignment },
                        { v: variation.internallabourTotal ?? '', s: centerAlignment }
                    ]))
                })
            }
        })
        systemsRows.push(getFinalArray([
            {
                v: alphabets[systemIndex],
                s: { ...columnTitleStyle, alignment: {}, fill: { fgColor: { rgb: "92d050" }, }, },
            },
            {
                v: `${system.name}: Total`,
                s: { ...columnTitleStyle, alignment: {}, fill: { fgColor: { rgb: "92d050" }, }, },
            },
            {v: ``, s: { ...columnTitleStyle, alignment: {}, fill: { fgColor: { rgb: "92d050" }, }, },},
            {v: ``, s: { ...columnTitleStyle, alignment: {}, fill: { fgColor: { rgb: "92d050" }, }, },},
            {v: ``, s: { ...columnTitleStyle, alignment: {}, fill: { fgColor: { rgb: "92d050" }, }, },},
            {v: ``, s: { ...columnTitleStyle, alignment: {}, fill: { fgColor: { rgb: "92d050" }, }, },},
            {v: `${system?.total?.materialTotal}`, s: { ...columnTitleStyle, alignment: { horizontal: "center", vertical: "center" }, fill: { fgColor: { rgb: "92d050" }, }, },},
            {v: ``, s: { ...columnTitleStyle, alignment: {}, fill: { fgColor: { rgb: "92d050" }, }, },},
            {v: `${system?.total?.labourTotal}`, s: { ...columnTitleStyle, alignment: { horizontal: "center", vertical: "center" }, fill: { fgColor: { rgb: "92d050" }, }, },},
            {v: ``, s: { ...columnTitleStyle, alignment: {}, fill: { fgColor: { rgb: "92d050" }, }, },},
            {v: `${system?.total?.internalmaterialTotal}`, s: { ...columnTitleStyle, alignment: { horizontal: "center", vertical: "center" }, fill: { fgColor: { rgb: "92d050" }, }, },},
            {v: ``, s: { ...columnTitleStyle, alignment: {}, fill: { fgColor: { rgb: "92d050" }, }, },},
            {v: `${system?.total?.internallabourTotal}`, s: { ...columnTitleStyle, alignment: { horizontal: "center", vertical: "center" }, fill: { fgColor: { rgb: "92d050" }, }, },},
        ]))
    });

  const data = [
    getFinalArray([{ v: organization.name, s: organizationHeaderStyle }]),
    getFinalArray([{ v: organization.address, s: organizationSubHeaderStyle }]),
    getFinalArray([
      {
        v: `Contact: ${organization.mobile} E-Mail: ${organization.email}`,
        s: organizationSubHeaderStyle,
      },
    ]),
    getFinalArray([""]),
    getFinalArray([
        {
            v: `To`,
            s: { ...boldStyle, alignment: {} },
        },
        {
            v: customer.name,
            s: { ...boldStyle, alignment: {} },
        },
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        {
            v: `Quote No.: ${'FFSS/23-24/143'}`,
            s: { ...boldStyle, alignment: {} },
        },
    ]),
    getFinalArray([
        "",
        {
            v: customer.address,
            s: { ...boldStyle, alignment: {} },
        },
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        {
            v: `Date: ${'11-12-2023'}`,
            s: { ...boldStyle, alignment: {} },
        },
      ]),
    getFinalArray([]),
    getFinalArray([
        { v: "Sr. No.", s: columnTitleStyle },
        { v: `Particulars`, s: columnTitleStyle },
        { v: "Make", s: columnTitleStyle },
        { v: "Unit", s: columnTitleStyle },
        { v: "Quantity", s: columnTitleStyle },
        { v: "Material", s: columnTitleStyle },
        "",
        { v: "Labour", s: columnTitleStyle },
        "",
        { v: "Material Working", s: columnTitleStyle },
        "",
        { v: "Labour Working", s: columnTitleStyle },
        "",
      ]),
    getFinalArray([
        "",
        "",
        "",
        "",
        "",
        { v: "Rate", s: columnTitleStyle },
        { v: "Amount", s: columnTitleStyle },
        { v: "Rate", s: columnTitleStyle },
        { v: "Amount", s: columnTitleStyle },
        { v: "Rate", s: columnTitleStyle },
        { v: "Amount", s: columnTitleStyle },
        { v: "Rate", s: columnTitleStyle },
        { v: "Amount", s: columnTitleStyle },
    ]),
    ...systemsRows,
    getFinalArray([]),
    getFinalArray([
      { v: "Other Terms & Conditions", s: { ...columnTitleStyle, fill: { fgColor: { rgb: "c5d9f1" }, }, } },
    ]),
    getFinalArray([
      { v: termsAndConditions, s: { alignment: { wrapText: true, vertical: "top", horizontal: "left" } }}
    ]),
  ];

  // Create a worksheet
  const ws = utils.aoa_to_sheet(data);

  // Add border to each cell
  Object.keys(ws).map((k) => {
    if (typeof ws[k] == "object") {
      ws[k].s = ws[k].s
        ? { ...ws[k].s, border: allBorder }
        : { border: allBorder };
    }
  });

  const calculateRowHeight = (text, width, fontSize = 12) => {
    const averageCharWidth = 1.2; // Rough average width per character
    const lineLength = width || 50; // Width for a single line of text
    const numLines = Math.ceil((text.length * averageCharWidth) / lineLength);
  
    // Adjust these multipliers as needed
    const baseHeight = fontSize * 1.5; // Increased base height scaling for font size
    const lineHeight = fontSize * 1.2; // Height for each additional line
  
    return baseHeight + lineHeight * (numLines - 1);
  };
  
  // Dynamically calculate row heights based on content and font size
  ws["!rows"] = data.map((row) => {
    const maxHeight = row.reduce((height, cell) => {
      const text = typeof cell === "string" ? cell : cell.v || "";
      const fontSize = cell?.s?.font?.sz || 12; // Default font size 12 if not specified
      const columnWidth = 50; // Width for text wrapping estimation
  
      return Math.max(height, calculateRowHeight(text, columnWidth, fontSize));
    }, 15); // Default height if no content
  
    return { hpt: maxHeight };
  });  

  ws["!cols"] = [
    { wch: 8 },
    { wch: 48 },
    { wch: 12 },
    { wch: 12 },
    { wch: 12 },
    { wch: 12 },
    { wch: 12 },
    { wch: 12 },
    { wch: 12 },
    { wch: 12 },
    { wch: 12 },
    { wch: 12 },
    { wch: 12 },
  ];

    const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 12 } }, // Header
        { s: { r: 1, c: 0 }, e: { r: 1, c: 12 } }, // Subheader
        { s: { r: 2, c: 0 }, e: { r: 2, c: 12 } }, // Subheader
        { s: { r: 3, c: 0 }, e: { r: 3, c: 12 } }, // Break

        { s: { r: 4, c: 1 }, e: { r: 4, c: 9 } }, // Customer Name
        { s: { r: 4, c: 10 }, e: { r: 4, c: 12 } }, // Quote No.
        { s: { r: 5, c: 1 }, e: { r: 5, c: 9 } }, // Customer Address
        { s: { r: 5, c: 10 }, e: { r: 5, c: 12 } }, // Date
        { s: { r: 6, c: 0 }, e: { r: 6, c: 12 } }, // Break

        { s: { r: 7, c: 0 }, e: { r: 8, c: 0 } }, // Column Title 1
        { s: { r: 7, c: 1 }, e: { r: 8, c: 1 } }, // Column Title 2
        { s: { r: 7, c: 2 }, e: { r: 8, c: 2 } }, // Column Title 3
        { s: { r: 7, c: 3 }, e: { r: 8, c: 3 } }, // Column Title 4
        { s: { r: 7, c: 4 }, e: { r: 8, c: 4 } }, // Column Title 5
        { s: { r: 7, c: 5 }, e: { r: 7, c: 6 } }, // Column Title 6
        { s: { r: 7, c: 7 }, e: { r: 7, c: 8 } }, // Column Title 7
        { s: { r: 7, c: 9 }, e: { r: 7, c: 10 } }, // Material Working Rate
        { s: { r: 7, c: 11 }, e: { r: 7, c: 12 } },

        { s: { r: 10 + systemsRows.length, c: 0 }, e: { r: 10 + systemsRows.length, c: 12 } }, // Other Terms & Condition
        { s: { r: 11 + systemsRows.length, c: 0 }, e: { r: 11 + systemsRows.length, c: 12 } }, // Other Terms & Condition
    ];

    ws["!merges"] = merge;

    return ws;
};

const getAbstractSheet = ({ systems, total }) => {
  const systemsRows = [];

  systems.map((system, systemIndex) => {
    systemsRows.push([
      alphabets[systemIndex],
      system.name,
      { v: system?.total?.materialTotal ?? '', s: centerAlignment },
      { v: system?.total?.labourTotal ?? '', s: centerAlignment },
      { v: system?.total?.internalmaterialTotal ?? '', s: centerAlignment },
      { v: system?.total?.internallabourTotal ?? '', s: centerAlignment },
    ])
  });

  systemsRows.push([
    {
        v: 'Total (₹)',
        s: { ...columnTitleStyle, alignment: {horizontal: "right", wrapText: true,}, fill: { fgColor: { rgb: "92d050" }, }, },
    },
    {v: ``, s: { ...columnTitleStyle, alignment: {}, fill: { fgColor: { rgb: "92d050" }, }, },},
    {v: `${total?.materialAmount}`, s: { ...columnTitleStyle, alignment: {horizontal: "center"}, fill: { fgColor: { rgb: "92d050" }, }, },},
    {v: `${total?.labourAmount}`, s: { ...columnTitleStyle, alignment: {horizontal: "center"}, fill: { fgColor: { rgb: "92d050" }, }, },},
    {v: `${total?.internalmaterialAmount}`, s: { ...columnTitleStyle, alignment: {horizontal: "center"}, fill: { fgColor: { rgb: "92d050" }, }, },},
    {v: `${total?.internallabourAmount}`, s: { ...columnTitleStyle, alignment: {horizontal: "center"}, fill: { fgColor: { rgb: "92d050" }, }, },},
  ])

  systemsRows.push([
    {
        v: '(Material + Labout) Grand Total (₹)',
        s: { ...columnTitleStyle, alignment: {horizontal: "right", wrapText: true,}, fill: { fgColor: { rgb: "92d050" }, }, },
    },
    {v: ``, s: { ...columnTitleStyle, alignment: {}, fill: { fgColor: { rgb: "92d050" }, }, },},
    {v: `${total?.grand}`, s: { ...columnTitleStyle, alignment: {horizontal: "center"}, fill: { fgColor: { rgb: "92d050" }, }, },},
    {v: ``, s: { ...columnTitleStyle, alignment: {}, fill: { fgColor: { rgb: "92d050" }, }, },},
    {v: `${total?.internalgrand}`, s: { ...columnTitleStyle, alignment: {horizontal: "center"}, fill: { fgColor: { rgb: "92d050" }, }, },},
    {v: ``, s: { ...columnTitleStyle, alignment: {}, fill: { fgColor: { rgb: "92d050" }, }, },},
  ])

  const data = [
    [
      {
        v: `Abstract`,
        s: columnTitleStyle,
      },
      "",
      "",
      "",
    ],
    [
      { v: "Sr. No", s: columnTitleStyle },
      { v: "Item of work for supply & installation purpose only", s: columnTitleStyle },
      { v: "Material Amount", s: columnTitleStyle },
      { v: "Labour Amount", s: columnTitleStyle },
      { v: "Material Cost", s: columnTitleStyle },
      { v: "Labour Cost", s: columnTitleStyle },
    ],
    ...systemsRows,
  ];

  // Create a worksheet
  const ws = utils.aoa_to_sheet(data);

  // Add border to each cell
  Object.keys(ws).map((k) => {
    if (typeof ws[k] == "object") {
      ws[k].s = ws[k].s
        ? { ...ws[k].s, border: allBorder }
        : { border: allBorder };
    }
  });

  ws["!rows"] = [{ hpt: 20 }];

  ws["!cols"] = [
    { wch: 10 },
    { wch: 60 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
  ];

  const merge = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } }, // Header
    { s: { r: systemsRows.length, c: 0 }, e: { r: systemsRows.length, c: 1 } }, // Total
    { s: { r: 1 + systemsRows.length, c: 0 }, e: { r: 1 + systemsRows.length, c: 1 } }, // Grand Total
    { s: { r: 1 + systemsRows.length, c: 2 }, e: { r: 1 + systemsRows.length, c: 3 } }, // Grand Total
    { s: { r: 1 + systemsRows.length, c: 4 }, e: { r: 1 + systemsRows.length, c: 5 } }, // Grand Total
  ];
  ws["!merges"] = merge;

  return ws;
};

const downloadXLSX = (exportData) => {
  // Create a workbook and add the worksheet
  const wb = utils.book_new();

  // Abstract Sheet
  const abstactWs = getAbstractSheet({systems: exportData.systems, total: exportData.total})
  utils.book_append_sheet(wb, abstactWs, 'Abstract');

  const ws = getBuildingSheet({
    organization: exportData.organization,
    customer: exportData.customer,
    systems: exportData.systems,
    termsAndConditions: exportData.termsAndConditions, 
  });
  utils.book_append_sheet(wb, ws, 'Plant');

  // Convert the workbook to a buffer
  const buffer = write(wb, { bookType: "xlsx", type: "buffer" });

  // Convert the buffer to a Blob
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Create a download link
  const downloadLink = URL.createObjectURL(blob);

  // Trigger the download
  const link = document.createElement("a");
  link.href = downloadLink;
  link.download = `${'type'}_Sheet.xlsx`;
  link.click();

  // Clean up the object URL
  URL.revokeObjectURL(downloadLink);
};

export default downloadXLSX;
