import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
  Image,
  Font,
  pdf,
} from "@react-pdf/renderer";
import CustomTablePDF from "./table";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    paddingVertical: 16,
    paddingTop: '0.1cm',
    paddingBottom: '0.1cm',
  },
  letterheader: {
    height: "120px",
    padding: 0,
  },
  title: {
    alignItems: "center",
    fontFamily: "Times-Bold",
    justifyContent: "center",
    paddingHorizontal: 32,
  },
  body: {
    fontFamily: "Times-Bold",
    textAlign: "left",
    fontSize: 14,
    paddingHorizontal: 16,
  },
  header: {
    flexDirection: "row",
    paddingBottom: 16,
    fontSize: 12,
    fontFamily: "Times-Roman",
    paddingHorizontal: 16,
    alignItems: "flex-start",
  },
  headerSectionLeft: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "center",
    fontSize: 12
  },
  headerSectionRight: {
    flex: 1,
    alignItems: "flex-end",
    justifyContent: "center",
  },
  headerBlock: {
    borderStyle: "solid",
    borderColor: "#000",
    borderWidth: 1,
    padding: 8,
    margin: 16,
  },
  letterfooter: {
    position: "absolute",
    bottom: 15,
    left: 0,
    right: 0,
  },
});

const fields = [
  {
    title: "Item Image",
    custom: true,
    component: (item) => (
      <Image
        src={`${
          item.image.includes(".jpg") ||
          item.image.includes(".png") ||
          item.image.includes(".jpeg")
            ? item.image
            : "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
        }`}
        style={{ height: 64, width: 64 }}
      />
    ),
    width: "15%",
    style: {
      textAlign: "center",
    },
  },
  {
    title: "Item Name",
    custom: true,
    component: (item) => `${item.name}`,
    width: "15%",
  },
  {
    title: "HSN Code",
    custom: true,
    component: (item) => `${item.hsnCode}`,
    width: "8%",
    style: {
      textAlign: "center",
    },
  },
  {
    title: "Description",
    custom: true,
    component: (item) => `${item.description}`,
    width: "35%",
  },
  {
    title: "Amount (Rs.)",
    custom: true,
    component: (item) => `${item.amount}`,
    width: "10%",
    style: {
      textAlign: "center",
    },
  },
  {
    title: "Quantity",
    custom: true,
    component: (item) => `${item.quantity}`,
    width: "7%",
    style: {
      textAlign: "center",
    },
  },
  {
    title: "Total Amount (Rs.)",
    custom: true,
    component: (item) => `${item.totalAmount}`,
    width: "10%",
    style: {
      textAlign: "center",
    },
  },
];

let tableCol = {
  borderStyle: "solid",
  borderColor: "#000",
  borderBottomColor: "#000",
  borderWidth: 1,
  borderLeftWidth: 0,
  borderTopWidth: 0,
};

// Create Document Component
const Quotation = ({
  letterheader,
  letterfooter,
  date,
  customer,
  data,
  quoatationNo,
  subject,
  amount,
  discountAmount,
  cgstAmount,
  sgstAmount,
  totalAmount,
  totalAmountWords,
  termsAndConditions,
}) => (
  <Page size="A4" style={styles.page} orientation="landscape">
    ?{<View style={styles.letterheader}>{<Image src={letterheader} />}</View>}
    <View style={styles.headerBlock}>
      <View
        style={{
          ...styles.header,
          paddingTop: letterheader ? 16 : 0,
          paddingBottom: 5,
        }}
      >
        <View style={styles.headerSectionLeft}>
          <Text style={{ fontFamily: "Times-Bold" }}>To</Text>
        </View>
        <View style={styles.headerSectionRight}>
          <Text style={{ fontFamily: "Times-Bold", fontSize: 12 }}>
            Quotation No:{" "}
            <Text style={{ fontFamily: "Times-Roman" }}>{quoatationNo}</Text>
          </Text>
        </View>
      </View>
      <View style={{ ...styles.header, paddingBottom: 16 }}>
        <View style={styles.headerSectionLeft}>
          <Text style={{ fontFamily: "Times-Bold", fontSize: 14 }}>
            {customer.name}
          </Text>
          <Text style={{ fontFamily: "Times-Roman", fontSize: 12 }}>
            {customer.address}
          </Text>
        </View>
        <View style={styles.headerSectionRight}>
          <Text
            style={{ fontFamily: "Times-Bold", fontSize: 12 }}
          >{`Date: ${date}`}</Text>
        </View>
      </View>

      <View style={styles.body}>
        <Text
          style={{ fontFamily: "Times-Bold", fontSize: 14, paddingBottom: 16 }}
        >
          Subject:{" "}
          <Text style={{ fontFamily: "Times-Roman" }}>{`${subject}`}</Text>
        </Text>
        <Text style={{ fontFamily: "Times-Roman", fontSize: 12 }}>
          Dear Sir/Madam,
        </Text>
        <Text style={{ fontFamily: "Times-Roman", fontSize: 12 }}>
          We thank you for the keen interest shown by you in FIRE FIGHT SAFETY
          SOLUTIONS. As desired we are pleased to quote you our best rates and
          look forward to your valuable order.
        </Text>
      </View>
    </View>
    <CustomTablePDF fields={fields} data={data} quotation />
    <View
      style={{
        ...styles.headerBlock,
        padding: 0,
        marginTop: 10,
        marginHorizontal: 16,
        flexDirection: "row",
        borderBottom: 0,
      }}
      wrap={false}
    >
      <View
        style={[
          tableCol,
          { width: "66%", paddingHorizontal: 24, paddingTop: 8 },
        ]}
      >
        <Text
          style={{ fontSize: 13, fontFamily: "Times-Bold", paddingBottom: 16 }}
        >
          Amount in words:{" "}
          <Text style={{ fontFamily: "Times-Roman" }}>{totalAmountWords}</Text>
        </Text>
        <Text
          style={{ fontSize: 13, fontFamily: "Times-Bold", paddingBottom: 16 }}
        >
          Our GST Number:{" "}
          <Text style={{ fontFamily: "Times-Roman" }}>27AACFF8864H1Z3</Text>
        </Text>
      </View>

      <View
        style={[
          tableCol,
          {
            width: "21%",
            paddingHorizontal: 8,
            fontSize: 13,
            fontFamily: "Times-Bold",
          },
        ]}
      >
        <Text style={{ paddingBottom: 4 }}>Amount</Text>
        <Text style={{ paddingBottom: 4 }}>Discount</Text>
        <Text style={{ paddingBottom: 4 }}>SGST Amount</Text>
        <Text style={{ paddingBottom: 4 }}>CGST Amount</Text>
        <Text style={{ paddingBottom: 4 }}>Total Amount</Text>
      </View>

      <View
        style={[
          tableCol,
          {
            width: "13%",
            paddingHorizontal: 8,
            fontSize: 13,
            fontFamily: "Times-Bold",
            textAlign: "right",
            borderRight: 0,
          },
        ]}
      >
        <Text style={{ paddingBottom: 4 }}>{amount}</Text>
        <Text style={{ paddingBottom: 4 }}>{discountAmount}</Text>
        <Text style={{ paddingBottom: 4 }}>{sgstAmount}</Text>
        <Text style={{ paddingBottom: 4 }}>{cgstAmount}</Text>
        <Text style={{ paddingBottom: 4 }}>{totalAmount}</Text>
      </View>
    </View>
    <View
      style={{
        ...styles.headerBlock,
        padding: 0,
        paddingVertical: 8,
        marginHorizontal: 16,
        marginTop: 16,
      }}
      wrap={false}
    >
      <View style={{ ...styles.header, fontSize: 12, paddingBottom: 0 }}>
        <View style={styles.headerSectionLeft}>
          <Text style={{ fontFamily: "Times-Bold", paddingBottom: 8 }}>
            Commercials :-
          </Text>
          <Text style={{ fontFamily: "Times-Roman" }}>
            {termsAndConditions}
          </Text>
        </View>
      </View>
    </View>
    <View
      style={{
        ...styles.headerBlock,
        padding: 0,
        paddingVertical: 8,
        marginHorizontal: 16,
        marginTop: 0,
      }}
      wrap={false}
    >
      <View
        style={{
          ...styles.header,
          paddingBottom: 0,
          fontSize: 12,
          fontFamily: "Times-Bold",
        }}
      >
        <View style={styles.headerSectionLeft}>
          <Text style={{ paddingBottom: 4 }}>Thanking You</Text>
          <Text style={{ paddingBottom: 4 }}>Regards</Text>
          <Text>For Fire Fight Safety Solutions</Text>
          <Text>Govind Aigole</Text>
          <Text>9970548822</Text>
        </View>
      </View>
    </View>
    {letterfooter && (
      <View style={styles.letterfooter} wrap={false}>
        {<Image src={letterfooter} />}
      </View>
    )}
  </Page>
);

export default Quotation;
