import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  Table,
  Dropdown,
  Button,
  Modal,
  Typography,
  Input,
  Form,
  Tag,
  message,
  Descriptions,
} from "antd";
import APICall from "../../services/apiservices";
import { Link, useLocation, useParams, useRevalidator } from "react-router-dom";
import moment from "moment";
import downloadXLSX from "../../employee/features/survey/export";
import downloadFireExtinguisherXLSX from "../../employee/features/survey/fireExtExport";
import queryString from "query-string";
import dayjs from "dayjs";

const { TextArea } = Input;
const userData = JSON.parse(localStorage.getItem("userData"));

const ActiveAMC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [amcDetails, setAMCDetails] = useState([]);

  const getActiveAMC = async (searchTerm, page, limit) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    try {
      if (searchTerm === undefined) searchTerm = "";
      const response = await APICall(
        `/customer/${userData.customer._id}/dashboard`,
        {},
        1
      );
      setAMCDetails(response.data.data.activeAMC);
      setIsLoading(false);
    } catch (error) {
      message.error("Failed to fetch visit details");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getActiveAMC();
  }, []);

  const requirementsArray = {
    adaptor: {
      name: "Adaptor",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hoseBox: {
      name: "Hose Box",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    branchPipe: {
      name: "Branch Pipe",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hosePipe: {
      name: "Hose Pipe",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hydrantValve: {
      name: "Hydrant Valve",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hoseReel: {
      name: "Hose Reel",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    fireExtinguishers: {
      name: "Fire Extinguisher",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hoseBoxGlass: {
      name: "Hose Box Glass",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    keyGlass: {
      name: "Key Glass",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hydrantValveWheel: {
      name: "Hydrant Valve Wheel",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hoseReelBallValve: {
      name: "Hose Reel Ball Valve",
      existing: 0,
      good: 0,
      requirement: 0,
    },
  };

  const exportVisit = async (visitId) => {
    try {
      const res = await APICall(`/visits/${visitId}/conducted`, {}, 1);

      if (!res?.data?.visit?.isFireExtinguisher) {
        const finalRequirements = JSON.parse(JSON.stringify(requirementsArray));
        const exportData = {
          organization: {
            name: "Fire Fight Safety Solutions",
            address:
              "Sr. No.85, Office No.7A/204, Kalpataru Estate, Jawalkar Nagar,Pimple Gurav, Pune - 411061",
            mobile: "9970548822",
            email: "info@ffss.in",
          },
          customer: {
            name: res?.data?.visit?.customer?.user?.name,
          },
          employee: {
            name: res?.data?.visit?.employee?.user?.name,
          },
          date: moment(res?.data?.visit?.visitDate).format("DD/MM/YYYY"),
          buildings: res.data.visitBuildings.map((sb) => {
            const areas = sb?.newFloors.map((nf, index) => ({
              area: nf?.floor.name,
              srNo: index + 1,
              equipments: nf?.equipments ?? {
                adaptor: "NA",
                hoseBox: "NA",
                branchPipe: "NA",
                hosePipe: "NA",
                hydrantValve: "NA",
                hoseReel: "NA",
                fireExtinguishers: "NA",
                hoseBoxGlass: "NA",
                keyGlass: "NA",
                hydrantValveWheel: "NA",
                hoseReelBallValve: "NA",
              },
            }));
            const requirements = JSON.parse(JSON.stringify(requirementsArray));
            areas.map((area) => {
              Object.keys(area.equipments).map((k) => {
                if (area.equipments[k] == "OK") {
                  requirements[k].existing = requirements[k].existing + 1;
                  requirements[k].good = requirements[k].good + 1;
                  finalRequirements[k].existing =
                    finalRequirements[k].existing + 1;
                  finalRequirements[k].good = finalRequirements[k].good + 1;
                } else if (area.equipments[k] == "Not OK") {
                  requirements[k].existing = requirements[k].existing + 1;
                  requirements[k].requirement = requirements[k].requirement + 1;
                  finalRequirements[k].existing =
                    finalRequirements[k].existing + 1;
                  finalRequirements[k].requirement =
                    finalRequirements[k].requirement + 1;
                }
              });
            });
            return {
              building: sb?.building?.name,
              notes: sb?.information?.notes ?? "",
              inCharge: sb?.information?.inCharge ?? "",
              areas,
              requirements,
            };
          }),
        };
        exportData.requirements = finalRequirements;
        downloadXLSX(exportData, "Visit");
      } else {
        console.log(res);
        const exportData = {
          organization: {
            name: "Fire Fight Safety Solutions",
            address:
              "Sr. No.85, Office No.7A/204, Kalpataru Estate, Jawalkar Nagar,Pimple Gurav, Pune - 411061",
            mobile: "9970548822",
            email: "info@ffss.in",
          },
          customer: {
            name: res?.data?.visit?.customer?.user?.name,
          },
          employee: {
            name: res?.data?.visit?.employee?.user?.name,
          },
          date: moment(res?.data?.visit?.visitDate).format("DD/MM/YYYY"),

          buildings: res.data.visitBuildings.map((sb) => {
            const areas = sb?.newFloors.map((floor, index) => ({
              srNo: index + 1,
              area: floor?.floor.name,
              fireExtinguisherInfo: {
                type: floor?.fireExtinguisherInfo?.type ?? "NA",
                capacity: floor?.fireExtinguisherInfo?.capacity ?? "NA",
                pressure: floor?.fireExtinguisherInfo?.pressure ?? "NA",
                weight: floor?.fireExtinguisherInfo?.weight ?? "NA",
                serialNo: floor?.fireExtinguisherInfo?.serialNo ?? "NA",
                installationRefillingDate: floor?.fireExtinguisherInfo
                  ?.installationRefillingDate
                  ? moment(
                      floor?.fireExtinguisherInfo?.installationRefillingDate
                    ).format("DD/MM/YYYY")
                  : "NA",
                refillingDueDate: floor?.fireExtinguisherInfo?.refillingDueDate
                  ? moment(
                      floor?.fireExtinguisherInfo?.refillingDueDate
                    ).format("DD/MM/YYYY")
                  : "NA",
                freeAccess: floor?.fireExtinguisherInfo?.freeAccess ?? "NA",
                identificationTag:
                  floor?.fireExtinguisherInfo?.identificationTag ?? "NA",
                forABCPressureGuage:
                  floor?.fireExtinguisherInfo?.forABCPressureGuage ?? "NA",
                hoseTightFitting:
                  floor?.fireExtinguisherInfo?.hoseTightFitting ?? "NA",
                isSafetyPinGood:
                  floor?.fireExtinguisherInfo?.isSafetyPinGood ?? "NA",
                forCO2HoseCondition:
                  floor?.fireExtinguisherInfo?.forCO2HoseCondition ?? "NA",
                forCO2HornHandle:
                  floor?.fireExtinguisherInfo?.forCO2HornHandle ?? "NA",
                isFEOK: floor?.fireExtinguisherInfo?.isFEOK ?? "NA",
                remark: floor?.fireExtinguisherInfo?.remark ?? "NA",
              },
            }));
            return {
              building: sb?.building?.name,
              notes: sb?.information?.notes ?? "",
              inCharge: sb?.information?.inCharge ?? "",
              areas,
            };
          }),
        };

        downloadFireExtinguisherXLSX(exportData, "Visit");
      }
    } catch (error) {
      message.error(error);
    }
  };

  const columns = [
    {
      title: "Sr. No.",
      key: "serialNumber",
      render: (_, record, index) => {
        return index + 1;
      },
    },

    {
      title: "Scheduled Date",
      key: "scheduledDate",
      render: (record) => {
        return moment(record?.scheduledDate).format("DD/MM/YY");
      },
      // align: "center",
    },
    {
      title: "Status",
      key: "status",
      render: (record) => {
        return (
          <Tag color={record.isConducted ? "blue" : "red"}>
            {record.isConducted ? "Conducted" : "Pending"}
          </Tag>
        );
      },
    },

    {
      title: "Actions",
      key: "actions",
      render: (_, record) =>
        record.isConducted ? (
          <Button
            type="link"
            onClick={() => {
              exportVisit(record._id);
            }}
          >
            Export Visit
          </Button>
        ) : (
          "—"
        ),
      align: "center",
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Card
          title="Active AMC"
          loading={isLoading}
          extra={
            <Link to={`/amcs/${amcDetails._id}/export`}>
              <Button type="primary">Export</Button>
            </Link>
          }
        >
          <Descriptions bordered layout="vertical" style={{ width: "100%" }}>
            <Descriptions.Item label="AMC Number">
              {"AMC" + amcDetails?.amcNo?.toString().padStart(4, "0")}
            </Descriptions.Item>
            <Descriptions.Item label="Type">
              <Tag color="blue">{amcDetails?.type}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Duration(Month)">
              {amcDetails?.duration + " Months"}
            </Descriptions.Item>
            <Descriptions.Item label="Toatal Visits">
              {amcDetails?.visitsCount}
            </Descriptions.Item>
            <Descriptions.Item label="Contract Date">
              {dayjs(amcDetails?.contractDate).format("DD/MM/YYYY")}
            </Descriptions.Item>
            <Descriptions.Item label="Expiry Date">
              {dayjs(amcDetails?.expiryDate).format("DD/MM/YYYY")}
            </Descriptions.Item>
            <Descriptions.Item label="Systems">
              {amcDetails?.systemTypes?.map((system) => {
                return system.systemTypeId.name + ", ";
              })}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Col>

      <Col span={24} style={{ marginTop: "10px" }}>
        <Card title="Visits">
          <Table
            columns={columns}
            dataSource={amcDetails?.visits}
            size="small"
            loading={isLoading}
            scroll={{ x: "1000px" }}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default ActiveAMC;
