import React from "react";
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
  },
  letterheader: {
    height: "150px",
    paddingHorizontal: 16,
  },
  title: {
    alignItems: "center",
    fontFamily: "Times-Bold",
    justifyContent: "center",
    paddingHorizontal: 32,
  },
  body: {
    fontFamily: "Times-Bold",
    textAlign: "left",
    fontSize: 14,
    paddingHorizontal: 32,
  },
  header: {
    flexDirection: "row",
    paddingBottom: 48,
    fontSize: 10,
    fontFamily: "Times-Roman",
    paddingHorizontal: 32,
  },
  headerSectionLeft: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "center",
  },
  headerSectionRight: {
    flex: 1,
    alignItems: "flex-end",
    justifyContent: "center",
  },
  letterfooter: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
});

function getYearString() {
  const currentYear = new Date().getFullYear();
  const yearString = `${currentYear.toString().slice(-2)}-${(currentYear + 1)
    .toString()
    .slice(-2)}`;
  return yearString;
}
// Create Document Component
const TrainingCertificate = ({
  letterheader,
  letterfooter,
  headingDate,
  customer,
  contactPerson,
  items,
  reportCode,
}) => (
  <Page size="A4" style={styles.page}>
    ?
    {letterheader && (
      <View style={styles.letterheader}>{<Image src={letterheader} />}</View>
    )}
    <View style={{ ...styles.header, paddingTop: letterheader ? 16 : 64 }}>
      <View style={styles.headerSectionRight}>
        <Text
          style={{ fontFamily: "Times-Bold", paddingBottom: 5, fontSize: 12 }}
        >
          Date: <Text style={{ fontFamily: "Times-Roman" }}>{headingDate}</Text>
        </Text>
        <Text
          style={{ fontFamily: "Times-Bold", paddingBottom: 5, fontSize: 12 }}
        >
          Report Code:{" "}
          <Text style={{ fontFamily: "Times-Roman" }}>
            FFSS/{getYearString()}/{reportCode}
          </Text>
        </Text>
      </View>
    </View>
    <View style={styles.title}>
      <Text
        style={{
          ...styles.title,
          fontSize: 22,
          paddingBottom: 32,
          textDecoration: "underline",
        }}
      >
        TO WHOM SO EVER IT MAY CONCERN
      </Text>
    </View>
    <View style={styles.body} wrap={false}>
      <Text
        style={{
          paddingBottom: 12,
          fontFamily: "Times-Roman",
          textAlign: "justify",
        }}
      >
        This is to certify that a{" "}
        <Text style={{ fontFamily: "Times-Bold" }}>
          "Fire Safety Basic Training"
        </Text>{" "}
        has been given to{" "}
        <Text style={{ fontFamily: "Times-Bold" }}>
          {customer.name}, {customer.address}
        </Text>
        .
      </Text>
      <Text
        style={{
          paddingBottom: 12,
          fontFamily: "Times-Roman",
          textAlign: "justify",
        }}
      >
        The Training Program has been attended by all the staff, workers &
        security guards of{" "}
        <Text style={{ fontFamily: "Times-Bold" }}>
          {customer.name}, {customer.address}
        </Text>
        , in presence of{" "}
        <Text style={{ fontFamily: "Times-Bold" }}>{contactPerson.name}</Text>.
      </Text>
      <Text
        style={{
          paddingBottom: 12,
          fontFamily: "Times-Roman",
          textAlign: "justify",
        }}
      >
        As per our Fire Audit Survey, We found that {items.name} in these
        premises are sufficient and well inspected.
      </Text>
      <Text
        style={{
          paddingBottom: 50,
          fontFamily: "Times-Roman",
          textAlign: "justify",
        }}
      >
        We wish hazards free and safe future of organization.
      </Text>
      <Text style={{ textAlign: "left", fontSize: 12, paddingBottom:10 }}>
        From Fire Fight Safety Solutions
      </Text>
      <Text style={{ fontSize: 10, paddingBottom: 4 }}>
        Office No.204/7A, Kalpataru Estate, 
      </Text>
      <Text style={{ fontSize: 10, paddingBottom: 80 }}>
      Phase-3, Jawalkar Nagar, Pimple
      Gurav, Pune
      </Text>
     
      <Text style={{ textAlign: "right", fontSize: 12 }}>
        Authorised Signature
      </Text>
    </View>
    {letterfooter && (
      <View style={styles.letterfooter}>{<Image src={letterfooter} />}</View>
    )}
  </Page>
);

export default TrainingCertificate;
