import React, { useEffect, useState } from "react";
import { Col, Row, Form, DatePicker, Input, Space, Checkbox, Button, Card, message, Modal,} from "antd";
import APICall from "../../../services/apiservices";
import DebounceSelect from "../../../components/debounceSelect/DebounceSelect";
import moment from "moment";
// const { RangePicker } = DatePicker;

import TrainingCertificate from "../../../pdfs/trainingCertificate";
import { PDFViewer, Document } from "@react-pdf/renderer";
import { PDFDocument, handleDownloadPDF } from "../../../pdfs/pdfService";
import { useParams, useNavigate, Link } from "react-router-dom";
import dayjs from "dayjs";

const { confirm } = Modal;
const TrainingCertificateForm = ({ view, edit }) => {
  const [value, setValue] = useState(undefined);
  const [reportForm] = Form.useForm();
  const [reportData, setReportData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [reportId, setReportId] = useState();
  const userData = JSON.parse(localStorage.getItem("userData"));

  const params = useParams();
  const navigate = useNavigate();

  const fetchCustomerList = async (searchValue) => {
    const res = await APICall(
      `/customer?page=1&limit=30&query=${searchValue}`,
      {},
      1
    );
    return res.data.documents.map((customer) => ({
      label: customer.user.name,
      value: customer._id,
    }));
  };

  function disabledDate(current) {
    const now = moment();
    return current && current < now.startOf("day");
  }

  const generateReport = async (formData) => {
    setIsLoading(true);
    try {
      const dataTosend = {
        organizationId: "649bff0f87a35d7bececa3d8",
        ...formData,
        type: "Training Certificate",
        extraInformation: {
          inPresenceOf: formData?.presence,
          items: formData?.items,
        },
      };
      delete dataTosend.items;
      let res;
      if (!view) {
        res = await APICall("/report", dataTosend, 2);
        setReportId(res?.data?.data?._id);
      }
      const res2 = await APICall(
        `/report/${view ? params.id : res.data.data._id}`,
        {},
        1
      );
      console.log("from page traing"+res2)
      // const res3 = await APICall(`/organization/${res2.data.organizationId}`, {}, 1);
      let letterhead = "";
      let letterfooter = "";
      if (view) {
        letterhead = res2?.data?.letterhead?.header;
        letterfooter = res2?.data?.letterhead?.footer;
      } else if (formData?.includeLetterheadHeader) {
        letterhead = res2?.data?.letterhead?.header;
        letterfooter = res2?.data?.letterhead?.footer;
      }
      setReportData({
        letterheader: letterhead,
        letterfooter: letterfooter,
        headingDate: moment(res2?.data?.date).format("DD/MM/YYYY"),
        customer: {
          name: res2?.data?.customer?.user?.name,
          address: res2?.data?.customer?.user?.address,
        },
        contactPerson: {
          name: res2?.data?.extraInformation?.inPresenceOf, // res2?.data?.customer?.contactPersons[0]?.name || 'Mr. Nilesh Mane',
        },
        items: {
          name: res2?.data?.extraInformation?.items, // res2?.data?.customer?.contactPersons[0]?.name || 'Mr. Nilesh Mane',
        },
        reportCode: res2?.data?.reportCode,
      });
    } catch (error) {
      setIsLoading(false);
    }
    setIsLoading(false);
  };
  const updateReport = async (formData) => {
    setIsLoading(true);
    const customerId = formData.customerId.value ?? formData.customerId;
    delete formData.customerId;
    try {
      const dataTosend = {
        organizationId: "649bff0f87a35d7bececa3d8",
        ...formData,
        customerId: customerId,
        type: "Training Certificate",
        extraInformation: {
          inPresenceOf: formData?.presence,
          items: formData?.items,
        },
      };
      delete dataTosend.items;

      const res = await APICall(`/report/${params.id}`, dataTosend, 3);
      message.success("Report updated successfully!");
      const res2 = await APICall(`/report/${params.id}`, {}, 1);

      let letterhead = "";
      let letterfooter = "";
      if (view) {
        letterhead = res2?.data?.letterhead?.header;
        letterfooter = res2?.data?.letterhead?.footer;
      } else if (formData?.includeLetterheadHeader) {
        letterhead = res2?.data?.letterhead?.header;
        letterfooter = res2?.data?.letterhead?.footer;
      }
      setReportData({
        letterheader: letterhead,
        letterfooter: letterfooter,
        headingDate: moment(res2?.data?.date).format("DD/MM/YYYY"),
        customer: {
          name: res2?.data?.customer?.user?.name,
          address: res2?.data?.customer?.user?.address,
        },
        contactPerson: {
          name: res2?.data?.extraInformation?.inPresenceOf, // res2?.data?.customer?.contactPersons[0]?.name || 'Mr. Nilesh Mane',
        },
        items: {
          name: res2?.data?.extraInformation?.items, // res2?.data?.customer?.contactPersons[0]?.name || 'Mr. Nilesh Mane',
        },
      });
    } catch (error) {
      setIsLoading(false);
      message.error(error.message);
    }
  };

  const handleDelete = async () => {
    confirm({
      title: "Confirm Delete",
      content: `Are you sure you want to delete report?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        // Delete logic goes here
        const res = await APICall(`/report/${reportId}`, {}, 4);
        // getQuotations(0, 10);
        message.success("Report deleted successfully!");
        navigate("/reports/trainingCertificate");
      },
    });
  };

  const getReportData = async () => {
    setIsLoading(true);
    try {
      const res = await APICall(`/report/${params.id}`, {}, 1);
      console.log(res.data);
      reportForm.setFieldsValue({
        customerId: {
          label: res?.data?.customer?.user?.name,
          value: res?.data?.customerId,
        },
        date: dayjs(res?.data?.date),
        presence: res?.data?.extraInformation?.inPresenceOf,
        items: res?.data?.extraInformation?.items,
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      message.error("Failed to fetch data");
    }
  };

  useEffect(() => {
    if (view) {
      generateReport();
      setReportId(params.id);
    }
    if (edit) {
      getReportData();
    }
  }, [edit, view]);

  return (
    <Row gutter={20}>
      <Col span={24}>
        <Card title="Training Certificate" loading={view ? isLoading : false}>
          {!view && (
            <Form
              form={reportForm}
              layout="vertical"
              onFinish={edit ? updateReport : generateReport}
              initialValues={{ place: "Pune" }}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name="customerId"
                    label="Select Customer"
                    rules={[
                      {
                        required: true,
                        message: "Please select the customer",
                      },
                    ]}
                  >
                    <DebounceSelect
                      value={value}
                      placeholder="Select a user"
                      fetchOptions={fetchCustomerList}
                      onChange={(newValue) => {
                        setValue(newValue);
                      }}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="items"
                    label="Audited Items"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the item's name",
                      },
                    ]}
                  >
                    <Input placeholder="e.g. Fire Extinguisher," type="text" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="date"
                    label="Date"
                    rules={[
                      {
                        required: true,
                        message: "Please select date",
                      },
                    ]}
                  >
                    <DatePicker format={'DD-MM-YYYY'} style={{ width: "100%" }} />
                  </Form.Item>
                  <Space>
                    <Form.Item
                      name="includeLetterheadHeader"
                      valuePropName="checked"
                      style={{ marginTop: "30px" }}
                    >
                      <Checkbox>Print With Letterhead</Checkbox>
                    </Form.Item>
                    <Form.Item style={{ marginTop: "30px" }}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                        onClick={() => setReportData(undefined)}
                      >
                        {edit ? "Update" : "Create"}
                      </Button>
                    </Form.Item>
                  </Space>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="presence"
                    label="In Presence of"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the name",
                      },
                    ]}
                  >
                    <Input placeholder="e.g. Ramesh Kolhe" type="text" />
                  </Form.Item>
                </Col>
                <Col span={6}></Col>
              </Row>
            </Form>
          )}

          {reportData && (
            <>
              <Space style={{ float: "right", marginBottom: "20px" }}>
                <Button
                  type="primary"
                  onClick={() => {
                    setReportData(undefined);
                    navigate(-1);
                  }}
                  ghost
                >
                  Close
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    handleDownloadPDF({
                      pages: [<TrainingCertificate {...reportData} />],
                      fileName: `Training Certificate_${reportData?.customer?.name}_${reportData?.headingDate}`,
                    });
                  }}
                  ghost
                >
                  Download
                </Button>
                {userData?.employee?.accessType ===
                  `${"Super Admin" || "Admin"}` && (
                  <>
                    <Button type="primary" onClick={handleDelete}>
                      Delete
                    </Button>
                    {!edit && (
                      <Link
                        to={`/edit/trainingCertificate/${
                          view ? params.id : reportId
                        }`}
                      >
                        <Button type="primary">Edit</Button>
                      </Link>
                    )}
                  </>
                )}
              </Space>
              <PDFViewer width={"100%"} height={1200}>
                <PDFDocument
                  pages={[<TrainingCertificate {...reportData} />]}
                />
              </PDFViewer>
            </>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default TrainingCertificateForm;
