import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Row,
  Table,
  message,
  Space,
  Button,
  DatePicker,
  Input,
} from "antd";
import APICall from "../../../services/apiservices";
import dayjs from "dayjs";
import * as XLSX from "xlsx";

const Salary = () => {
  const [salaryData, setSalaryData] = useState();
  const [loading, setLoading] = useState(true);
  const [month, setMonth] = useState(dayjs().toISOString());
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const getSalaryData = async () => {
    setLoading(true);
    try {
      const response = await APICall(
        `/employee/attendance?startDate=${dayjs(month).startOf(
          "month"
        )}&endDate=${dayjs(month).endOf("month").toISOString()}`,
        {},
        1
      );
      setSalaryData(response.data);
      setLoading(false);
      setCurrentPage(1);
    } catch (error) {
      message.error("Failed to fetch data");
      setLoading(false);
    }
  };

  //Export tale to excel
  const exportToExcel = (apiData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const formattedData = apiData.map((item) => {
      return {
        "Employee Name": item?.userId?.name,
        "Basic Salary": item?.salary,
        "Total Days":
          dayjs(month)
            .endOf("month")
            .diff(dayjs(month).startOf("month"), "day") + 1,
        "Present Days": item?.finalAttendance?.filter((item) => item.isApproved)
          .length,
        "Per Day Salary": (
          item?.salary /
          (dayjs(month)
            .endOf("month")
            .diff(dayjs(month).startOf("month"), "day") +
            1)
        )?.toFixed(2),
        "Total Salary as Per Attendance": (
          (item?.salary /
            (dayjs(month)
              .endOf("month")
              .diff(dayjs(month).startOf("month"), "day") +
              1)) *
          item?.finalAttendance?.filter((item) => item.isApproved).length
        ).toFixed(2),
        "Total Overtime Hrs":
          item?.finalAttendance?.filter((item) => item.isApproved).length > 0
            ? item?.finalAttendance
                ?.filter((item) => item.isApproved)
                .map((item) => item?.otHours)
                .reduce((a, b) => a + b)
            : 0,
        "Over Time Rate Per Hr": item?.otRatePerHr,
        "Total Overtime Payout":
          item?.finalAttendance?.filter((item) => item.isApproved).length > 0
            ? item?.finalAttendance
                ?.filter((item) => item.isApproved)
                .map((item) => item?.otHours)
                .reduce((a, b) => a + b) * item?.otRatePerHr
            : 0,
        "Total Salary": (
          (item?.salary /
            (dayjs(month)
              .endOf("month")
              .diff(dayjs(month).startOf("month"), "day") +
              1)) *
            item?.finalAttendance?.filter((item) => item.isApproved).length +
          (item?.finalAttendance?.filter((item) => item.isApproved).length > 0
            ? item?.finalAttendance
                ?.filter((item) => item.isApproved)
                .map((item) => item?.otHours)
                .reduce((a, b) => a + b) * item?.otRatePerHr
            : 0)
        ).toFixed(2),
        "Round Off Amount":
          // Round up to nearest 1 rupee
          Math.ceil(
            (item?.salary /
              (dayjs(month)
                .endOf("month")
                .diff(dayjs(month).startOf("month"), "day") +
                1)) *
              item?.finalAttendance?.filter((item) => item.isApproved).length +
              (item?.finalAttendance?.filter((item) => item.isApproved).length >
              0
                ? item?.finalAttendance
                    ?.filter((item) => item.isApproved)
                    .map((item) => item?.otHours)
                    .reduce((a, b) => a + b) * item?.otRatePerHr
                : 0)
          ),
        "Account No.": item?.bankAccounts[0]?.accountNumber,
      };
    });
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    const anchor = document.createElement("a");
    anchor.href = URL.createObjectURL(data);
    anchor.download = fileName + fileExtension;
    anchor.click();
  };

  useEffect(() => {
    getSalaryData();
  }, []);

  const columns = [
    {
      title: "Sr. No.",
      key: "srNo",

      width: 80,
      fixed: "left",
      render: (_, record, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: "Employee Name",
      key: "name",
      fixed: "left",
      render: (_, record) => record?.userId?.name,
      width: 150,
    },
    {
      title: "Basic Salary",
      key: "basicSalary",
      width: 150,
      render: (_, record) => record?.salary,
    },
    {
      title: "Total Days",
      key: "totalDays",
      render: (_, record) =>
        // total days in month
        dayjs(month).endOf("month").diff(dayjs(month).startOf("month"), "day") +
        1,
    },
    {
      title: "Present Days",
      key: "presentDays",
      render: (_, record) =>
        record?.finalAttendance?.filter((item) => item.isApproved).length,
    },
    {
      title: "Per Day Salary",
      key: "perDaySalary",
      render: (_, record) =>
        // total salary / total days in add precision to 2 decimal places

        (
          record?.salary /
          (dayjs(month)
            .endOf("month")
            .diff(dayjs(month).startOf("month"), "day") +
            1)
        )?.toFixed(2),
    },
    {
      title: "Total Salary as Per Attendance",
      key: "totalSalaryAsPerAttendance",
      render: (_, record) =>
        (
          (record?.salary /
            (dayjs(month)
              .endOf("month")
              .diff(dayjs(month).startOf("month"), "day") +
              1)) *
          record?.finalAttendance?.filter((item) => item.isApproved).length
        ).toFixed(2),
    },
    {
      title: "Total Overtime Hrs",
      key: "totalOvertimeHrs",
      render: (_, record) =>
        record?.finalAttendance?.filter((item) => item.isApproved).length > 0
          ? record?.finalAttendance
              ?.filter((item) => item.isApproved)
              .map((item) => item?.otHours)
              .reduce((a, b) => a + b)
          : 0,
    },
    {
      title: "Over Time Rate Per Hr",
      key: "overTimeRatePerHr",
      render: (_, record) => record?.otRatePerHr,
    },
    {
      title: "Total Overtime Payout",
      key: "totalOvertimePayout",
      render: (_, record) =>
        record?.finalAttendance?.filter((item) => item.isApproved).length > 0
          ? record?.finalAttendance
              ?.filter((item) => item.isApproved)
              .map((item) => item?.otHours)
              .reduce((a, b) => a + b) * record?.otRatePerHr
          : 0,
    },
    {
      title: "Total Salary",
      key: "totalSalary",
      render: (_, record) =>
        (
          (record?.salary /
            (dayjs(month)
              .endOf("month")
              .diff(dayjs(month).startOf("month"), "day") +
              1)) *
            record?.finalAttendance?.filter((item) => item.isApproved).length +
          (record?.finalAttendance?.filter((item) => item.isApproved).length > 0
            ? record?.finalAttendance
                ?.filter((item) => item.isApproved)
                .map((item) => item?.otHours)
                .reduce((a, b) => a + b) * record?.otRatePerHr
            : 0)
        ).toFixed(2),
    },
    {
      title: "Round Off Amount",
      key: "roundOffAmount",
      render: (_, record) =>
        // Round up to nearest 1 rupee
        Math.ceil(
          (record?.salary /
            (dayjs(month)
              .endOf("month")
              .diff(dayjs(month).startOf("month"), "day") +
              1)) *
            record?.finalAttendance?.filter((item) => item.isApproved).length +
            (record?.finalAttendance?.filter((item) => item.isApproved).length >
            0
              ? record?.finalAttendance
                  ?.filter((item) => item.isApproved)
                  .map((item) => item?.otHours)
                  .reduce((a, b) => a + b) * record?.otRatePerHr
              : 0)
        ),
    },

    // {
    //   title: "Advance",
    //   key: "advanceDeductionAmount",
    //   render: (_, record) => (
    //     <Input type="number" min={0} onChange={(val) => val} />
    //   ),
    //   editable: true,
    //   width: 200,
    //   responsive: ["md"],
    // },
    // {
    //   title: "Salary After Advance Deduction",
    //   key: "totalSalaryAfterAdvanceDeduction",
    //   render: (_, record) =>
    //     Math.ceil(
    //       (record?.salary /
    //         (dayjs(month)
    //           .endOf("month")
    //           .diff(dayjs(month).startOf("month"), "day") +
    //           1)) *
    //         record?.finalAttendance?.filter((item) => item.isApproved).length +
    //         (record?.finalAttendance?.filter((item) => item.isApproved).length >
    //         0
    //           ? record?.finalAttendance
    //               ?.filter((item) => item.isApproved)
    //               .map((item) => item?.otHours)
    //               .reduce((a, b) => a + b) * record?.otRatePerHr
    //           : 0) -
    //         0
    //     ),
    // },

    {
      title: "Account No.",
      key: "accountNo",
      responsive: ["md"],
      render: (_, record) => record?.bankAccounts[0]?.accountNumber,
    },
    // {
    //   title: "Balance Leave",
    //   dataIndex: "balanceLeave",
    //   key: "balanceLeave",
    //   responsive: ["md"],
    // },
  ];

  return (
    <Row>
      <Col>
        <Card
          title="Employee Salary"
          extra={
            <Space>
              <DatePicker
                format={'DD-MM-YYYY'}
                picker="month"
                onChange={(value) => {
                  setMonth(value?.toISOString());
                  setSalaryData([]);
                }}
              />
              <Button
                type="primary"
                // icon={<LuPlus style={{ paddingTop: "2px" }} />}
                onClick={getSalaryData}
              >
                Load
              </Button>
              <Button
                type="primary"
                onClick={() =>
                  exportToExcel(
                    salaryData,
                    `Employee Salary ${dayjs(month).format("MMMM YYYY")}`
                  )
                }
              >
                Export
              </Button>
            </Space>
          }
        >
          <Table
            columns={columns}
            dataSource={salaryData}
            pagination={{
              defaultPageSize: 10,
              current: currentPage,
              onChange: (page) => {
                setCurrentPage(page);
              },
            }}
            rowKey={(record) => record.id}
            loading={loading}
            scroll={{ x: "2000px" }}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default Salary;
