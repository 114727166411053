import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Table,
  Dropdown,
  Row,
  Space,
  DatePicker,
  Badge,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "antd";
import APICall from "../../../services/apiservices";
import moment from "moment";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
const { confirm, message } = Modal;
const EmployeeAttendance = () => {
  const [month, setMonth] = useState(dayjs().toISOString());
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [attendanceData, setAttendanceData] = useState([]);
  const [columns, setColumns] = useState([]);

  const navigate = useNavigate();

  const handleDelete = (record) => {
    confirm({
      title: "Confirm Delete",
      content: `Are you sure you want to delete attendance record of employee ${record.name}?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        message.success("Employee deleted successfully!");
      },
      onCancel: () => {
        message.info("Delete canceled");
      },
    });
  };

  // const handleExport = () => {
  //   const tableData = [];

  //   tableData.push(table.columns.map((column) => column.title));

  //   table.dataSource.forEach((record) => {
  //     const rowData = table.columns.map((column) => record[column.dataIndex]);
  //     tableData.push(rowData);
  //   });

  //   const worksheet = XLSX.utils.aoa_to_sheet(tableData);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(
  //     workbook,
  //     worksheet,
  //     `EmployeeAttendance${month}`
  //   );

  //   const excelBuffer = XLSX.write(workbook, {
  //     bookType: "xlsx",
  //     type: "array",
  //   });

  //   const data = new Blob([excelBuffer], {
  //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  //   });

  //   const anchor = document.createElement("a");
  //   anchor.href = URL.createObjectURL(data);
  //   anchor.download = "table_export.xlsx";
  //   anchor.click();
  // };

  //export atteeandance table to excel
  const exportToExcel = (data, fileName) => {
    let count = 0;
    let otHours = 0;
    const tableData = [];
    tableData.push(
      columns
        .map((column) => column.title)
        .concat("Total Present Days", "Total OT Hours")
    );
    data.forEach((record) => {
      const rowData = [
        "EMP" + record.employeeCode.toString().padStart(4, 0),
        record.userId.name,
        //method to add items in array
        ...record.finalAttendance.map((item) => {
          return item.isApproved
            ? item.isHalfDay
              ? "H"
              : "F"
            : "A";
        }),

        //find total approved days
        record.finalAttendance.reduce((acc, item) => {
          if (item.isApproved) {
            count++;
          }
          return count;
        }, 0),

        //find total OT hours
        record.finalAttendance.reduce((acc, item) => {
          if (item.isApproved) {
            otHours += item.otHours;
          }
          return otHours;
        }, 0),
      ];
      tableData.push(rowData);
      count = 0;
    });
    const worksheet = XLSX.utils.aoa_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const fileData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    const anchor = document.createElement("a");
    anchor.href = URL.createObjectURL(fileData);
    anchor.download = `EmployeeAttendance-${moment(month).format(
      "MMM-YYYY"
    )}.xlsx`;
    anchor.click();
  };

  const generateColumns = (data) => {
    //columns for last 7 days
    const columns = [
      {
        title: "Employee Number",
        key: "name",
        fixed: "left",
        render: (_, data) => {
          return "EMP" + data.employeeCode.toString().padStart(4, "0");
        },
        responsive: ["md"],
      },
      {
        title: "Employee Name",
        key: "name",
        fixed: "left",
        render: (_, data) => {
          return data.userId.name;
        },
        responsive: ["md"],
      },
    ];

    //colums of past 7 days from currunt date with enitre date
    const newColumns  = data[0]?.finalAttendance?.forEach((item, index) => {
      const column = {
        title: moment(item.date).format("DD/MM/YYYY"),
        key: "date",
        render: (_, data) => {
          return data.finalAttendance[index]?.isApproved ? (
            data.finalAttendance[index]?.isHalfDay ? <b style={{ color: 'yellow' }}>H</b> :
              <>
                <div style={{ color: 'black' }}><b style={{ color: 'green' }}>P </b> {`${data?.finalAttendance[index]?.otHours !== 0 ? `${data?.finalAttendance[index]?.otHours} OT` : ''}`}</div>
                <div>{data?.finalAttendance[index]?.description}</div>
              </>
          ) : (
            <b style={{ color: 'red' }}>A</b>
          );
        },
      };

      columns.push(column);
      return column;
    });

    return columns;
  };

  // method to get attendace data with try catch
  const getAttendanceData = async () => {
    setLoading(true);
    try {
      const response = await APICall(
        `/employee/attendance?startDate=${dayjs(month)
          .startOf("month")
          .add(1, "day")
          .toISOString()}&endDate=${dayjs(month).endOf("month").toISOString()}`,
        {},
        1
      );
      setAttendanceData(response.data);
      setColumns(generateColumns(response.data));
      setLoading(false);
      setCurrentPage(1);
    } catch (error) {
      message.error("Failed to fetch data");
      setLoading(false);
    }
  };

  useEffect(() => {
    getAttendanceData();
  }, []);

  const data = [
    {
      _id: 1,
      name: "Mr. Gaurav More",
      date: ["P", "P", "A", "P", "A", "A", "P"],
      total: 4,
    },
    {
      _id: 2,
      name: "Ms. Priyadarshini Indalkar",
      date: ["P", "P", "P", "A", "P", "A", "P"],
      total: 5,
    },
    {
      _id: 3,
      name: "Mr. Omkar Raut",
      date: ["A", "A", "P", "P", "P", "A", "P"],
      total: 4,
    },
    {
      _id: 4,
      name: "Mr. Pruthvik Pratap",
      date: ["P", "P", "A", "P", "P", "P", "P"],
      total: 6,
    },
  ];

  return (
    <Card
      title="Attendance"
      extra={
        <Row justify={"end"} align="top" style={{ paddingBottom: "10px" }}>
          <Space>
            <DatePicker
              format={'DD-MM-YYYY'}
              picker="month"
              onChange={(value) => setMonth(value?.toISOString())}
            />
            <Button
              type="primary"
              // icon={<LuPlus style={{ paddingTop: "2px" }} />}
              onClick={getAttendanceData}
            >
              Load
            </Button>

            <Button
              type="primary"
              onClick={() => {
                navigate("/employees/attendance/accept");
              }}
            >
              Approve Attendances
            </Button>
            <Button
              type="primary"
              ghost
              onClick={() => {
                exportToExcel(attendanceData, "EmployeeAttendance");
              }}
            >
              Export
            </Button>
          </Space>
        </Row>
      }
    >
      <div style={{ overflowX: "hidden" }}>
        <Table
          columns={columns}
          dataSource={attendanceData}
          scroll={{ x: "5000px" }}
          size="small"
          loading={loading}
        // pagination={true}
        />
      </div>
    </Card>
  );
};

export default EmployeeAttendance;
