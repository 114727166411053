import { Card, Col, Form, Input, Row, Space, Button, Checkbox, message, Switch } from "antd";
import React, { useEffect, useState } from "react";
import DebounceSelect from "../../../components/debounceSelect/DebounceSelect";
import APICall from "../../../services/apiservices";
import BOQItem from "./BOQItem.component";
import TextArea from "antd/es/input/TextArea";
import downloadXLSX from "./export";
import { useParams, useNavigate } from "react-router-dom";

const filterSelectedItems = (items) => {
  return items
    .map((item) => {
      const filteredSubItems = item.items ? filterSelectedItems(item.items) : [];
      return item.isSelected || filteredSubItems.length > 0
        ? { ...item, items: filteredSubItems }
        : null;
    })
    .filter(Boolean);
};

const calculateTotals = (items) => {
  let totalMaterialAmount = 0;
  let totalLabourAmount = 0;

  items.forEach((item) => {
    if (item.isSelected) {
      totalMaterialAmount += item.materialTotal || 0;
      totalLabourAmount += item.labourTotal || 0;

      if (item.items && item.items.length > 0) {
        const subItemTotals = calculateTotals(item.items);
        totalMaterialAmount += subItemTotals.materialTotal;
        totalLabourAmount += subItemTotals.labourTotal;
      }
    }
  });

  return { materialTotal: totalMaterialAmount, labourTotal: totalLabourAmount };
};
const termsandConditionDefalut = `1. We shall arrange all required the tools, manpower, such as engineers, supervisors, skilled workers etc.
2. Expectation from you: Sufficient clear space for storing materials. Water for drinking free of charges & permission the work round the clock as well as clear site. Electrical power to be made available inside the Building Area of Erection.
3. Final billing will be as per measurement/quantity installed.
4. GST will be Extra at actual.
5. Payment Terms:
  60% Advance With PO
  35% Against Supply
  5% After Completion
6. Liasioning Charges will be extra at actual.
7. This offer is based on the drawing received from your side.
8. You will provide provisional NOC if required.
9. Civil work, excavation, backfilling, making holes, backfilling will be in your scope.
10. You will provide incoming supply up to our panels.`

const ManageBOQ = ({ isEdit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [customerForm] = Form.useForm();
  const [customer, setCustomer] = useState(undefined);
  const [megaRecords, setMegaRecords] = useState([]);
  const [existingMegaRecords, setExistingMegaRecords] = useState([]);
  const [originalMegaRecords, setOriginalMegaRecords] = useState([]);
  const { boqId } = useParams();
  const navigate = useNavigate();
  const [termsAndConditions, setTermsAndConditions] = useState('');
  const [showInternalRate, setShowInternalRate] = useState(false);

  const compareAndMarkSubItems = (originalSubItems, existingSubItems) => {
    return originalSubItems.map(originalSubItem => {
      const matchingSubItem = existingSubItems.find(existingSubItem =>
        existingSubItem && existingSubItem._id === originalSubItem._id
      );
      if (matchingSubItem) {
        return {
          ...originalSubItem,
          ...matchingSubItem,
          isSelected: true,
          items: originalSubItem.items && matchingSubItem.variations
            ? compareAndMarkSubItems(originalSubItem.items, matchingSubItem.variations)
            : originalSubItem.items, 
        };
      } else {
        return {
          ...originalSubItem,
          isSelected: false,
          items: originalSubItem.items ? originalSubItem.items.map(subVariation => ({
            ...subVariation,
            isSelected: false,
          })) : [],
        };
      }
    });
  };

  const compareAndMarkItems = (originalItems, existingMegaItems) => {
    return originalItems.map(originalItem => {
      const matchingItem = existingMegaItems.find(existingItem => existingItem.name === originalItem.name);
      if (matchingItem) {
        return {
          ...originalItem,
          ...matchingItem,
          isSelected: true,

          items: originalItem.items && matchingItem.items
            ? compareAndMarkSubItems(originalItem.items, matchingItem.items)
            : originalItem.items,

          variations: originalItem.variations && matchingItem.variations
            ? compareAndMarkSubItems(originalItem.variations, matchingItem.variations)
            : originalItem.variations,
        };
      } else {
        return {
          ...originalItem,
          isSelected: false,
          items: originalItem.items ? originalItem.items.map(subItem => ({
            ...subItem,
            isSelected: false,
          })) : [],
          variations: originalItem.variations ? originalItem.variations.map(subVariation => ({
            ...subVariation,
            isSelected: false,
          })) : [],
        };
      }
    });
  };

  const fetchCustomerName = async (customerId) => {
    try {
      const response = await APICall(`/customer/${customerId}`, {}, 1);
      const customerData = response.data.user;
      setCustomer(JSON.stringify(response.data));
      customerForm.setFieldsValue({
        customer: customerData.name,
      });
    } catch (error) {
      message.error("Failed to fetch customer details");
    }
  };
  
  useEffect(() => {
    if (isEdit && originalMegaRecords.length > 0) {
      const fetchExistingBOQ = async () => {
        try {
          const response = await APICall(`/boq/${boqId}`, {}, 1);
          const existingBOQ = response.data;
          setExistingMegaRecords(response.data);
          fetchCustomerName(existingBOQ.customerId);
          const updatedMegaRecords = compareAndMarkItems(originalMegaRecords, existingBOQ.mega);
          setMegaRecords(updatedMegaRecords);
          setTermsAndConditions(existingBOQ.termsAndConditions);
        } catch (error) {
          message.error("Failed to fetch BOQ details");
        }
      };
      fetchExistingBOQ();
    }
    if(!isEdit){
      setTermsAndConditions(termsandConditionDefalut)
    }
  }, [isEdit, boqId, customerForm, originalMegaRecords]);

  const getOriginalMegaRecords = async () => {
    try {
      const response = await APICall(`/boqItem/mega`, {}, 1);
      setOriginalMegaRecords(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOriginalMegaRecords(); 
  }, []);

  const handleCreate = async () => {
    try {
      const filteredSystems = megaRecords
        .map((record) => {
          const filteredItems = filterSelectedItems(record.items);
          const { materialTotal, labourTotal } = calculateTotals(filteredItems);

          return {
            ...record,
            items: filteredItems,
            total: {
              materialTotal,
              labourTotal, 
              grandAmount: materialTotal + labourTotal,
            },
          };
        })
        .filter((record) => record.items.length > 0);

      let totalLabourAmount = 0;
      let totalMaterialAmount = 0;

      filteredSystems.forEach((system) => {
        totalLabourAmount += Number(system.total.labourTotal) || 0;
        totalMaterialAmount += Number(system.total.materialTotal) || 0;
      });

      if (!customer) {
        message.error("Please select customer");
        customerForm.scrollToField("customer");
        return;
      }

      const customerData = JSON.parse(customer);
      const organizationData = await getorganizationdetails();

      if (!organizationData) {
        message.error("organization details not found");
        return;
      }

      const data = {
        organization: {
          name: organizationData?.name,
          address: organizationData?.address,
          mobile: organizationData?.mobile,
          email: organizationData?.email,
        },
        customer: {
          name: customerData?.user?.name,
          address: customerData?.user?.address,
        },
        systems: filteredSystems,
        total: {
          materialAmount: totalMaterialAmount,
          labourAmount: totalLabourAmount,
          grand: totalMaterialAmount + totalLabourAmount,
        },
        termsAndConditions: termsAndConditions,
      };

      const payload = filteredSystems.map((system) => ({
        name: system.name,
        items: system.items.map((item) => ({
          _id: item._id,
          name: item.name,
          make: item.make,
          unit: item.unit,
          quantity: item.quantity,
          materialRate: item.materialRate,
          materialTotal: item.materialTotal,
          labourRate: item.labourRate,
          labourTotal: item.labourTotal,
          purchaseRate: item.purchaseRate,
          labourInternalRate: item.labourInternalRate,
          internallabourTotal: (item.labourInternalRate * item.quantity) ,
          internalmaterialTotal: (item.purchaseRate  * item.quantity),
          variations: item.items?.map((subItem) => ({
            _id: subItem._id,
            name: subItem.name,
            make: subItem.make,
            unit: subItem.unit,
            quantity: subItem.quantity,
            materialRate: subItem.materialRate,
            materialTotal: subItem.materialTotal,
            labourRate: subItem.labourRate,
            labourTotal: subItem.labourTotal,
            purchaseRate: subItem.purchaseRate,
            labourInternalRate: subItem.labourInternalRate,
            internallabourTotal: (subItem.labourInternalRate * subItem.quantity) ,
            internalmaterialTotal: (subItem.materialTotal  * subItem.quantity),
            __original: {
              name: subItem.originalName || subItem.name,
              labourRate: subItem.originalLabourRate || subItem.labourRate,
              materialRate: subItem.originalMaterialRate || subItem.materialRate,
              purchaseRate: subItem.purchaseRate,
              labourInternalRate: subItem.labourInternalRate,
            },
          })),
          __original: {
            name: item.originalName || item.name,
            unit: item.originalUnit || item.unit,
            labourRate: item.originalLabourRate || item.labourRate,
            materialRate: item.originalMaterialRate || item.materialRate,
            make: item.originalMake || item.make,
            purchaseRate: item.purchaseRate,
            labourInternalRate: item.labourInternalRate,
          },
        })),
      }));

      const payloadData = {
       "customerId": customerData._id,
        "termsAndConditions": termsAndConditions,
        "mega": payload,
        "working": [{}] //future
      };

      downloadXLSX(data);
      try {
        if(isEdit){
          const response = await APICall(`/boq/${existingMegaRecords._id}`, payloadData, 3);
          message.success("BOQ updated successfully!");
        } else {
          const response = await APICall("/boq/", payloadData, 2);
          message.success("BOQ created successfully!");
        }
        navigate("/boq");
      } catch (error) {
        message.error("Failed to create BOQ.");
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateItemInList = (items, itemIndex, updatedItem, subItemIndex = null) => {
    return items.map((item, index) => {
      if (index === itemIndex) {
        if (subItemIndex !== null && item.items && item.items.length > 0) {
          const updatedSubItems = updateItemInList(item.items, subItemIndex, updatedItem);
          return {
            ...item,
            items: updatedSubItems,
          };
        }
        return {
          ...item,
          ...updatedItem,
        };
      }
      return item;
    });
  };

  const updateMegaRecordsData = (recordIndex, itemIndex, updatedItem, subItemIndex = null) => {
    const updatedRecords = [...megaRecords];
    updatedRecords[recordIndex].items = updateItemInList(updatedRecords[recordIndex].items, itemIndex, updatedItem, subItemIndex);
    setMegaRecords(updatedRecords);
  };

  const updateRecordSelection = (recordIndex, isSelected) => {
    const updatedRecords = [...megaRecords];
    updatedRecords[recordIndex].isSelected = isSelected;
    setMegaRecords(updatedRecords);
  };

  const getorganizationdetails = async () => {
    try {
      const response = await APICall(`/organization/649bff0f87a35d7bececa3d8`, {}, 1);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCustomerList = async (searchValue) => {
    const res = await APICall(
      `/customer?page=1&limit=30&query=${searchValue}`,
      {},
      1
    );
    return res.data.documents.map((customer) => ({
      label: customer.user.name,
      value: JSON.stringify(customer),
    }));
  };

  const getMegaRecordsFromDatabase = async () => {
    try {
      const response = await APICall(`/boqItem/mega`, {}, 1);
      setMegaRecords(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMegaRecordsFromDatabase();
  }, []);

  return (
    <>
    <Card title={isEdit ? "Edit BOQ" : "Create BOQ"} style={{ marginBottom: 16 }}>
      <Row gutter={16}>
        <Col span={24}>
          <Form layout="vertical" form={customerForm}>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  label="Customer"
                  name="customer"
                  rules={[{ required: true, message: "Please select customer" }]}
                >
                  <DebounceSelect
                    value={customer}
                    placeholder="Select customer"
                    fetchOptions={fetchCustomerList}
                    onChange={(newValue) => setCustomer(newValue)}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Card>  
      <Card style={{ marginBottom: 16 }}>
        <Col span={24}>
        <Row justify="end" style={{ width: "100%", marginBottom: 20 }}>
          <Space align="center">
            <h3 style={{ margin: 0 }}>Show Internal Rate</h3>
              <Switch
                size="small"
                onChange={(checked) => setShowInternalRate(checked)}
              />
            </Space>
          </Row>
          <Space direction="vertical" size={16} style={{ width: "100%" }}>
            {megaRecords.map((record, recordIndex) => (
              <div key={recordIndex}>
                <Checkbox
                  checked={record.isSelected || false}
                  onChange={(e) => updateRecordSelection(recordIndex, e.target.checked)}
                >
                  <h3>{record.name}</h3>
                </Checkbox>

                {record.isSelected && (
                  <>
                    {record.items.map((item, itemIndex) => (
                      <BOQItem
                        key={itemIndex}
                        item={item}
                        updateMegaRecordsData={(updatedItem, subItemIndex = null) =>
                          updateMegaRecordsData(recordIndex, itemIndex, updatedItem, subItemIndex)
                        }
                        showInternalRate={showInternalRate}
                      />
                    ))}
                  </>
                )}
              </div>
            ))}
          </Space>
        </Col>
        </Card>
        <Card sx={{mt:2}}> 
        <Col span={24}>
          <Space direction="vertical" size={16} style={{ width: "100%" }}>
            <Form.Item label="Terms & Conditions" labelCol={{ span: 24 }}  >
              <TextArea
                size="small"
                rows={20}
                value={termsAndConditions}
                onChange={(e) => setTermsAndConditions(e.target.value)}
              />
            </Form.Item>
          </Space>
        </Col>
        <Row justify="end">          
        <Button
          type="primary"
          loading={isLoading}
          onClick={handleCreate}
          style={{ marginTop: "24px" }}
        >
          {isEdit ? "Save" : "Create"}
        </Button>
        </Row>
      </Card>     
    </>
  );
};

export default ManageBOQ;