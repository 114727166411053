import React, { useEffect, useState } from "react";
import { GoInfo } from "react-icons/go";
import { FiUsers } from "react-icons/fi";
import { RiSurveyLine } from "react-icons/ri";
import { LuTimer, LuUser } from "react-icons/lu";
import {
  Card,
  Col,
  Row,
  Input,
  Radio,
  Space,
  Tooltip,
  Statistic,
  DatePicker,
  Table,
  Form,
  Typography,
  Button,
  message,
  Modal,
  InputNumber,
  Progress,
  Dropdown,
} from "antd";

import { Link, Navigate, useNavigate } from "react-router-dom";
import CountUp from "react-countup";
import dayjs from "dayjs";
import APICall from "../../services/apiservices";
import moment from "moment/moment";
import Item from "antd/es/list/Item";

const { TextArea } = Input;

const Dashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [attedanceModelVisible, setAttendanceModelVisible] = useState(false);
  const [visiblec, setVisiblec] = useState(false);
  const [extraHours, setExtraHours] = useState(0);
  const [date, setDate] = useState(null);
  const [form] = Form.useForm();
  const [otForm] = Form.useForm();
  const [dashboardData, setDashboardData] = useState();

  const formatter = (value) => <CountUp end={value} separator="," />;

  useEffect(() => {
    getDashboardData();
  }, []);

  const getDashboardData = async () => {
    try {
      const userData = JSON.parse(localStorage.getItem("userData"));
      const response = await APICall(
        `/employee/${userData.employee._id}/dashboard`,
        {},
        1
      );
      setDashboardData(response.data);
      setLoading(false);
    } catch (error) {
      message.error(error);
    }
  };

  const visitDel = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Customer",
      key: "customer",
      render: (record) => record?.customer?.user?.name,
    },
    {
      title: "Scheduled Date",
      key: "scheduledDate",
      render: (record) => {
        return moment(record.scheduledDate).format("DD/MM/YYYY");
      },
    },
    {
      title: "Action",
      render: (record) => (
        <Button
          onClick={() =>
            navigate(
              `/visit/${record._id}/buildings?customerId=${record.customerId}&customerName=${record.customer.user.name}&pagename=Visits`
            )
          }
          type="link"
        >
          Conduct
        </Button>
      ),
      align: "center",
      fixed: "right",
    },
  ];

  const surveyCol = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Customer",
      key: "customer",
      render: (_, record) => {
        return record.customerId.userId.name;
      },
    },
    {
      title: "Survey Date",
      dataIndex: "surveyDate",
      key: "surveyDate",
      render: (_, record) => moment(record.surveyDate).format("DD/MM/YYYY"),
    },
    {
      title: "Action",
      render: (_, record) => (
        <Button
          onClick={() =>
            navigate(
              `/survey/${record._id}/buildings?customerId=${record.customerId._id}&customerName=${record.customerId.userId.name}&pagename=Survey`
            )
          }
          type="link"
        >
          Conduct
        </Button>
      ),
      align: "center",
      fixed: "right",
    },
  ];

  const handleOkc = () => {
    setVisiblec(false);
  };

  const handleCancelc = () => {
    setVisiblec(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
    console.log(`Extra working hours: ${extraHours}`);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleDateChange = (value) => {
    setDate(value);
    console.log(`Selected date: ${value}`);
  };

  const showAtendanceModal = () => {
    setAttendanceModelVisible(true);
  };

  const handleAttendanceOk = () => {
    markAttendance();
    setAttendanceModelVisible(false);
  };

  const handleAttandanceCancel = () => {
    setAttendanceModelVisible(false);
  };

  const markAttendance = () => {
    console.log("Attendance marked for today");
  };
  const onFinisha = (values) => {
    console.log("Success:", values);
    setVisible(false);
  };

  const onAttendanceFinish = async (formdata) => {
    console.log(formdata);
    try {
      debugger;
      setAttendanceModelVisible(false);
      const localStorageUserData = localStorage.getItem("userData");
      const userData = JSON.parse(localStorageUserData);
      const data = {};
      data.date = formdata.date;
      data.description = formdata.description;
      formdata.status === "halfDay" && (data.isHalfDay = "true");
      console.clear();
      console.log(data);
      // const response = {
      //   status: true,
      // };
      const response = await APICall(
        `/employee/${userData.employee._id}/attendance`,
        data,
        2
      );
      if (response?.status) {
        getDashboardData();
        Modal.success({
          title: "Attendance Marked",
          content: "",
        });
      }
    } catch (error) {
      Modal.error({
        title: "Failed to mark attendance",
      });
    }
  };

  const onExtraHoursFinish = async (formdata) => {
    if (dashboardData?.todayAttendance === null) {
      Modal.error({
        title: "Can't add extra hours, first mark today's attendance.",
        onOk: () => {
          setVisible(false);
        },
      });
    } else {
      try {
        setVisible(false);
        const localStorageUserData = localStorage.getItem("userData");
        const userData = JSON.parse(localStorageUserData);
        const data = {
          date: moment().utc(),
          otHours: formdata.otHours,
        };
        const response = await APICall(
          `/employee/${userData.employee._id}/attendance`,
          data,
          3
        );
        if (response?.status) {
          getDashboardData();
          Modal.success({
            title: "Extra Hours Marked",
            content: "",
          });
        }
      } catch (error) {
        Modal.error({
          title: "Failed to mark extra hours",
        });
      }
    }
  };

  const disabledDates = (current) => {
    // Do not allow dates after today
    return current && current > new Date().endOf("day");
  };

  return (
    <>
      <Row gutter={10}>
        <Col lg={6} md={6} sm={12} xs={24} className="gutter-row" span={6}>
          <Card
            bordered={false}
            // onClick={() => {
            //   navaigate("/employees");
            // }}
            style={{
              cursor: "pointer",
            }}
            // loading={loading}
          >
            <Statistic
              prefix={
                <FiUsers style={{ fontSize: "20px", color: "#024fa1" }} />
              }
              precision={2}
              formatter={formatter}
              // loading={loading}
              title={
                <>
                  <Row justify="space-between">
                    <Col>Total Visits</Col>
                    <Col>
                      <Tooltip title="Pending Visits">
                        <GoInfo style={{ fontSize: "20px" }} />
                      </Tooltip>
                    </Col>
                  </Row>
                </>
              }
              valueStyle={{ fontWeight: 500 }}
              value={dashboardData?.visitsCount}
            />
            <Progress percent={99} showInfo={false} />
            <hr style={{ opacity: "25%" }}></hr>
            <Typography.Text>Total Visits this month </Typography.Text>
          </Card>
        </Col>

        <Col lg={6} md={6} sm={12} xs={24} className="gutter-row" span={6}>
          <Card
            bordered={false}
            // onClick={() => {
            //   navaigate("/surveys");
            // }}
            style={{
              cursor: "pointer",
            }}
            // loading={loading}
          >
            <Statistic
              prefix={
                <RiSurveyLine style={{ fontSize: "20px", color: "#024fa1" }} />
              }
              precision={2}
              formatter={formatter}
              loading={loading}
              title={
                <>
                  <Row justify="space-between">
                    <Col> Total Surveys</Col>
                    <Col>
                      <Tooltip title="Pending Surveys">
                        <GoInfo style={{ fontSize: "20px" }} />
                      </Tooltip>
                    </Col>
                  </Row>
                </>
              }
              valueStyle={{ fontWeight: 500 }}
              value={dashboardData?.surveyCount}
            />

            <Progress percent={99} showInfo={false} />
            <hr style={{ opacity: "25%" }}></hr>
            <Typography.Text>Total surveys this month</Typography.Text>
          </Card>
        </Col>

        <Col lg={6} md={6} sm={12} xs={24} className="gutter-row" span={6}>
          <Card
            bordered={false}
            // onClick={() => {
            //   navaigate("/customers");
            // }}
            // style={{
            //   cursor: "pointer",
            // }}
            // loading={loading}
          >
            <Statistic
              prefix={<LuUser style={{ fontSize: "20px", color: "#024fa1" }} />}
              precision={2}
              formatter={formatter}
              // loading={loading}
              title={
                <>
                  <Row justify="space-between">
                    <Col>Attendance</Col>
                    <Col>
                      <Tooltip title="This month Attendance">
                        <GoInfo style={{ fontSize: "20px" }} />
                      </Tooltip>
                    </Col>
                  </Row>
                </>
              }
              valueStyle={{ fontWeight: 500 }}
              value={dashboardData?.attendanceCount}
            />
            <Progress percent={99} showInfo={false} />
            <hr style={{ opacity: "25%" }}></hr>
            <Space>
              {dashboardData?.todayAttendance ? (
                <>Your attendnace is marked for today</>
              ) : (
                <Button
                  type="primary"
                  onClick={showAtendanceModal}
                  style={{ alignItems: "center" }}
                >
                  Mark Attendance
                </Button>
              )}
              <Modal
                title="Mark Attendance"
                open={attedanceModelVisible}
                onOk={form.submit}
                onCancel={handleAttandanceCancel}
                centered
              >
                <Form
                  form={form}
                  onFinish={onAttendanceFinish}
                  layout="vertical"
                >
                  <Space direction="vertical">
                    <Typography.Text>
                      {`Do you want to mark attendance?`}
                    </Typography.Text>
                    {/* set the selected date in attendanceDate state */}
                    <Form.Item
                      label="Attendance Date"
                      name="date"
                      rules={[
                        {
                          required: true,
                          message: "Please select the attendance date",
                        },
                      ]}
                    >
                      <DatePicker
                        format={'DD-MM-YYYY'}
                        // disabledDate={disabledDates}
                        placeholder="Please select date"
                        style={{ width: "100%" }}
                        tabIndex={4}
                      />
                    </Form.Item>
                    <Form.Item name={"status"} initialValue={"fullDay"}>
                      <Radio.Group defaultValue="present" buttonStyle="solid">
                        <Radio value="fullDay">Full Day</Radio>
                        {/* <Radio value="absent">Absent</Radio> */}
                        <Radio value="halfDay">Half Day</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item name={"description"}>
                      <TextArea name="description" rows={4} />
                    </Form.Item>
                  </Space>
                </Form>
              </Modal>
            </Space>
            {/* <Typography.Text>
              This month sales {data.customers.thisMonthSales}
            </Typography.Text> */}
          </Card>
        </Col>
        <Col lg={6} md={6} sm={12} xs={24} className="gutter-row" span={6}>
          <Card
            bordered={false}
            // onClick={() => {
            //   navaigate("/customers");
            // }}
            // style={{
            //   cursor: "pointer",
            // }}
            // loading={loading}
          >
            <Statistic
              prefix={
                <LuTimer style={{ fontSize: "20px", color: "#024fa1" }} />
              }
              precision={2}
              formatter={formatter}
              // loading={loading}
              title={
                <>
                  <Row justify="space-between">
                    <Col> Extra Working Hours</Col>
                    <Col>
                      <Tooltip title="Total Working hours of this month">
                        <GoInfo style={{ fontSize: "20px" }} />
                      </Tooltip>
                    </Col>
                  </Row>
                </>
              }
              valueStyle={{ fontWeight: 500 }}
              value={dashboardData?.otHours}
            />
            <Progress percent={99} showInfo={false} />
            <hr style={{ opacity: "25%" }}></hr>
            {dashboardData?.todayAttendance === null ? (
              <Button
                type="primary"
                onClick={showModal}
                style={{ alignItems: "center" }}
              >
                Add Extra Hours
              </Button>
            ) : dashboardData?.todayAttendance?.otHours === 0 ? (
              <Button
                type="primary"
                onClick={showModal}
                style={{ alignItems: "center" }}
              >
                Add Extra Hours
              </Button>
            ) : (
              <>Your extra hours are marked for today</>
            )}

            <Modal
              title="Today's Extra Working Hours"
              open={visible}
              onOk={otForm.submit}
              onCancel={handleCancel}
              centered
            >
              <Form form={otForm} onFinish={onExtraHoursFinish}>
                <Form.Item
                  name={"otHours"}
                  label="Extra Working Hours"
                  initialValue={0}
                >
                  <InputNumber
                    min={0}
                    // style={{ width: "90%", maxWidth: 300 }}
                  />
                </Form.Item>
              </Form>
              {/* <label>Extra working hours: </label>
                  <InputNumber
                    min={0}
                    value={extraHours}
                    onChange={setExtraHours}
                    style={{ width: "90%", maxWidth: 300 }}
                  /> */}
            </Modal>
            {/* <Typography.Text>
              This month sales {data.customers.thisMonthSales}
            </Typography.Text> */}
          </Card>
        </Col>
        <Col className="gutter-row" span={6}></Col>
      </Row>

      <Row gutter={10} style={{ marginTop: "1rem" }}>
        <Col lg={12} md={12} sm={24} xs={24} span={12}>
          <Card
            title="Scheduled Surveys"
            extra={
              <Link to="/surveys">
                <Button type="primary" ghost>
                  View All
                </Button>
              </Link>
            }
            style={{
              width: "100%",
            }}
            // loading={loading}
          >
            <Table
              columns={surveyCol}
              // loading={loading}
              dataSource={dashboardData?.surveys}
              pagination={false}
            />
          </Card>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24} span={12}>
          <Card
            title="Scheduled Visits "
            extra={
              <Link to="/visits">
                <Button type="primary" ghost>
                  View All
                </Button>
              </Link>
            }
            style={{
              width: "100%",
            }}
            // loading={loading}
          >
            <Table
              columns={visitDel}
              // loading={loading}
              dataSource={dashboardData?.visits}
              pagination={false}
            />
          </Card>
        </Col>
        <Modal
          title="Conduct Visit"
          open={visiblec}
          onOk={handleOkc}
          onCancel={handleCancelc}
          centered
        >
          <Typography.Text>
            This action will save your current location.
          </Typography.Text>
          <br />
          <strong>Remarks:</strong>
          <TextArea />
        </Modal>
      </Row>
    </>
  );
};

export default Dashboard;
