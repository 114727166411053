import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Form,
  Input,
  Button,
  Row,
  Col,
  Space,
  Image,
  Upload,
  message,
  InputNumber,
  Select,
} from "antd";
import APICall from "../../../services/apiservices";
import axios from "axios";

const ManageBOQItems = ({ isEdit }) => {
  const [isParent, setIsParent] = useState(false);
  const [itemData, setItemData] = useState();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [img, setImg] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [photoFileId, setPhotoFileId] = useState();
  const [photoURL, setPhotoURL] = useState();
  const [systems, setSystems] = useState([]);
  const [parentItems, setParentItems] = useState([]);
  const navigate = useNavigate();
  const params = useParams();

  const showImage = (img) => {
    setImg(img);
    console.log(img);
    setVisible(true);
  };

  const getSystems = async () => {
    try {
      const response = await APICall(
        `/organization/649bff0f87a35d7bececa3d8/systemType`,
        {},
        1
      );
      setSystems(response.data);
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  const getParentItems = async (systemId) => {
    try {
      const response = await APICall(
        `/boqItem?systemId=${systemId}&isParent=true`,
        {},
        1
      )
      setParentItems(response.data.documents);
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  const getItemDetails = async () => {
    try {
      console.log(params.id);
      const response = await APICall(`/boqItem/${params.id}`, {}, 1);
      setItemData(response.data);
      setImg(response.data.photoURL);
      setIsLoading(false);
    } catch (error) {
      message.error(error.response.data.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSystems();
    if (isEdit) {
      setIsLoading(true);
      getItemDetails();
    }
  }, []);

  useEffect(() => {
    if (itemData) {
      const initialValues = {
        name: itemData?.name,
        materialRate: itemData?.materialRate,
        purchaseRate: itemData?.purchaseRate,
        unit: itemData?.unit,
        systemId: { value: itemData?.system?._id, label: itemData?.system?.name }
      };
      form.setFieldsValue(initialValues);
    }
  }, [itemData]);

  const onFinish = async (values) => {
    setIsLoading(!isLoading);
    if (isEdit) {
      const systemId = values?.systemId?.value ?? values.systemId
      const response = await APICall(
        `/boqItem/${params.id}`,
        { ...values, systemId },
        3
      );
      if (response.data) {
        setIsLoading(false);
        message.success("Item has been updated successfully.");
        navigate("/boqItems");
      }
      try {
      } catch (error) {
        setIsLoading(false);
        message.error(error.response.data.message);
      }
    } else {
      try {
        const response = await APICall(
          `/boqItem`,
          values,
          2
        );
        setIsLoading(false);
        if (response.data) {
          message.success("BOQ Item has been created successfully.");
        }
        navigate("/boqItems");
      } catch (error) {
        setIsLoading(false);
        message.error(error.response.data.message);
      }
    }
  };

  const handleFileUpload = async (file, fileType) => {
    const fileData = {
      requirement: [
        {
          order: 0,
          extension: file.name.substring(file.name.lastIndexOf(".") + 1),
        },
      ],
      module: "BOQ Item",
    };

    const fileResponse = await APICall("/file", fileData, 2);
    await axios.put(fileResponse.data.data[0].url, file);
    setPhotoFileId(fileResponse.data.data[0].id);
    showImage(await getBase64(file));
    return false;
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <Card
      title={isEdit ? "Edit BOQ Item" : "Create BOQ Item"}
    // extra={
    //   <>
    //     <Link to="/items">
    //       <Button type="primary">Back</Button>
    //     </Link>
    //   </>
    // }
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="systemId"
              label="System Type"
              rules={[
                { required: true, message: "Please enter the system type of the item" },
              ]}
            >
              <Select
                placeholder="Please select"
                onChange={(value) => {
                  getParentItems(value);
                }}
                options={systems?.map((s) => ({
                  label: s.name,
                  value: s._id,
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="isParent"
              label="Is this a main item under the system?"
              rules={[
                { required: true, message: "Please enter the parent item of the item" },
              ]}
            >
              <Select
                placeholder="Please select"
                onChange={(value) => { setIsParent(value) }}
                options={[
                  { label: "Yes", value: true },
                  { label: "No", value: false, checked: true },
                ]}
              />
            </Form.Item>
          </Col>

          {
            !isParent && (
              <Col span={8}>
                <Form.Item
                  name="parentId"
                  label="Parent Item"
                  rules={[
                    { required: false, message: "Please enter the parent item of the item" },
                  ]}
                >
                  <Select
                    placeholder="Please select"
                    options={parentItems?.map((parentItem) => ({
                      label: parentItem.name,
                      value: parentItem._id,
                    }))}
                  />
                </Form.Item>
              </Col>
            )
          }
          <Col span={8}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please enter the name of the item",
                },
              ]}
            >
              <Input placeholder="e.g. CNG Cylindar (14 KG)" />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item
              name="make"
              label="Make"
              rules={[
                { required: false, message: "Please enter the make of the item" },
              ]}
            >
              <Input placeholder="e.g. Appollo" />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item
              name="unit"
              label="Unit"
              rules={[
                { required: true, message: "Please enter the unit of the item" },
              ]}
            >
              <Input placeholder="e.g. mtr" />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item
              name="purchaseRate"
              label="Purchase Rate (₹)"
              rules={[
                {
                  required: true,
                  message: "Please enter the purchase price of the item",
                },
                {
                  pattern: /^[0-9]+(\.[0-9]{1,2})?$/,
                  message: "Please enter a valid price",
                },
              ]}
            >
              <InputNumber placeholder="e.g. 1400" />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item
              name="materialRate"
              label="Material Rate (₹)"
              rules={[
                {
                  required: true,
                  message: "Please enter the price of the item",
                },
                {
                  pattern: /^[0-9]+(\.[0-9]{1,2})?$/,
                  message: "Please enter a valid price",
                },
              ]}
            >
              <InputNumber placeholder="e.g. 1400" />
            </Form.Item>
          </Col>

          <Col span={5}>
            <Form.Item
              name="labourInternalRate"
              label="Labour Internal Rate (₹)"
              rules={[
                {
                  required: true,
                  message: "Please enter the labour internal price of the item",
                },
                {
                  pattern: /^[0-9]+(\.[0-9]{1,2})?$/,
                  message: "Please enter a valid price",
                },
              ]}
            >
              <InputNumber placeholder="e.g. 1400" />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item
              name="labourRate"
              label="Labour Rate (₹)"
              rules={[
                {
                  required: true,
                  message: "Please enter the labour price of the item",
                },
                {
                  pattern: /^[0-9]+(\.[0-9]{1,2})?$/,
                  message: "Please enter a valid price",
                },
              ]}
            >
              <InputNumber placeholder="e.g. 1400" />
            </Form.Item>
          </Col>

        </Row>

        <Form.Item style={{ marginTop: "20px" }}>
          <Button
            type="primary"
            htmlType="submit"

          //  style={{ float: "right" }}
          >
            {isEdit ? "Save" : "Create"}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default ManageBOQItems;
