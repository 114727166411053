import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Row,
  Col,
  Card,
  Table,
  message,
  Modal,
  Space,
  Image,
  Dropdown,
  Tag,
} from "antd";
import TextArea from "antd/es/input/TextArea";
// import APICall from "../../services/apiservices";
import { useForm } from "antd/es/form/Form";
import { Upload } from "antd";
import APICall from "../../services/apiservices";
import axios from "axios";

const { confirm } = Modal;

const Settings = () => {
  const { Item } = Form;
  const [form] = useForm();
  const [formLetterheads] = useForm();
  const [formLicenseCopy] = useForm();
  const [systemForm] = useForm();
  const [header, setHeader] = useState([]);
  const [footer, setFooter] = useState([]);
  const [licenseCopies, setLicenseCopies] = useState([]);
  const [organizationdetails, setorganizationdetails] = useState();
  const [imageUrl, setImageUrl] = useState(null);
  const [photoFileId, setPhotoFileId] = useState();
  const [visible, setVisible] = useState(false);
  const [showLetterheadModal, setShowLetterheadModal] = useState(false);
  const [showLicenseCopyModal, setShowLicenseCopyModal] = useState(false);
  const [showSystemModal, setShowSystemModal] = useState(false);
  const [showSystemEditModal, setShowSystemEditModal] = useState(false);
  const [systems, setSystems] = useState([]);
  const [editable, setEditable] = useState(false);
  const [img, setImg] = useState();

  const handleSystemOk = async (isEdit) => {
    setShowSystemModal(false);
    setShowSystemEditModal(false);
    if (await systemForm.validateFields()) {
      const formData = systemForm.getFieldsValue();
      if (isEdit) {
        await APICall(
          `/organization/649bff0f87a35d7bececa3d8/systemType/${formData.data._id}`,
          formData,
          3
        );
        message.success("System edited");
      } else {
        await APICall(
          `/organization/649bff0f87a35d7bececa3d8/systemType`,
          formData,
          2
        );
        message.success("System added");
      }
      systemForm.resetFields();
      getSystems();
    }
    // const formData = itemForm.getFieldsValue()
    // console.log(formData)
    // itemForm.resetFields();
    // setEmployeeValue(undefined)
    // if(selectedVisits.find(v => moment((new Date(v.scheduledDate))).format('DD/MM/YYYY') === moment((new Date(formData.scheduledDate))).format('DD/MM/YYYY'))) {
    //     message.warning('Visit already added for selected date')
    //     return;
    // }
    // const visitsSelected = [...selectedVisits]
    // setSelectedVisits([...visitsSelected, formData])
  };

  const handleSystemCancel = () => {
    systemForm.resetFields();
    setShowSystemModal(false);
    setShowSystemEditModal(false);
  };

  const onFinish = async (formData) => {
    delete formData.logo;
    console.log({ new: formData });
    try {
      const response = await APICall(
        `/organization/649bff0f87a35d7bececa3d8`,
        { ...formData, photoFileId: photoFileId },
        3
      );
      if (response.data) {
        message.success("organization information has been updated!");
        getorganizationdetails();
      }
    } catch (error) {
      alert(error);
    }
  };

  const showImage = (img) => {
    setImg(img);
    setVisible(true);
  };

  const handleFileUpload = async (file) => {
    const fileData = {
      requirement: [
        {
          order: 0,
          extension: file.name.substring(file.name.lastIndexOf(".") + 1),
        },
      ],
      module: "Setting",
    };

    const fileResponse = await APICall("/file", fileData, 2);
    await axios.put(fileResponse.data.data[0].url, file);

    setPhotoFileId(fileResponse.data.data[0].id);
    return false;
  };

  const handleLetterheadSubmit = (formData) => {
    try {
      const res = APICall(
        `/organization/649bff0f87a35d7bececa3d8/letterhead`,
        {
          name: formData.fileName,
          type: formData.type,
          fileId: photoFileId,
        },
        2
      );
      message.success("Letterhead added successfully.");
      setShowLetterheadModal(false);
      formLetterheads.resetFields();
      getLetterhead();
    } catch (error) {
      setShowLetterheadModal(false);
      message.error(error.response.data.message);
    }
  };

  const handleLicenseCopySubmit = async (formData) => {
    try {
      const res = await APICall(
        `/organization/649bff0f87a35d7bececa3d8/licenseCopy`,
        {
          name: formData.fileName,
          type: 'License Copy',
          fileId: photoFileId,
        },
        2
      );
      message.success("License Copy added successfully.");
      setShowLicenseCopyModal(false);
      formLetterheads.resetFields();
      getLicenseCopies();
    } catch (error) {
      setShowLicenseCopyModal(false);
      message.error(error.response.data.message);
    }
  };

  const getLetterhead = async () => {
    try {
      const response = await APICall(
        `/organization/649bff0f87a35d7bececa3d8/letterhead`,
        {},
        1
      );
      setHeader(response.data.letterheadHeaders);
      setFooter(response.data.letterheadFooters);
    } catch (error) {
      console.error(error);
    }
  };

  const getLicenseCopies = async () => {
    try {
      const response = await APICall(
        `/organization/649bff0f87a35d7bececa3d8/licenseCopy`,
        {},
        1
      );
      setLicenseCopies(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getSystems = async () => {
    try {
      const response = await APICall(
        `/organization/649bff0f87a35d7bececa3d8/systemType`,
        {},
        1
      );
      setSystems(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getorganizationdetails = async () => {
    try {
      const response = await APICall(
        `/organization/649bff0f87a35d7bececa3d8`,
        {},
        1
      );
      setorganizationdetails(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleLetterheads = (record) => {
    if (record.isDefault) {
      message.warning("Can not delete default letterheads.");
    } else {
      confirm({
        title: "Confirm Delete",
        content: `Are you sure you want to delete letterhead?`,
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk: async () => {
          const res = await APICall(
            `/organization/649bff0f87a35d7bececa3d8/letterhead/${record._id}`,
            {},
            4
          );
          // setReload(true);
          message.success("Letterhead deleted successfully!");
          getLetterhead();
        },
      });
    }
  };

  const handleDeleLicenseCopy = (record) => {
    if (record.isDefault) {
      message.warning("Can not delete default license copy.");
    } else {
      confirm({
        title: "Confirm Delete",
        content: `Are you sure you want to delete license copy?`,
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk: async () => {
          const res = await APICall(
            `/organization/649bff0f87a35d7bececa3d8/licenseCopy/${record._id}`,
            {},
            4
          );
          // setReload(true);
          message.success("License copy deleted successfully!");
          getLicenseCopies();
        },
      });
    }
  };

  const handleMakeDefault = (record) => {
    confirm({
      title: "Confirm Default",
      content: `Are you sure you want to make default letterhead?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        const res = await APICall(
          `/organization/649bff0f87a35d7bececa3d8/letterhead/${record._id}`,
          { isDefault: true },
          3
        );
        message.success("Default letterhead set successfully!");
        getLetterhead();
      },
    });
  };

  useEffect(() => {
    if (organizationdetails) {
      const initialValues = {
        name: organizationdetails?.name,
        gstn: organizationdetails?.gstn,
        licenceNumber: organizationdetails?.licenceNumber,
        email: organizationdetails?.email,
        finacialyear: organizationdetails?.financialYear,
        mobile: organizationdetails?.mobile,
        quotationNumberFormat: organizationdetails?.quotationNumberFormat,
        address: organizationdetails?.address,
        state: organizationdetails?.state,
        pincode: organizationdetails?.pincode,
        gstNumber: organizationdetails?.gstNumber,
        website: organizationdetails?.website,
      };
      form.setFieldsValue(initialValues);
    }
  }, [organizationdetails]);

  console.log("footer", footer);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: " Actions",
      render: (_, record) => (
        // <Dropdown
        //   menu={{
        //     items: [
        //       {
        //         key: 2,
        //         label: "Edit",
        //         onClick: () => {
        //           systemForm.setFieldsValue({
        //             data: record,
        //             name: record.name,
        //             description: record.description,
        //           });
        //           setShowSystemEditModal(true);
        //         },
        //       },
        //       {
        //         key: 3,
        //         label: "Delete",
        //         onClick: async () => {
        //           confirm({
        //             title: "Delete system?",
        //             content: `Are you sure you want to delete this system?`,
        //             okText: "Yes",
        //             okType: "danger",
        //             cancelText: "No",
        //             onOk: async () => {
        //               try {
        //                 await APICall(
        //                   `/organization/649bff0f87a35d7bececa3d8/systemType/${record._id}`,
        //                   {},
        //                   4
        //                 );
        //                 message.success("System deleted");
        //                 getSystems();
        //               } catch (error) {}
        //             },
        //           });
        //         },
        //         danger: true,
        //       },
        //     ],
        //   }}
        //   placement="bottom"
        // >
        //   <Button type="link">Actions</Button>
        // </Dropdown>
        <Space>
          <Button
            onClick={() => {
              systemForm.setFieldsValue({
                data: record,
                name: record.name,
                description: record.description,
              });
              setShowSystemEditModal(true);
            }}
            type="primary"
            ghost
            size="small"
          >
            Edit
          </Button>
          <Button
            onClick={() => {
              confirm({
                title: "Delete system?",
                content: `Are you sure you want to delete this system?`,
                okText: "Yes",
                okType: "danger",
                cancelText: "No",
                onOk: async () => {
                  try {
                    await APICall(
                      `/organization/649bff0f87a35d7bececa3d8/systemType/${record._id}`,
                      {},
                      4
                    );
                    message.success("System deleted");
                    getSystems();
                  } catch (error) {}
                },
              });
            }}
            type="primary"
            size="small"
          >
            Delete
          </Button>
        </Space>
      ),
      align: "center",
    },
  ];

  const headerCols = [
    {
      title: "Header Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Default",
      render: (_, record) => (
        <Tag color={record.isDefault ? "green" : "red"}>
          {record.isDefault ? "Yes" : "No"}
        </Tag>
      ),
    },
    {
      title: " Actions",
      render: (_, record) => (
        // <Dropdown
        //   menu={{
        //     items: [
        //       {
        //         key: 1,
        //         label: "View",
        //         onClick: () => {
        //           showImage(record.url);
        //         },
        //       },

        //       {
        //         key: 2,
        //         label: "Make Default",
        //         onClick: () => {
        //           handleMakeDefault(record);
        //         },
        //       },
        //       {
        //         key: 3,
        //         label: "Delete",
        //         onClick: () => {
        //           handleDeleLetterheads(record);
        //         },
        //         danger: true,
        //       },
        //     ],
        //   }}
        //   placement="bottom"
        // >
        //   <Button type="link">Actions</Button>
        // </Dropdown>
        <Space>
          <Button
            onClick={() => {
              showImage(record.url);
            }}
            type="primary"
            ghost
            size="small"
          >
            View
          </Button>
          <Button
            onClick={() => {
              handleMakeDefault(record);
            }}
            type="primary"
            size="small"
          >
            Make Default
          </Button>
          <Button
            onClick={() => {
              handleDeleLetterheads(record);
            }}
            type="primary"
            size="small"
          >
            Delete
          </Button>
        </Space>
      ),
      align: "center",
    },
  ];
  const footerCols = [
    {
      title: "Footer Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Default",
      render: (_, record) => (
        <Tag color={record.isDefault ? "green" : "red"}>
          {record.isDefault ? "Yes" : "No"}
        </Tag>
      ),
    },
    {
      title: " Actions",
      render: (_, record) => (
        // <Dropdown
        //   menu={{
        //     items: [
        //       {
        //         key: 1,
        //         label: "View",
        //         onClick: () => {
        //           showImage(record.url);
        //         },
        //       },

        //       {
        //         key: 2,
        //         label: "Make Default",
        //         onClick: () => {
        //           handleMakeDefault(record);
        //         },
        //       },
        //       {
        //         key: 3,
        //         label: "Delete",
        //         onClick: () => {
        //           handleDeleLetterheads(record);
        //         },
        //         danger: true,
        //       },
        //     ],
        //   }}
        //   placement="bottom"
        // >
        //   <Button type="link">Actions</Button>
        // </Dropdown>
        <Space>
          <Button
            onClick={() => {
              showImage(record.url);
            }}
            type="primary"
            ghost
            size="small"
          >
            View
          </Button>
          <Button
            onClick={() => {
              handleMakeDefault(record);
            }}
            type="primary"
            size="small"
          >
            Make Default
          </Button>
          <Button
            onClick={() => {
              handleDeleLetterheads(record);
            }}
            type="primary"
            size="small"
          >
            Delete
          </Button>
        </Space>
      ),
      align: "center",
    },
  ];

  const licenseCopyCols = [
    {
      title: "License Copy Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Default",
      render: (_, record) => (
        <Tag color={record.isDefault ? "green" : "red"}>
          {record.isDefault ? "Yes" : "No"}
        </Tag>
      ),
    },
    {
      title: " Actions",
      render: (_, record) => (
        <Space>
          <Button
            onClick={() => {
              showImage(record.url);
            }}
            type="primary"
            ghost
            size="small"
          >
            View
          </Button>
          <Button
            onClick={() => {
              handleMakeDefault(record);
            }}
            type="primary"
            size="small"
          >
            Make Default
          </Button>
          <Button
            onClick={() => {
              handleDeleLicenseCopy(record);
            }}
            type="primary"
            size="small"
          >
            Delete
          </Button>
        </Space>
      ),
      align: "center",
    },
  ];

  const handleAdd = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    getLetterhead();
    getLicenseCopies();
    getSystems();
    getorganizationdetails();
  }, []);

  console.log(header);

  return (
    <>
      <Row>
        <Col span={24}>
          <Card
            title={"Settings & Preferences"}
            extra={
              <Space>
                <Button
                  type="primary"
                  tabIndex={14}
                  ghost
                  onClick={() => {
                    setEditable(!editable);
                  }}
                >
                  {editable ? "Cancel" : "Edit"}
                </Button>
              </Space>
            }
            style={{ marginBottom: "20px" }}
          >
            <Form
              form={form}
              layout="vertical"
              wrapperCol={{
                span: 14,
              }}
              onFinish={onFinish}
            >
              <Row gutter={16}>
                <Col span={24}>
                  <Card title="" bordered={false}>
                    <Row gutter={10}>
                      <Col span={12}>
                        <Item
                          label="Name"
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the name",
                            },
                          ]}
                        >
                          <Input
                            disabled={!editable}
                            placeholder="e.g ABC organization"
                            tabIndex={1}
                          />
                        </Item>

                        <Item
                          label="GSTIN"
                          name="gstn"
                          rules={[
                            {
                              required: true,
                              message: "Enter GSTIN of the organization",
                            },
                          ]}
                        >
                          <Input
                            disabled={!editable}
                            placeholder="e.g GSTN12345678"
                            tabIndex={3}
                          />
                        </Item>

                        <Item
                          label="Logo"
                          name="logo"
                          rules={[
                            {
                              required: false,
                              message: "Please enter the Logo",
                            },
                          ]}
                        >
                          <Image src="ffsslogo.png" style={{ width: "20px" }} />
                        </Item>
                      </Col>
                      <Col span={12}>
                        <Item
                          label="License Number"
                          name="licenceNumber"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the licenc number",
                            },
                          ]}
                        >
                          <Input
                            disabled={!editable}
                            placeholder="e.g MFS - LA / RF-38"
                            tabIndex={2}
                          />
                        </Item>
                        <Item
                          label="Financial Year"
                          name="finacialyear"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Financial Year",
                            },
                          ]}
                        >
                          <Input
                            disabled={!editable}
                            placeholder="e.g 2023-2024"
                            tabIndex={4}
                          />
                        </Item>
                        <Item
                          label="Quotation Number Format"
                          name="quotationNumberFormat"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Quotation Number Format",
                            },
                          ]}
                        >
                          <Input
                            disabled={!editable}
                            placeholder="e.g QUOTE/23-24/0000 "
                            tabIndex={6}
                          />
                        </Item>
                      </Col>
                    </Row>
                  </Card>
                  <Card title="Contact Information" bordered={false}>
                    <Row gutter={10}>
                      <Col span={12}>
                        <Item
                          label="Email"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Email",
                            },
                          ]}
                        >
                          <Input
                            disabled={!editable}
                            placeholder="e.g abc@gmail.com"
                            tabIndex={7}
                          />
                        </Item>
                        <Item
                          label="Address"
                          name="address"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Address",
                            },
                          ]}
                        >
                          <Input
                            disabled={!editable}
                            placeholder="e.g Shiwaji Nagar, Pune-123456"
                            tabIndex={9}
                          />
                        </Item>
                      </Col>

                      <Col span={12}>
                        <Item
                          label="Mobile"
                          name="mobile"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the mobile",
                            },
                          ]}
                        >
                          <Input
                            disabled={!editable}
                            placeholder="e.g 0123456789"
                            tabIndex={8}
                          />
                        </Item>

                        <Item
                          label="Website"
                          name="website"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the website",
                            },
                          ]}
                        >
                          <Input
                            disabled={!editable}
                            placeholder="e.g organization.com"
                            tabIndex={10}
                          />
                        </Item>
                      </Col>
                    </Row>
                    {editable && (
                      <Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{ marginTop: "20px" }}
                        >
                          Save
                        </Button>
                      </Item>
                    )}
                  </Card>
                </Col>
              </Row>
            </Form>
          </Card>

          <Card title="Letterhead" style={{ marginBottom: "20px" }}>
            <Card
              title="Header"
              bordered={false}
              style={{ marginBottom: "20px" }}
            >
              <Button
                onClick={() => {
                  formLetterheads.setFieldsValue({
                    type: "Header",
                  });
                  setShowLetterheadModal(!showLetterheadModal);
                }}
                type="primary"
                style={{
                  marginBottom: 16,
                  marginLeft: "85%",
                }}
                ghost
              >
                Add Header
              </Button>
              <Table columns={headerCols} dataSource={header} size="large" />
            </Card>
            <Card title="Footer" bordered={false}>
              <Button
                onClick={() => {
                  formLetterheads.setFieldsValue({
                    type: "Footer",
                  });
                  setShowLetterheadModal(!showLetterheadModal);
                }}
                type="primary"
                style={{
                  marginBottom: 16,
                  marginLeft: "85%",
                }}
                ghost
              >
                Add Footer
              </Button>

              <Table columns={footerCols} dataSource={footer} size="large" />
            </Card>
            <Modal
              title="Add Letterheads"
              open={showLetterheadModal}
              onOk={formLetterheads.submit}
              onCancel={() => {
                setShowLetterheadModal(!showLetterheadModal);
              }}
              centered
            >
              <Form
                name="uploadLetterheads"
                form={formLetterheads}
                onFinish={handleLetterheadSubmit}
              >
                <Item
                  label="File Type"
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the file name",
                    },
                  ]}
                >
                  <Input disabled />
                </Item>

                <Item
                  label="File Name"
                  name="fileName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the file name",
                    },
                  ]}
                >
                  <Input />
                </Item>
                <Item
                  label="File"
                  name="file"
                  rules={[
                    {
                      required: true,
                      message: "Please upload a file",
                    },
                  ]}
                >
                  <Upload
                    Upload
                    name="productPhoto"
                    accept=".jpg, .png"
                    beforeUpload={handleFileUpload}
                    multiple={false}
                  >
                    <Button>Click to Upload</Button>
                  </Upload>
                </Item>
              </Form>
            </Modal>
            <Image
              preview={{
                visible,
                src: img,
                onVisibleChange: (value) => {
                  setVisible(value);
                },
              }}
            />
          </Card>

          <Card 
            title="License Copy" 
            style={{ marginBottom: "20px" }}
            extra={
              <Button
                onClick={() => {
                  setShowLicenseCopyModal(!showLicenseCopyModal);
                }}
                type="primary"
                ghost
              >
                  Add License Copy
                </Button>
            }>

            <Table columns={licenseCopyCols} dataSource={licenseCopies} size="large" />
            <Modal
              title="Add License Copy"
              open={showLicenseCopyModal}
              onOk={formLicenseCopy.submit}
              onCancel={() => {
                setShowLicenseCopyModal(!showLicenseCopyModal);
              }}
              centered
            >
              <Form
                name="uploadLicenseCopy"
                form={formLicenseCopy}
                onFinish={handleLicenseCopySubmit}
              >

                <Item
                  label="File Name"
                  name="fileName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the file name",
                    },
                  ]}
                >
                  <Input />
                </Item>
                <Item
                  label="File"
                  name="file"
                  rules={[
                    {
                      required: true,
                      message: "Please upload a file",
                    },
                  ]}
                >
                  <Upload
                    Upload
                    name="productPhoto"
                    accept=".pdf"
                    beforeUpload={handleFileUpload}
                    multiple={false}
                  >
                    <Button>Click to Upload</Button>
                  </Upload>
                </Item>
              </Form>
            </Modal>
            <Image
              preview={{
                visible,
                src: img,
                onVisibleChange: (value) => {
                  setVisible(value);
                },
              }}
            />
          </Card>

          <Card
            title="Systems"
            extra={
              <Button
                type="primary"
                onClick={() => setShowSystemModal(true)}
                ghost
              >
                Add Systems
              </Button>
            }
            style={{ marginBottom: "20px" }}
          >
            <Table columns={columns} dataSource={systems} size="middle" />
            <Modal
              title="Add System"
              open={showSystemModal}
              onOk={() => handleSystemOk(false)}
              onCancel={handleSystemCancel}
              centered
              okText="Add"
            >
              <Form
                form={systemForm}
                layout="vertical"
                onFinish={(value) => console.log(value)}
              >
                <Form.Item
                  name="name"
                  label="Enter Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter name",
                    },
                  ]}
                >
                  <Input placeholder="e.g. Hydrant" tabIndex={51} />
                </Form.Item>
                <Item label="Data" name="data" hidden></Item>

                <Form.Item
                  name="description"
                  label="Enter Description"
                  rules={[
                    {
                      required: true,
                      message: "Please enter description",
                    },
                  ]}
                >
                  <TextArea
                    placeholder="e.g. Highly durable hydrant"
                    tabIndex={52}
                  />
                </Form.Item>
              </Form>
            </Modal>

            <Modal
              title="Edit System"
              open={showSystemEditModal}
              onOk={() => handleSystemOk(true)}
              onCancel={handleSystemCancel}
              centered
              okText="Save"
            >
              <Form
                form={systemForm}
                layout="vertical"
                onFinish={(value) => console.log(value)}
              >
                <Form.Item
                  name="name"
                  label="Enter Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter name",
                    },
                  ]}
                >
                  <Input placeholder="e.g. Hydrant" tabIndex={51} />
                </Form.Item>
                <Item label="Data" name="data" hidden></Item>

                <Form.Item
                  name="description"
                  label="Enter Description"
                  rules={[
                    {
                      required: true,
                      message: "Please enter description",
                    },
                  ]}
                >
                  <TextArea
                    placeholder="e.g. Highly durable hydrant"
                    tabIndex={52}
                  />
                </Form.Item>
              </Form>
            </Modal>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Settings;
