import React, { useEffect, useState } from "react";
import { LuPlus } from "react-icons/lu";
// import PrintUtility from "../../utils/Print";
import {
  Row,
  Card,
  Col,
  Table,
  Space,
  Button,
  message,
  Modal,
  Dropdown,
  Input,
} from "antd";

import { Link } from "react-router-dom";
import APICall from "../../services/apiservices";
import moment from "moment";
const { confirm } = Modal;
const { Search } = Input;

const PurchaseOrders = () => {
  const [print, setPrint] = useState();
  const [pos, setPOs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tableParams, setTableParams] = useState({
    filters: {
      searchTerm: "",
    },
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
  });

  // useEffect(() => {
  //   getPOs();
  // }, [])

  const fetchList = async () => {
    // setLoading(true);
    const page = tableParams.pagination.current;
    const limit = tableParams.pagination.pageSize;
    const searchTerm = tableParams.filters.searchTerm || "";
    const response = await getPOs(searchTerm, page, limit);
    //console.log(response)
    // console.log(response)
    setPOs(response.documents);
    setIsLoading(false);

    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: response.total,
      },
    });
  };

  useEffect(() => {
    fetchList();
  }, [JSON.stringify(tableParams)]);

  const getPOs = async (searchTerm, page, limit) => {
    try {
      if (searchTerm === undefined) searchTerm = "";
      const response = await APICall(
        `/purchaseOrder?page=${page}&limit=${limit}&query=${searchTerm}`,
        {},
        1
      );
      return response.data;
    } catch (error) {
      message.error("failed to get PO data");
      setIsLoading(false);
    }
  };

  const handleDelete = async (record) => {
    confirm({
      title: "Confirm Delete",
      content: `Are you sure you want to delete?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        // Delete logic goes here
        const res = await APICall(`/amc/${record._id}`, {}, 4);
        fetchList();
        message.success("AMC deleted successfully!");
      },
      onCancel: () => {
        message.info("Delete canceled");
      },
    });
  };

  const handlePrint = (record) => {
    setPrint(record);
  };

  // Table columns
  const columns = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => {
        return (
          tableParams.pagination.pageSize *
            (tableParams.pagination.current - 1) +
          index +
          1
        );
      },
    },
    {
      title: "PO Number",
      dataIndex: "poNumber",
      key: "poNumber",
    },
    {
      title: "Customer",
      dataIndex: ["customer", "user", "name"],
      key: "customer",
    },
    {
      title: "Timeline (Months)",
      dataIndex: "timeline",
      key: "timeline",
    },
    {
      title: "Systems",
      dataIndex: "systems",
      key: "systems",
      render: (_, record) => {
        return record.systems.join(', ')
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Date",
      dataIndex: "poDate",
      key: "Date",
      render: (text, record) => moment(text).format("DD/MM/YYYY"),
    },
    {
      title: "Attachment",
      dataIndex: "file",
      key: "file",
      render: (_, record) => <span><a href={record?.file?.url}>Download</a></span>,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        // <Dropdown
        //   menu={{
        //     items: [
        //       {
        //         key: 1,
        //         label: <Link to={`/pos/details/${record._id}`}>View</Link>,
        //       },
        //       {
        //         key: 2,
        //         label: <Link to={`/pos/manage/${record._id}`}>Edit</Link>,
        //       },
        //       {
        //         key: 3,
        //         label: <Link to={`/pos/${record._id}`}>Export</Link>,
        //       },
        //       {
        //         key: 4,
        //         label: (
        //           <span
        //             style={{ color: "red" }}
        //             onClick={() => handleDelete(record)}
        //           >
        //             Delete
        //           </span>
        //         ),
        //       },
        //     ],
        //   }}
        //   placement="bottom"
        // >
        //   <Button type="link">Actions</Button>
        // </Dropdown>

        <Link to={`/purchaseOrder/details/${record._id}`}>
          <Button type="primary" ghost>
            View
          </Button>
        </Link>
      ),
      align: "center",
      fixed: "right",
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setPOs([]);
    }
  };

  const onSearch = (value) => {
    setTableParams({
      ...tableParams,
      filters: {
        ...tableParams.filters,
        searchTerm: value,
      },
    });
  };

  return (
    <Row>
      <Col span={24}>
        <Card
          title="Purchase Order Management"
          extra={
            <Row justify={"end"} align="top" style={{ paddingBottom: "10px" }}>
              <Space>
                <Search
                  style={{
                    width: 220,
                  }}
                  placeholder="Search by customer"
                  allowClear
                  enterButton
                  // loading={loading}
                  size="middle"
                  onSearch={onSearch}
                />
                <Link to="/purchaseOrder/manage">
                  <Button
                    type="primary"
                    icon={<LuPlus style={{ paddingTop: "2px" }} />}
                  >
                    Create PO
                  </Button>
                </Link>
              </Space>
            </Row>
          }
        >
          <Table
            dataSource={pos}
            columns={columns}
            loading={isLoading}
            onChange={handleTableChange}
            pagination={tableParams.pagination}
            scroll={{ x: "1000px" }}
            size="small"
          />
        </Card>
      </Col>
      {/* {print && (
        <Modal open={print} onCancel={() => setPrint(null)} width={"100%"}>
          <PrintUtility data={print} />
        </Modal>
      )} */}
    </Row>
  );
};

export default PurchaseOrders;
