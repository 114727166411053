import React, { useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Tag,
  message,
  Modal,
  Dropdown,
  Input,
  Form,
  Select,
  DatePicker,
  Space,
} from "antd";
// import { LuPlus } from "react-icons/lu";
import { Link } from "react-router-dom";

import DebounceSelect from "../../components/debounceSelect/DebounceSelect";
import { useState } from "react";
import downloadXLSX from "./survey/export";
import downloadFireExtinguisherXLSX from "./survey/fireExtExport";
import APICall from "../../services/apiservices";
import dayjs from "dayjs";
import moment from "moment/moment";
const { Search } = Input;
const userData = JSON.parse(localStorage.getItem("userData"));

const Surveys = ({ completed }) => {
  const [createSurvey, setCreateSurvey] = React.useState(false);
  const [editSurvey, setEditSurvey] = React.useState(false);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [surveyId, setSurveyId] = React.useState(null);
  const [surveyData, setSurveyData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [surveys, setSurveys] = useState([]);
  const [tableParams, setTableParams] = useState({
    filters: {
      searchTerm: "",
    },
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
  });

  const getSurvey = async (searchTerm, page, limit) => {
    try {
      if (searchTerm === undefined) searchTerm = "";
      const response = await APICall(
        `/survey?page=${page}&limit=${limit}&query=${searchTerm}&isConducted=${
          completed ? true : false
        }&employeeId=${userData.employee._id}`,
        {},
        1
      );
      return response.data.data;
    } catch (error) {
      message.error("Failed to fetch survey details");
      setIsLoading(false);
    }
  };

  const fetchList = async () => {
    setIsLoading(true);
    const page = tableParams.pagination.current;
    const limit = tableParams.pagination.pageSize;
    const searchTerm = tableParams.filters.searchTerm || "";
    const response = await getSurvey(searchTerm, page, limit);

    //sort by date  in descending order
    setSurveys(
      response?.documents.sort((a, b) => {
        return (
          new Date(completed ? b.surveyDate : a.surveyDate) -
          new Date(completed ? a.surveyDate : b.surveyDate)
        );
      })
    );
    setIsLoading(false);

    setTableParams(prev => ({
      ...prev,
      pagination: {
          ...prev.pagination,
          total: response.total,
          current: page,
      },
    }));
  };

  useEffect(() => {
    fetchList();
  }, [JSON.stringify(tableParams), completed]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setSurveys([]);
    }
  };

  const onSearch = (value) => {
    setTableParams(prev => ({
      filters: {
          searchTerm: value,
      },
      pagination: {
          ...prev.pagination,
          current: 1,
      },
  }));
  };

  const handleVisibleChange = () => {
    handleDelete(surveyId);
    setModalVisible(false);
  };

  const onDelete = (id) => {
    setModalVisible(true);
    setSurveyId(id);
  };

  const surveysD = [
    {
      _id: "1",
      surveyNo: "SN0010",
      type: "Industrial",
      customer: {
        customerCode: "C001",
        user: {
          name: "Aundh IT Park",
        },
      },
      surveyDate: "2021-08-01",
    },
    {
      _id: "2",
      surveyNo: "SN0009",
      type: "Residential",
      customer: {
        customerCode: "S001",
        user: {
          name: "Suryodaya Society",
        },
      },
      surveyDate: "2021-08-02",
    },
  ];
  const completedSurveys = [
    {
      _id: "1",
      surveyNo: "SN0010",
      type: "Industrial",
      customer: {
        customerCode: "C001",
        user: {
          name: "Aundh IT Park",
        },
      },
      surveyDate: "2021-08-01",
    },
    {
      _id: "2",
      surveyNo: "SN0009",
      type: "Residential",
      customer: {
        customerCode: "S001",
        user: {
          name: "Suryodaya Society",
        },
      },
      surveyDate: "2021-08-02",
    },
    {
      _id: "2",
      surveyNo: "SN0009",
      type: "Residential",
      customer: {
        customerCode: "S001",
        user: {
          name: "Suryodaya Society",
        },
      },
      surveyDate: "2021-08-02",
    },
    {
      _id: "2",
      surveyNo: "SN0009",
      type: "Residential",
      customer: {
        customerCode: "S001",
        user: {
          name: "Suryodaya Society",
        },
      },
      surveyDate: "2021-08-02",
    },
    {
      _id: "2",
      surveyNo: "SN0009",
      type: "Residential",
      customer: {
        customerCode: "S001",
        user: {
          name: "Suryodaya Society",
        },
      },
      surveyDate: "2021-08-02",
    },
  ];

  const handleDelete = (id) => {
    setSurveyData(surveys.filter((item) => item._id !== id));
    message.success("Survey deleted successfully");
  };

  const requirementsArray = {
    adaptor: {
      name: "Adaptor",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hoseBox: {
      name: "Hose Box",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    branchPipe: {
      name: "Branch Pipe",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hosePipe: {
      name: "Hose Pipe",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hydrantValve: {
      name: "Hydrant Valve",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hoseReel: {
      name: "Hose Reel",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    fireExtinguishers: {
      name: "Fire Extinguisher",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hoseBoxGlass: {
      name: "Hose Box Glass",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    keyGlass: {
      name: "Key Glass",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hydrantValveWheel: {
      name: "Hydrant Valve Wheel",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hoseReelBallValve: {
      name: "Hose Reel Ball Valve",
      existing: 0,
      good: 0,
      requirement: 0,
    },
  };

  const exportSurvey = async (surveyId) => {
    try {
      const res = await APICall(`/survey/${surveyId}/conducted`, {}, 1);
      console.log(res);
      if (!res?.data?.survey?.isFireExtinguisher) {
        const finalRequirements = JSON.parse(JSON.stringify(requirementsArray));
        const exportData = {
          organization: {
            name: "Fire Fight Safety Solutions",
            address:
              "Sr. No.85, Office No.7A/204, Kalpataru Estate, Jawalkar Nagar,Pimple Gurav, Pune - 411061",
            mobile: "9970548822",
            email: "info@ffss.in",
          },
          customer: {
            name: res?.data?.survey?.customer?.user?.name,
          },
          employee: {
            name: res?.data?.survey?.employee?.user?.name,
          },
          date: moment(res?.data?.survey?.surveyDate).format("DD/MM/YYYY"),
          buildings: res.data.surveyBuildings.map((sb) => {
            const areas = sb?.newFloors.map((nf, index) => ({
              area: nf?.floor.name,
              srNo: index + 1,
              equipments: nf?.equipments ?? {
                adaptor: "NA",
                hoseBox: "NA",
                branchPipe: "NA",
                hosePipe: "NA",
                hydrantValve: "NA",
                hoseReel: "NA",
                fireExtinguishers: "NA",
                hoseBoxGlass: "NA",
                keyGlass: "NA",
                hydrantValveWheel: "NA",
                hoseReelBallValve: "NA",
              },
            }));
            const requirements = JSON.parse(JSON.stringify(requirementsArray));
            areas.map((area) => {
              Object.keys(area.equipments).map((k) => {
                if (area.equipments[k] == "OK") {
                  requirements[k].existing = requirements[k].existing + 1;
                  requirements[k].good = requirements[k].good + 1;
                  finalRequirements[k].existing =
                    finalRequirements[k].existing + 1;
                  finalRequirements[k].good = finalRequirements[k].good + 1;
                } else if (area.equipments[k] == "Not OK") {
                  requirements[k].existing = requirements[k].existing + 1;
                  requirements[k].requirement = requirements[k].requirement + 1;
                  finalRequirements[k].existing =
                    finalRequirements[k].existing + 1;
                  finalRequirements[k].requirement =
                    finalRequirements[k].requirement + 1;
                }
              });
            });
            return {
              building: sb?.building?.name,
              notes: sb?.information?.notes ?? "",
              inCharge: sb?.information?.inCharge ?? "",
              areas,
              requirements,
            };
          }),
        };
        exportData.requirements = finalRequirements;
        downloadXLSX(exportData, "Survey");
      } else {
        console.log(res);
        const exportData = {
          organization: {
            name: "Fire Fight Safety Solutions",
            address:
              "Sr. No.85, Office No.7A/204, Kalpataru Estate, Jawalkar Nagar,Pimple Gurav, Pune - 411061",
            mobile: "9970548822",
            email: "info@ffss.in",
          },
          customer: {
            name: res?.data?.survey?.customer?.user?.name,
          },
          employee: {
            name: res?.data?.survey?.employee?.user?.name,
          },
          date: moment(res?.data?.survey?.surveyDate).format("DD/MM/YYYY"),

          buildings: res.data.surveyBuildings.map((sb) => {
            const areas = sb?.newFloors.map((floor, index) => ({
              srNo: index + 1,
              area: floor?.floor.name,
              fireExtinguisherInfo: {
                type: floor?.fireExtinguisherInfo?.type ?? "NA",
                capacity: floor?.fireExtinguisherInfo?.capacity ?? "NA",
                pressure: floor?.fireExtinguisherInfo?.pressure ?? "NA",
                weight: floor?.fireExtinguisherInfo?.weight ?? "NA",
                serialNo: floor?.fireExtinguisherInfo?.serialNo ?? "NA",
                installationRefillingDate: floor?.fireExtinguisherInfo
                  ?.installationRefillingDate
                  ? moment(
                      floor?.fireExtinguisherInfo?.installationRefillingDate
                    ).format("DD/MM/YYYY")
                  : "NA",
                refillingDueDate: floor?.fireExtinguisherInfo?.refillingDueDate
                  ? moment(
                      floor?.fireExtinguisherInfo?.refillingDueDate
                    ).format("DD/MM/YYYY")
                  : "NA",
                freeAccess: floor?.fireExtinguisherInfo?.freeAccess ?? "NA",
                identificationTag:
                  floor?.fireExtinguisherInfo?.identificationTag ?? "NA",
                forABCPressureGuage:
                  floor?.fireExtinguisherInfo?.forABCPressureGuage ?? "NA",
                hoseTightFitting:
                  floor?.fireExtinguisherInfo?.hoseTightFitting ?? "NA",
                isSafetyPinGood:
                  floor?.fireExtinguisherInfo?.isSafetyPinGood ?? "NA",
                forCO2HoseCondition:
                  floor?.fireExtinguisherInfo?.forCO2HoseCondition ?? "NA",
                forCO2HornHandle:
                  floor?.fireExtinguisherInfo?.forCO2HornHandle ?? "NA",
                isFEOK: floor?.fireExtinguisherInfo?.isFEOK ?? "NA",
                remark: floor?.fireExtinguisherInfo?.remark ?? "NA",
              },
            }));
            return {
              building: sb?.building?.name,
              notes: sb?.information?.notes ?? "",
              inCharge: sb?.information?.inCharge ?? "",
              areas,
            };
          }),
        };

        downloadFireExtinguisherXLSX(exportData, "Survey");
      }
    } catch (error) {
      message.error(error);
    }
  };

  const columns = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => {
        return (
          tableParams.pagination.pageSize *
            (tableParams.pagination.current - 1) +
          index +
          1
        );
      },
    },
    {
      title: "Survey No.",
      key: "number",
      render: (_, record) => {
        return "SN" + record.surveyNo.toString().padStart(4, "0");
      },
    },
    {
      title: "Type",
      key: "type",
      render: (_, record) => {
        return (
          <Tag color={record.type === "Industrial" ? "blue" : "green"}>
            {record.type}
          </Tag>
        );
      },
    },
    {
      title: "Customer",
      key: "customer",
      render: (_, record) => {
        return record.customer.user.name;
      },
    },
    {
      title: "Survey Date",
      key: "date",
      render: (_, record) => {
        return dayjs(record.surveyDate).format("DD/MM/YYYY");
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) =>
        completed ? (
          // <Dropdown
          //   align="center"
          //   menu={{
          //     items: [
          //       {
          //         key: 2,
          //         label: (
          //           <Link
          //             to={`/survey/${record._id}/buildings?customerId=${record.customerId}&customerName=${record.customer.user.name}&pagename=Surveys`}
          //           >
          //             Edit
          //           </Link>
          //         ),
          //       },
          //       {
          //         key: 1,
          //         label: "Export Survey",
          //         onClick: () => {
          //           exportSurvey(record._id);
          //         },
          //       },
          //     ],
          //   }}
          // >
          //   <Button type="link">Actions</Button>
          // </Dropdown>
          <Space>
            <Link
              to={`/survey/${record._id}/buildings?customerId=${record.customerId}&customerName=${record.customer.user.name}&pagename=Surveys`}
            >
              <Button type="primary" size="small" ghost>
                Edit
              </Button>
            </Link>
            <Button
              type="primary"
              size="small"
              ghost
              onClick={() => exportSurvey(record._id)}
            >
              Export
            </Button>
          </Space>
        ) : (
          <Link
            to={`/survey/${record._id}/buildings?customerId=${record.customerId}&customerName=${record.customer.user.name}&pagename=Surveys`}
          >
            <Button type="link">Conduct</Button>
          </Link>
        ),
      align: "center",
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Card
          title={completed ? "Completed Surveys" : "Assigned Surveys"}
          extra={
            <Search
              style={{
                width: 220,
              }}
              placeholder="Search by customer"
              allowClear
              size="middle"
              onSearch={onSearch}
              enterButton
            />
          }
        >
          <Table
            columns={columns}
            dataSource={surveys}
            size="small"
            onChange={handleTableChange}
            pagination={tableParams.pagination}
            loading={isLoading}
            scroll={{ x: "1000px" }}
          />
        </Card>
      </Col>

      <Modal
        title="Confirm Action"
        open={modalVisible}
        okText="Delete"
        cancelText="Cancel"
        onOk={() => {
          handleVisibleChange(surveyId);
        }}
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <p>Are you sure you want to delete the Survey?</p>
      </Modal>

      <Modal
        title="Create Survey"
        okText="Create"
        cancelText="Cancel"
        open={createSurvey}
        // onCancel={() => {
        // }}
      >
        <Form layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="customerId"
                label="Customer"
                rules={[
                  {
                    required: true,
                    message: "Please select a customer!",
                  },
                ]}
              >
                <DebounceSelect
                  mode="default"
                  placeholder="Select Customer"
                  // fetchOptions={fetchCustomer}
                  debounceTimeout={800}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="employeeId"
                label="Employee"
                rules={[
                  {
                    required: true,
                    message: "Please select an employee!",
                  },
                ]}
              >
                <DebounceSelect
                  mode="default"
                  placeholder="Select Employee"
                  // fetchOptions={fetchEmployee}
                  debounceTimeout={800}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="type"
                label="Type"
                rules={[
                  {
                    required: true,
                    message: "Please select a type!",
                  },
                ]}
              >
                <Select placeholder="Select Type">
                  <Select.Option value="industrial">Industrial</Select.Option>
                  <Select.Option value="residential">Residential</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="surveyDate"
                label="Survey Date"
                rules={[
                  {
                    required: true,
                    message: "Please select a date!",
                  },
                ]}
              >
                <DatePicker format={'DD-MM-YYYY'} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      {/* Edit Model ... */}
      <Modal
        title="Edit Survey"
        open={editSurvey}
        okText={"Save Survey"}
        onCancel={() => {}}
      >
        <Form
          okText="Save"
          layout="vertical"
          onCancel={() => {
            setEditSurvey(!editSurvey);
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="customerId"
                label="Customer"
                rules={[
                  {
                    required: true,
                    message: "Please select customer",
                  },
                ]}
              >
                <DebounceSelect
                  placeholder="Select Customer"
                  onChange={(newValue) => {}}
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item
                name="employeeId"
                label="Employee"
                rules={[
                  {
                    required: true,
                    message: "Please select employee",
                  },
                ]}
              >
                <DebounceSelect
                  placeholder="Select Employee"
                  onChange={(newValue) => {
                    // setEmployee(newValue);
                  }}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="type"
                label="Type"
                rules={[
                  {
                    required: true,
                    message: "Please select the type",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select Type"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={[
                    {
                      value: "industrial",
                      label: "Industrial",
                    },
                    {
                      value: "residential",
                      label: "Residential",
                    },
                  ]}
                  tabIndex={1}
                />
              </Form.Item>

              <Form.Item
                name="surveyDate"
                label="Date"
                rules={[
                  {
                    required: true,
                    message: "Please select date",
                  },
                ]}
              >
                <DatePicker
                  format={'DD-MM-YYYY'}
                  // disabledDate={disabledDate}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Row>
  );
};

export default Surveys;
