import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Col, Row, Table, message, Input, Space } from "antd";
import APICall from "../../services/apiservices";
import { LuPlus } from "react-icons/lu";

const { Search } = Input;

const BOQ = () => {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tableParams, setTableParams] = useState({
    filters: {
      searchTerm: "",
    },
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
  });

  const getItems = async (searchTerm, page, limit) => {
    try {
      if (!searchTerm) searchTerm = ""; // Default search term if not provided
      const response = await APICall(
        `/boq?page=${page}&limit=${limit}&searchTerm=${searchTerm}`,
        {},
        1
      );
      return response.data;
    } catch (error) {
      message.error("Failed to fetch BOQ details");
      setIsLoading(false);
    }
  };

  const getCustomerName = async (customerId) => {
    try {
      const response = await APICall(`/customer/${customerId}`, {}, 1);
      return response.data.user.name;
    } catch (error) {
      message.error("Failed to fetch customer details");
      return null;
    }
  };
  
  const fetchList = async () => {
    const { current, pageSize } = tableParams.pagination;
    const searchTerm = tableParams.filters.searchTerm || "";
    const response = await getItems(searchTerm, current, pageSize);

    const itemsWithCustomerNames = await Promise.all(
      response?.documents.map(async (item) => {
        const customerName = await getCustomerName(item.customerId);
        return { ...item, customerName };
      })
    );

    setItems(itemsWithCustomerNames || []);
    setIsLoading(false);
    setTableParams((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        total: response?.total || 0,
      },
    }));
  };

  useEffect(() => {
    fetchList();
  }, [JSON.stringify(tableParams)]);

  const columns = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, __, index) => {
        return (
          tableParams.pagination.pageSize * (tableParams.pagination.current - 1) +
          index +
          1
        );
      },
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      render: (text) => text || "Unknown",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Link to={`/boq/details/${record._id}`}>
          <Button type="primary" ghost>
            View
          </Button>
        </Link>
      ),
      align: "center",
    },
  ];

  const handleTableChange = (pagination) => {
    setTableParams({
      ...tableParams,
      pagination,
    });

    if (pagination.pageSize !== tableParams.pagination.pageSize) {
      setItems([]);
    }
  };

  const onSearch = (value) => {
    setTableParams({
      ...tableParams,
      filters: {
        ...tableParams.filters,
        searchTerm: value,
      },
      pagination: {
        ...tableParams.pagination,
        current: 1, // Reset to the first page on a new search
      },
    });
  };

  return (
    <Row>
      <Col span={24}>
        <Card
          title="BOQ"
          extra={
            <Row justify={"end"} align="top" style={{ paddingBottom: "10px" }}>
              <Space>
                <Search
                  style={{
                    width: 220,
                  }}
                  placeholder="Search an boq"
                  allowClear
                  enterButton
                  // loading={loading}
                  size="middle"
                  
                />
                <Link to="/boq/manage">
                  <Button
                    type="primary"
                    icon={<LuPlus style={{ paddingTop: "2px" }} />}
                  >
                    Create BOQ
                  </Button>
                </Link>
              </Space>
            </Row>
          }
        >
          <Table
            loading={isLoading}
            columns={columns}
            dataSource={items}
            onChange={handleTableChange}
            size="small"
            pagination={tableParams.pagination}
            scroll={{ x: "1000px" }}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default BOQ;
