import React, { useEffect, useState } from "react";
import { GoInfo } from "react-icons/go";
import { VscTools } from "react-icons/vsc";
import { RiSurveyLine } from "react-icons/ri";
import { LuTimer, LuUser } from "react-icons/lu";
import { AiOutlineCalendar } from "react-icons/ai";
import {
  Card,
  Col,
  Row,
  Input,
  Radio,
  Space,
  Tooltip,
  Statistic,
  Table,
  Form,
  Typography,
  Button,
  message,
  Modal,
  InputNumber,
  Progress,
  Tag,
} from "antd";

import { Link, Navigate, useNavigate } from "react-router-dom";
import CountUp from "react-countup";
import dayjs from "dayjs";
import APICall from "../../services/apiservices";
import moment from "moment/moment";

const { TextArea } = Input;

const Dashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState();

  const formatter = (value) => <CountUp end={value} separator="," />;

  useEffect(() => {
    getDashboardData();
  }, []);

  const getDashboardData = async () => {
    try {
      const userData = JSON.parse(localStorage.getItem("userData"));
      const response = await APICall(
        `/customer/${userData.customer._id}/dashboard        `,
        {},
        1
      );

      setDashboardData(response.data.data);
      setLoading(false);
    } catch (error) {
      message.error(error);
    }
  };

  const visitDel = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Scheduled Date",
      key: "scheduledDate",
      render: (record) => {
        return moment(record.scheduledDate).format("DD/MM/YYYY");
      },
    },
    {
      title: "Status",
      key: "status",
      render: (record) => {
        return <Tag color="red">Pending</Tag>;
      },
    },
  ];

  const surveyCol = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Customer",
      key: "customer",
      render: (_, record) => {
        return record.customerId.userId.name;
      },
    },
    {
      title: "Survey Date",
      dataIndex: "surveyDate",
      key: "surveyDate",
      render: (_, record) => moment(record.surveyDate).format("DD/MM/YYYY"),
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <Typography.Title level={5} style={{ margin: 0, color: "#024fa1" }}>
            Welcome to
          </Typography.Title>
        </Col>
        <Col span={24}>
          <Typography.Title level={4} type="danger">
            Fire Fight Safety Solutions
          </Typography.Title>
        </Col>
      </Row>

      <Row gutter={10}>
        <Col
          className="gutter-row"
          span={6}
          onClick={() => {
            navigate("/surveys");
          }}
        >
          <Card
            bordered={false}
            loading={loading}
            style={{
              cursor: "pointer",
            }}
          >
            <Statistic
              prefix={
                <AiOutlineCalendar
                  style={{ fontSize: "20px", color: "#024fa1" }}
                />
              }
              precision={2}
              formatter={formatter}
              loading={loading}
              title={
                <>
                  <Row justify="space-between">
                    <Col>Survey Conducted at</Col>
                    <Col>
                      <Tooltip title="Survey coducted at">
                        <GoInfo style={{ fontSize: "20px" }} />
                      </Tooltip>
                    </Col>
                  </Row>
                </>
              }
              valueStyle={{ fontWeight: 500, fontSize: "20px" }}
              valueRender={() => {
                return moment(dashboardData?.customerSurvey?.surveyDate).format(
                  "DD/MM/YYYY"
                );
              }}
            />
          </Card>
        </Col>
        <Col className="gutter-row" span={6} onClick={() => navigate("/amcs")}>
          <Card
            bordered={false}
            style={{
              cursor: "pointer",
            }}
            loading={loading}
          >
            <Statistic
              prefix={
                <VscTools style={{ fontSize: "20px", color: "#024fa1" }} />
              }
              suffix={"days"}
              precision={2}
              formatter={formatter}
              loading={loading}
              title={
                <>
                  <Row justify="space-between">
                    <Col>AMC Expiring in</Col>
                    <Col>
                      <Tooltip title="AMC will get expired in given days">
                        <GoInfo style={{ fontSize: "20px" }} />
                      </Tooltip>
                    </Col>
                  </Row>
                </>
              }
              valueStyle={{ fontWeight: 500, fontSize: "20px" }}
              value={dashboardData?.daysToExpiry}
            />
          </Card>
        </Col>

        <Col
          className="gutter-row"
          span={6}
          onClick={() => {
            navigate("/amcs");
          }}
        >
          <Card
            bordered={false}
            loading={loading}
            style={{
              cursor: "pointer",
            }}
          >
            <Statistic
              prefix={
                <RiSurveyLine style={{ fontSize: "20px", color: "#024fa1" }} />
              }
              precision={2}
              formatter={formatter}
              loading={loading}
              title={
                <>
                  <Row justify="space-between">
                    <Col>Visits in current AMC</Col>
                    <Col>
                      <Tooltip title="Visits remaining in currunt AMC.">
                        <GoInfo style={{ fontSize: "20px" }} />
                      </Tooltip>
                    </Col>
                  </Row>
                </>
              }
              valueStyle={{ fontWeight: 500, fontSize: "20px" }}
              value={dashboardData?.visitsInActiveAMCCount}
            />

            {/* <Typography.Text>
              This month sales {data.customers.thisMonthSales}
            </Typography.Text> */}
          </Card>
        </Col>
        <Col className="gutter-row" span={6}></Col>
      </Row>

      <Row gutter={10} style={{ marginTop: "1rem" }}>
        <Col span={18}>
          <Card
            title="Scheduled Visits "
            style={{
              width: "100%",
            }}
            loading={loading}
          >
            <Table
              columns={visitDel}
              loading={loading}
              dataSource={dashboardData?.activeAMC?.visits.filter(
                (item) => item.isConducted === false
              )}
              pagination={false}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
