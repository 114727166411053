import React, { useEffect, useState } from "react";
import { LuPlus } from "react-icons/lu";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Tag,
  message,
  Modal,
  Dropdown,
  Spin,
  Space,
  Input,
  Form,
  Select,
  DatePicker,
} from "antd";
import { Link, useParams } from "react-router-dom";
import APICall from "../../services/apiservices";
import moment from "moment";
import DebounceSelect from "../../components/debounceSelect/DebounceSelect";
import dayjs from "dayjs";
import downloadXLSX from "../../employee/features/survey/export";
const { confirm } = Modal;
const { Search } = Input;

const Surveys = ({ conducted }) => {
  const [form] = Form.useForm();
  const params = useParams();
  const [surveys, setSurveys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEdit, setIsEdit] = useState();
  const [tableParams, setTableParams] = useState({
    filters: {
      searchTerm: "",
    },
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
  });
  const [createSurvey, setCreateSurvey] = useState(false);
  const [editSurvey, setEditSurvey] = useState(false);
  const [editingSurveyId, setEditingSurveyId] = useState();
  const [initialCustomer, setInitialCustomer] = useState();
  const [initialEmployee, setInitialEmployee] = useState();
  const [customer, setCustomer] = useState(undefined);
  const [employee, setEmployee] = useState(undefined);

  const fetchCustomerList = async (searchValue, initialValues) => {
    if (initialValues) {
      return initialValues.map((i) => ({
        label: i.user.name,
        value: i._id,
      }));
    }
    const res = await APICall(
      `/customer?page=1&limit=30&query=${searchValue}`,
      {},
      1
    );
    return res.data.documents.map((customer) => ({
      label: customer.user.name,
      value: customer._id,
    }));
  };

  const fetchEmployeeList = async (searchValue) => {
    const res = await APICall(
      `/employee?page=1&limit=30&query=${searchValue}`,
      {},
      1
    );
    return res.data.documents.map((employee) => ({
      label: employee.user.name,
      value: employee._id,
    }));
  };

  const handleDelete = (record) => {
    confirm({
      title: "Confirm Delete",
      content: `Are you sure you want to delete survey?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          const response = await APICall(`/survey/${record._id}`, {}, 4);
          if (response.data) {
            message.success("Survey deleted successfully!");
          }
          fetchList();
        } catch (error) {
          message.error(error.response.data.message);
        }
      },
    });
  };

  const handleCreateSurvey = async (formData) => {
    setIsLoading(!isLoading);
    {
      try {
        const res = await APICall("/survey/number", {}, 1);
        const response = await APICall(
          `/survey`,
          { ...formData, surveyNo: res.data.data },
          2
        );
        if (response?.data) {
          fetchList();
          setCreateSurvey(false);
          form.resetFields();
          message.success("Survey has been created successfully.");
        }
      } catch (error) {
        message.error(error.response.data.message);
        form.resetFields();
        setCreateSurvey(false);
      }
    }
  };

  const handleEditSurvey = async () => {
    const formData = form.getFieldsValue();
    setIsLoading(!isLoading);

    if (!(typeof formData.customerId === "string")) {
      formData.customerId = formData.customerId.value;
    }

    if (!(typeof formData.employeeId === "string")) {
      formData.employeeId = formData.employeeId.value;
    }

    try {
      const response = await APICall(`/survey/${editingSurveyId}`, formData, 3);
      if (response?.data) {
        message.success("Survey has been updated successfully.");
        fetchList();
        setEditSurvey(false);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const getSurvey = async (searchTerm, page, limit) => {
    try {
      if (searchTerm === undefined) searchTerm = "";
      const response = await APICall(
        `/survey?isConducted=${
          conducted ? true : false
        }&page=${page}&limit=${limit}&query=${searchTerm}`,
        {},
        1
      );
      return response.data.data;
    } catch (error) {
      message.error("Failed to fetch survey details");
      setIsLoading(false);
    }
  };

  const fetchList = async () => {
    const page = tableParams.pagination.current;
    const limit = tableParams.pagination.pageSize;
    const searchTerm = tableParams.filters.searchTerm || "";
    const response = await getSurvey(searchTerm, page, limit);

    setSurveys(response.documents);
    setIsLoading(false);

    setTableParams((prevParams) => ({
      ...prevParams,
      pagination: {
        ...prevParams.pagination,
        total: response.total,
        current: page
      },
    }));
  };

  useEffect(() => {
    fetchList();
  }, [JSON.stringify(tableParams), conducted]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setSurveys([]);
    }
  };

  function disabledDate(current) {
    const now = moment();
    return current && current < now.startOf("day");
  }

  const onSearch = (value) => {
    setTableParams(prev => ({
      filters: {
          searchTerm: value,
      },
      pagination: {
          ...prev.pagination,
          current: 1,
      },
    }));
  };

  const columns = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => {
        return (
          tableParams.pagination.pageSize *
            (tableParams.pagination.current - 1) +
          index +
          1
        );
      },
    },
    {
      title: "Survey No.",
      key: "number",
      render: (_, record) => {
        return "SN" + record.surveyNo.toString().padStart(4, "0");
      },
    },
    {
      title: "Type",
      key: "type",
      render: (_, record) => {
        return record.type === "industrial" ? (
          <Tag color="green">Industrial</Tag>
        ) : (
          <Tag color="magenta">Residential</Tag>
        );
      },
    },
    {
      title: "Customer",
      key: "customer",
      render: (_, record) => {
        return record.customer?.user?.name;
      },
    },
    {
      title: "Survey Date",
      key: "date",
      render: (_, record) => {
        return moment(record.surveyDate).format("DD/MM/YYYY");
      },
    },
    {
      title: "Assigned To",
      key: "conductor",
      render: (_, record) => {
        return record.employee?.user?.name;
      },
    },

    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        // <Dropdown
        //   menu={{
        //     items: [
        //       {
        //         key: 1,
        //         label: <Link to={`/surveys/${record._id}`}>View</Link>,
        //       },
        //       {
        //         key: 2,
        //         label: "Edit",
        //         onClick: () => {
        //           setEditingSurveyId(record._id);
        //           // setIsEdit(true);
        //           setInitialCustomer({
        //             label: record.customer.user.name,
        //             value: record.customer._id,
        //           });

        //           setInitialEmployee({
        //             label: record.employee.user.name,
        //             value: record.employee._id,
        //           });

        //           form.setFieldsValue({
        //             type: record.type,
        //             surveyDate: dayjs(record.surveyDate),
        //             customerId: {
        //               label: record.customer.user.name,
        //               value: record.customer._id,
        //             },
        //             employeeId: {
        //               label: record.employee.user.name,
        //               value: record.employee._id,
        //             },
        //           });
        //           setEditSurvey(true);
        //         },
        //       },

        //       {
        //         key: 3,
        //         label: "Delete",
        //         onClick: () => {
        //           handleDelete(record);
        //         },
        //         danger: true,
        //       },
        //       conducted && {
        //         key: 4,
        //         label: "Export Survey",
        //         onClick: () => {
        //           exportSurvey(record._id);
        //         },
        //       },
        //     ],
        //   }}
        //   placement="bottom"
        // >
        //   <Button type="link">Actions</Button>
        // </Dropdown>
        <Link to={`/surveys/${record._id}`}>
          <Button type="primary">View</Button>
        </Link>
      ),
      align: "center",
      fixed: "right",
    },
  ];
  return (
    <Row>
      <Col span={24}>
        <Card
          title="Survey Management"
          extra={
            <Row justify={"end"} align="top" style={{ paddingBottom: "10px" }}>
              <Space>
                <Search
                  style={{
                    width: 220,
                  }}
                  placeholder="Search by Customer"
                  allowClear
                  enterButton
                  // loading={loading}
                  size="middle"
                  onSearch={onSearch}
                />

                <Button
                  type="primary"
                  icon={<LuPlus style={{ paddingTop: "2px" }} />}
                  onClick={() => {
                    setCreateSurvey(!createSurvey);
                  }}
                >
                  Create Survey
                </Button>
              </Space>
            </Row>
          }
        >
          <Table
            columns={columns}
            dataSource={surveys}
            size="small"
            onChange={handleTableChange}
            loading={isLoading}
            pagination={tableParams.pagination}
            scroll={{ x: "1000px" }}
          />
        </Card>
      </Col>
      <Modal
        title="Create Survey"
        open={createSurvey}
        okText={isEdit ? "Save" : "Create Survey"}
        onCancel={() => {
          setCreateSurvey(false);
          form.resetFields();
        }}
        onOk={form.submit}
      >
        <Form
          form={form}
          onFinish={handleCreateSurvey}
          okText="Create"
          layout="vertical"
          onCancel={() => {
            setCreateSurvey(!createSurvey);
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="customerId"
                label="Customer"
                rules={[
                  {
                    required: true,
                    message: "Please select customer",
                  },
                ]}
              >
                <DebounceSelect
                  value={customer}
                  placeholder="Select Customer"
                  fetchOptions={fetchCustomerList}
                  defaultValue={initialCustomer}
                  onChange={(newValue) => {
                    setCustomer(newValue);
                  }}
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item
                name="employeeId"
                label="Employee"
                rules={[
                  {
                    required: true,
                    message: "Please select employee",
                  },
                ]}
              >
                <DebounceSelect
                  value={employee}
                  placeholder="Select Employee"
                  fetchOptions={fetchEmployeeList}
                  defaultValue={initialEmployee}
                  onChange={(newValue) => {
                    setEmployee(newValue);
                  }}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="type"
                label="Type"
                rules={[
                  {
                    required: true,
                    message: "Please select the type",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select Type"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={[
                    {
                      value: "industrial",
                      label: "Industrial",
                    },
                    {
                      value: "residential",
                      label: "Residential",
                    },
                  ]}
                  tabIndex={1}
                />
              </Form.Item>

              <Form.Item
                name="surveyDate"
                label="Date"
                rules={[
                  {
                    required: true,
                    message: "Please select date",
                  },
                ]}
              >
                <DatePicker
                  format={'DD-MM-YYYY'}
                  // disabledDate={disabledDate}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="Edit Survey"
        open={editSurvey}
        okText={"Save Survey"}
        onCancel={() => {
          setEditSurvey(false);
          form.resetFields();
        }}
        onOk={form.submit}
        // onOk={handleEditSurvey}
      >
        <Form
          form={form}
          onFinish={handleEditSurvey}
          okText="Save"
          layout="vertical"
          onCancel={() => {
            setEditSurvey(!editSurvey);
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="customerId"
                label="Customer"
                rules={[
                  {
                    required: true,
                    message: "Please select customer",
                  },
                ]}
              >
                <DebounceSelect
                  value={customer}
                  placeholder="Select Customer"
                  fetchOptions={fetchCustomerList}
                  defaultValue={initialCustomer}
                  onChange={(newValue) => {
                    setCustomer(newValue);
                  }}
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item
                name="employeeId"
                label="Employee"
                rules={[
                  {
                    required: true,
                    message: "Please select employee",
                  },
                ]}
              >
                <DebounceSelect
                  value={employee}
                  placeholder="Select Employee"
                  fetchOptions={fetchEmployeeList}
                  defaultValue={initialEmployee}
                  onChange={(newValue) => {
                    setEmployee(newValue);
                  }}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="type"
                label="Type"
                rules={[
                  {
                    required: true,
                    message: "Please select the type",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select Type"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={[
                    {
                      value: "industrial",
                      label: "Industrial",
                    },
                    {
                      value: "residential",
                      label: "Residential",
                    },
                  ]}
                  tabIndex={1}
                />
              </Form.Item>

              <Form.Item
                name="surveyDate"
                label="Date"
                rules={[
                  {
                    required: true,
                    message: "Please select date",
                  },
                ]}
              >
                <DatePicker
                  format={'DD-MM-YYYY'}
                  // disabledDate={disabledDate}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Row>
  );
};

export default Surveys;
