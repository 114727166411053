import {
  Button,
  Row,
  Col,
  Card,
  Form,
  Modal,
  Radio,
  Breadcrumb,
  message,
  Spin
} from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import APICall from "../../../services/apiservices";
import { Link, useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import React from "react";
import { useGeolocated } from "react-geolocated";

const { confirm } = Modal;

const Equipments = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [floorEquipments, setFloorEquipments] = useState({});
  const [loading, setLoading] = useState(true);  // Add loading state
  const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
    userDecisionTimeout: null,
  });

  const location = useLocation();
  const params = useParams();
  const { customerName, buildingName, floor, pagename } = queryString.parse(location.search);

  useEffect(() => {
    getEquipmentData();
  }, []);

  const getEquipmentData = async () => {
    try {
      const res = await APICall(
        `/${pagename === "Visits" ? "visits" : "survey"}/conducted/building/${params.buildingId}/floor/${params.areaId}`,
        {},
        1
      );

      const equipments = res.data?.data?.equipments ?? {};  // Safely handle response
      if (Object.keys(equipments).length === 0) {
        // If empty response, set default values
        const defaultValues = {
          adaptor: "OK",
          hoseBox: "OK",
          branchPipe: "OK",
          hosePipe: "OK",
          hydrantValve: "OK",
          hoseReel: "OK",
          hoseBoxGlass: "OK",
          keyGlass: "OK",
          hydrantValveWheel: "OK",
          fireExtinguishers: "OK",
          hoseReelBallValve: "OK",
        };
        setFloorEquipments(defaultValues);
        form.setFieldsValue(defaultValues);
      } else {
        setFloorEquipments(equipments);
        form.setFieldsValue(equipments);
      }
    } catch (error) {
      console.error("Error fetching data", error);
      // Set default values in case of an error
      const defaultValues = {
        adaptor: "OK",
        hoseBox: "OK",
        branchPipe: "OK",
        hosePipe: "OK",
        hydrantValve: "OK",
        hoseReel: "OK",
        hoseBoxGlass: "OK",
        keyGlass: "OK",
        hydrantValveWheel: "OK",
        fireExtinguishers: "OK",
        hoseReelBallValve: "OK",
      };
      setFloorEquipments(defaultValues);
      form.setFieldsValue(defaultValues);
    } finally {
      setLoading(false);  // Stop the spinner
    }
  };

  const breadCrumbItems = [
    { label: pagename, to: `/${pagename.toLowerCase()}` },
    { label: customerName, to: `/surveys` },
    { label: "Buildings", to: -2 },
    { label: buildingName },
    { label: "Areas", to: -1 },
    { label: floor },
    { label: "Equipments" },
  ];

  const Equipments = [
    { name: "Adaptor", key: "adaptor" },
    { name: "Hose Box", key: "hoseBox" },
    { name: "Branch Pipe", key: "branchPipe" },
    { name: "Hose Pipe", key: "hosePipe" },
    { name: "Hydrant Valve", key: "hydrantValve" },
    { name: "Hose Reel", key: "hoseReel" },
    { name: "Fire Extinguisher", key: "fireExtinguishers" },
    { name: "Hose Box Glass", key: "hoseBoxGlass" },
    { name: "Key Glass", key: "keyGlass" },
    { name: "Hydrant valve Wheel", key: "hydrantValveWheel" },
    { name: "Hose Reel Ball Valve", key: "hoseReelBallValve" },
  ];

  const onFinish = async (formData) => {
    confirm({
      title: "Do you want to save?",
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        try {
          const response = await APICall(
            `/${pagename === "Visits" ? "visits" : "survey"}/${pagename === "Visits" ? params.visitId : params.surveyId
            }/conducted/building/${params.buildingId}/floor/${params.areaId}`,
            { equipments: formData, isConducted: true },
            3
          );
          if (response?.status) {
            message.success(`${pagename === "Visits" ? "Visit" : "Survey"} submitted.`);
            navigate(-1);
          }
        } catch (error) {
          message.error(`Failed to save ${pagename === "Visits" ? "visit" : "survey"}.`);
        }
      },
    });
  };

  const renderRadioGroup = (equipment) => (
    <Row gutter={16}>
      <Radio.Group defaultValue={form.getFieldValue(equipment)}>
        <Col lg={24} md={24} sm={24} xs={24}>
          <Radio value="OK">OK</Radio>
          <Radio value="Not OK">Not OK</Radio>
          <Radio value="NA">NA</Radio>
        </Col>
      </Radio.Group>
    </Row>
  );

  return (
    <>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} size="large" />
        </div>
      ) : (
        <Form form={form} name="elementForm" onFinish={onFinish} layout="vertical">
          <Card
            title={
              <Row>
                <Col lg={24} md={24} sm={20} xs={0}>
                  <Breadcrumb separator=">">
                    {breadCrumbItems.map((item, index) => (
                      <Breadcrumb.Item key={index}>
                        {item.to ? (
                          <Link to={item.to}>{item.label}</Link>
                        ) : (
                          item.label
                        )}
                      </Breadcrumb.Item>
                    ))}
                  </Breadcrumb>
                </Col>
              </Row>
            }
            style={{ width: "100%" }}
          >
            <Row gutter={16}>
              {Equipments.map((equipment, index) => (
                <Col lg={12} md={12} sm={12} xs={24} key={index}>
                  <Form.Item label={equipment.name} name={equipment.key}>
                    {renderRadioGroup(equipment.key)}
                  </Form.Item>
                </Col>
              ))}
            </Row>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Card>
        </Form>
      )}
    </>
  );
};

export default Equipments;
