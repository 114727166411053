import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  Table,
  Dropdown,
  Button,
  Modal,
  Typography,
  Input,
  Form,
  Tag,
  message,
  Descriptions,
} from "antd";
import APICall from "../../../services/apiservices";
import { Link, useLocation, useParams, useRevalidator } from "react-router-dom";
import moment from "moment";
import downloadXLSX from "../../../employee/features/survey/export";
import queryString from "query-string";
import dayjs from "dayjs";

const { TextArea } = Input;
const userData = JSON.parse(localStorage.getItem("userData"));

const AmcDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { amcId } = useParams();
  const [amcDetails, setAMCDetails] = useState([]);
  const [tableParams, setTableParams] = useState({
    filters: {
      searchTerm: "",
    },
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
  });

  const getAMCDetails = async (searchTerm, page, limit) => {
    try {
      const response = await APICall(`/amc/${amcId}`, {}, 1);
      setIsLoading(false);
      return response.data;
    } catch (error) {
      message.error("Failed to fetch AMC Details");
      setIsLoading(false);
    }
  };

  const fetchList = async () => {
    setIsLoading(true);
    const page = tableParams.pagination.current;
    const limit = tableParams.pagination.pageSize;
    const searchTerm = tableParams.filters.searchTerm || "";
    const response = await getAMCDetails(searchTerm, page, limit);
    console.log(response);
    setAMCDetails(response);
    setIsLoading(false);

    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: response.total,
      },
    });
  };

  useEffect(() => {
    fetchList();
  }, [JSON.stringify(tableParams)]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setAMCDetails([]);
    }
  };

  const onSearch = (value) => {
    setTableParams({
      ...tableParams,
      filters: {
        ...tableParams.filters,
        searchTerm: value,
      },
    });
  };

  const requirementsArray = {
    adaptor: {
      name: "Adaptor",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hoseBox: {
      name: "Hose Box",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    branchPipe: {
      name: "Branch Pipe",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hosePipe: {
      name: "Hose Pipe",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hydrantValve: {
      name: "Hydrant Valve",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hoseReel: {
      name: "Hose Reel",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    fireExtinguishers: {
      name: "Fire Extinguisher",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hoseBoxGlass: {
      name: "Hose Box Glass",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    keyGlass: {
      name: "Key Glass",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hydrantValveWheel: {
      name: "Hydrant Valve Wheel",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hoseReelBallValve: {
      name: "Hose Reel Ball Valve",
      existing: 0,
      good: 0,
      requirement: 0,
    },
  };

  const exportVisit = async (visitId) => {
    try {
      const res = await APICall(`/amcDetails/${visitId}/conducted`, {}, 1);
      const finalRequirements = JSON.parse(JSON.stringify(requirementsArray));
      const exportData = {
        organization: {
          name: "Fire Fight Safety Solutions",
          address:
            "Sr. No.85, Office No.7A/204, Kalpataru Estate, Jawalkar Nagar,Pimple Gurav, Pune - 411061",
          mobile: "9970548822",
          email: "info@ffss.in",
        },
        customer: {
          name: res?.data?.visit?.customerId?.userId?.name,
        },
        employee: {
          name: res?.data?.visit?.assignedTo?.userId?.name,
        },
        date: moment(res?.data?.visit?.scheduledDate).format("DD/MM/YYYY"),
        buildings: res.data.visitBuildings.map((sb) => {
          const areas = sb?.newFloors.map((nf, index) => ({
            area: nf?.floor.name,
            srNo: index + 1,
            equipments: nf?.equipments ?? {
              adaptor: "NA",
              hoseBox: "NA",
              branchPipe: "NA",
              hosePipe: "NA",
              hydrantValve: "NA",
              hoseReel: "NA",
              fireExtinguishers: "NA",
              hoseBoxGlass: "NA",
              keyGlass: "NA",
              hydrantValveWheel: "NA",
              hoseReelBallValve: "NA",
            },
          }));
          const requirements = JSON.parse(JSON.stringify(requirementsArray));
          areas.map((area) => {
            Object.keys(area.equipments).map((k) => {
              if (area.equipments[k] == "OK") {
                requirements[k].existing = requirements[k].existing + 1;
                requirements[k].good = requirements[k].good + 1;
                finalRequirements[k].existing =
                  finalRequirements[k].existing + 1;
                finalRequirements[k].good = finalRequirements[k].good + 1;
              } else if (area.equipments[k] == "Not OK") {
                requirements[k].existing = requirements[k].existing + 1;
                requirements[k].requirement = requirements[k].requirement + 1;
                finalRequirements[k].existing =
                  finalRequirements[k].existing + 1;
                finalRequirements[k].requirement =
                  finalRequirements[k].requirement + 1;
              }
            });
          });
          return {
            building: sb?.building?.name,
            notes: sb?.information?.notes ?? "",
            inCharge: sb?.information?.inCharge ?? "",
            areas,
            requirements,
          };
        }),
      };
      exportData.requirements = finalRequirements;
      downloadXLSX(exportData, "Visit");
    } catch (error) {
      message.error(error);
    }
  };

  const columns = [
    {
      title: "Sr. No.",
      key: "serialNumber",
      render: (_, record, index) => {
        return (
          tableParams.pagination.pageSize *
            (tableParams.pagination.current - 1) +
          index +
          1
        );
      },
    },

    {
      title: "Scheduled Date",
      key: "scheduledDate",
      render: (record) => {
        return moment(record?.scheduledDate).format("DD/MM/YY");
      },
      // align: "center",
    },
    {
      title: "Status",
      key: "status",
      render: (record) => {
        return (
          <Tag color={record.isConducted ? "blue" : "red"}>
            {record.isConducted ? "Conducted" : "Pending"}
          </Tag>
        );
      },
    },
    // {
    //   title: "Visit Date",
    //   dataIndex: "visitDate",
    //   key: "visitDate",
    //   align: "center",
    // },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) =>
        record.isConducted ? (
          <Button
            type="link"
            onClick={() => {
              exportVisit(record._id);
            }}
          >
            Export Visit
          </Button>
        ) : (
          "—"
        ),
      align: "center",
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Card
          title="AMC Details"
          loading={isLoading}
          extra={
            <Link to={`/amcs/${amcDetails._id}/export`}>
              <Button type="primary">Export</Button>
            </Link>
          }
        >
          <Descriptions bordered layout="vertical" style={{ width: "100%" }}>
            <Descriptions.Item label="AMC Number">
              {"AMC" + amcDetails?.amcNo?.toString().padStart(4, "0")}
            </Descriptions.Item>
            <Descriptions.Item label="Type">
              <Tag color="blue">{amcDetails?.type}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Duration(Month)">
              {amcDetails?.duration + " Months"}
            </Descriptions.Item>
            <Descriptions.Item label="Toatal Visits">
              {amcDetails?.visitsCount}
            </Descriptions.Item>
            <Descriptions.Item label="Contract Date">
              {dayjs(amcDetails?.contractDate).format("DD/MM/YYYY")}
            </Descriptions.Item>
            <Descriptions.Item label="Expiry Date">
              {dayjs(amcDetails?.expiryDate).format("DD/MM/YYYY")}
            </Descriptions.Item>
            <Descriptions.Item label="Systems">
              {amcDetails?.systemTypes?.map((system) => {
                return system?.systemTypeId.name + ", ";
              })}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Col>

      <Col span={24} style={{ marginTop: "10px" }}>
        <Card title="Visits">
          <Table
            columns={columns}
            dataSource={amcDetails?.visits}
            size="small"
            onChange={handleTableChange}
            loading={isLoading}
            scroll={{ x: "1000px" }}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default AmcDetails;
