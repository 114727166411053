import { Col, Form, Input, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";

const BOQDetailsField = ({ item }) => {
  return (
    <div>
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={5}>
            <Form.Item label="Name">
              <TextArea rows={1} value={item.name} readOnly />
            </Form.Item>
          </Col>

          <Col span={2}>
            <Form.Item label="Make">
              <Input value={item.make} readOnly /> 
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item label="Unit">
              <Input value={item.unit} readOnly /> 
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item label="Quantity">
              <Input type="number" value={item.quantity} readOnly /> 
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label="Material Rate">
              <Input type="number" value={item.materialRate} readOnly />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label="Material Total">
              <Input value={item.materialTotal} readOnly />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label="Labour Rate">
              <Input type="number" value={item.labourRate} readOnly /> 
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label="Labour Total">
              <Input value={item.labourTotal} readOnly /> 
            </Form.Item>
          </Col>
        </Row>
      </Form>

      {item.items && item.items.length > 0 && (
        <div style={{ paddingLeft: "20px", borderLeft: "2px solid #eee" }}>
          <h4>Sub-items:</h4>
          {item.items.map((subItem, index) => (
            <BOQDetailsField key={index} item={subItem} />
          ))}
        </div>
      )}

      {item.variations && item.variations.length > 0 && (
        <div style={{ paddingLeft: "20px", borderLeft: "2px solid #eee" }}>
          <h4>Sub-items:</h4>
          {item.variations.map((variation, index) => (
            <BOQDetailsField key={index} item={variation} /> 
          ))}
        </div>
      )}
    </div>
  );
};

export default BOQDetailsField;
