import React, { useEffect, useState } from "react";
import { LuPlus } from "react-icons/lu";
import { LiaExternalLinkAltSolid } from "react-icons/lia";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Tag,
  message,
  Modal,
  Dropdown,
  Spin,
  Input,
  Space,
  Descriptions,
} from "antd";
import { Link } from "react-router-dom";
import APICall, { serverURL } from "../../services/apiservices";
const { Search } = Input;

const { confirm } = Modal;

const Customers = ({ isActive }) => {
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [tableParams, setTableParams] = useState({
    filters: {
      searchTerm: "",
    },
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
  });

  const getCustomers = async (searchTerm, page, limit) => {
    console.log("serachTerm", searchTerm);
    console.log(searchTerm);
    try {
      if (searchTerm === undefined) searchTerm = "";
      const url =
        isActive === true
          ? `/customer?page=${page}&limit=${limit}&isActive=true&query=${searchTerm}`
          : `/customer?page=${page}&limit=${limit}&isInactive=true&query=${searchTerm}`;
      const response = await APICall(url, {}, 1);
      // setCustomers(response.data.documents);
      return response.data;
      // setIsLoading(false);
    } catch (error) {
      message.error("Failed to fetch customer details");
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   getCustomers(1, 10);
  // }, [reload]);

  const fetchList = async () => {
    setIsLoading(true);
    const page = tableParams.pagination.current;
    const limit = tableParams.pagination.pageSize;
    const searchTerm = tableParams.filters.searchTerm || "";

    const response = await getCustomers(searchTerm, page, limit);

    setCustomers(response.documents);
    console.log(response.documents);
    setIsLoading(false);

    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: response.total,
      },
    });
  };

  useEffect(() => {
    fetchList();
  }, [JSON.stringify(tableParams), isActive]);

  const columns = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => {
        return (
          tableParams.pagination.pageSize *
            (tableParams.pagination.current - 1) +
          index +
          1
        );
      },
    },
    {
      title: "Customer Number",
      key: "name",
      render: (_, record) => {
        return (
          record.customerCode
        );
      },
    },
    {
      title: "Customer Name",
      key: "name",
      render: (_, record) => {
        return record.user.name;
      },
    },
    {
      title: "Address",
      key: "address",
      render: (_, record) => {
        return record.user.address;
      },
    },
    {
      title: "State",
      key: "state",
      render: (_, record) => {
        return record.user.state;
      },
    },
    {
      title: "Mobile",
      key: "mobile",
      render: (_, record) => {
        return record.user.mobile;
      },
    },
    {
      title: "Email",
      key: "email",
      render: (_, record) => {
        return record.user.email;
      },
    },
    {
      title: "GSTIN",
      key: "GSTIN",
      render: (_, record) => {
        return (
          <a
            href={`https://www.mastersindia.co/gst-search/?gstin=${record.gstNumber}`}
            target="blank"
          >
            {record.gstNumber}
            <LiaExternalLinkAltSolid />
          </a>
        );
      },
    },
    {
      title: "Access Status",
      key: "accessStatus",
      render: (_, record) => (
        <Tag color={record.user.isActive ? "green" : "red"}>
          {record.user.isActive ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        // <Dropdown
        //   menu={{
        //     items: [
        //       {
        //         key: 1,
        //         label: <Link to={`/customers/manage/${record._id}`}>Edit</Link>,
        //       },
        //       {
        //         key: 2,
        //         label: <Link to={`/customers/${record._id}`}>View</Link>,
        //       },

        //       {
        //         key: 3,
        //         label: "Reset Password",
        //         onClick: () => {
        //           handleResetPassword(record);
        //         },
        //       },
        //       {
        //         key: 4,
        //         label: "Send Password via Email",
        //         onClick: () => {
        //           handleSendPassword(record);
        //         },
        //       },
        //       {
        //         key: 5,
        //         label: isActive ? "Disable" : "Enable",
        //         onClick: () => {
        //           handleActivate(record);
        //         },
        //         danger: true,
        //       },
        //     ],
        //   }}
        //   placement="bottom"
        // >
        //   <Button type="link">Actions</Button>
        // </Dropdown>
        <Link to={`/customers/${record._id}`}>
          <Button type="primary" ghost>
            View
          </Button>
        </Link>
      ),
      align: "center",
      fixed: "right",
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setCustomers([]);
    }
  };

  const onSearch = (value) => {
    console.log(value);
    setTableParams({
      ...tableParams,
      filters: {
        ...tableParams.filters,
        searchTerm: value,
      },
    });
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Card
            title={isActive ? "Customer Management" : "Inactive Customers"}
            extra={
              <Row
                justify={"end"}
                align="top"
                style={{ paddingBottom: "10px" }}
              >
                <Space>
                  <Search
                    style={{
                      width: 220,
                    }}
                    placeholder="Search a customer"
                    allowClear
                    enterButton
                    size="middle"
                    onSearch={onSearch}
                  />
                  {isActive && (
                    <Link to="/customers/manage">
                      <Button
                        type="primary"
                        icon={<LuPlus style={{ paddingTop: "2px" }} />}
                      >
                        Create Customer
                      </Button>
                    </Link>
                  )}
                </Space>
              </Row>
            }
          >
            <Table
              columns={columns}
              dataSource={customers}
              size="small"
              onChange={handleTableChange}
              pagination={{
                ...tableParams.pagination,
              }}
              scroll={{ x: "1000px" }}
              loading={isLoading}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Customers;
