import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {Form, Input, DatePicker, Button, Row, Col, Card, message, Dropdown, Table, Modal,} from "antd";
import DebounceSelect from "../../../components/debounceSelect/DebounceSelect";
import APICall from "../../../services/apiservices";
import { Select } from "antd";
import { LuPlus } from "react-icons/lu";
import dayjs from "dayjs";
const { confirm } = Modal;
const { Item } = Form;

const ManageQuoatation = ({ isEdit }) => {
  const [quotationData, setQuotationData] = useState();
  const [form] = Form.useForm();
  const [itemForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  const [cgst, setCgst] = useState(0);
  const [sgst, setSgst] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const [value, setValue] = useState(undefined);
  const [itemValue, setItemValue] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    const formData = itemForm.getFieldsValue();
    console.log(formData);
    itemForm.resetFields();
    setItemValue(undefined);
    if (selectedItems?.find((i) => i.itemId === formData.itemId)) {
      message.warning("Item already added to the quotation table");
      setIsLoading(false);
      return;
    }
    const itemsSelected = [...selectedItems];
    setSelectedItems([...itemsSelected, formData]);
    changeNumerics({ itemsSelected: [...itemsSelected, formData] });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getQuotationDetails = async () => {
    try {
      console.log(params.id);
      const response = await APICall(`/quotation/${params.id}`, {}, 1);
      setQuotationData(response.data);
      setIsLoading(false);
    } catch (error) {
      message.error(error.response.data.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isEdit) {
      setIsLoading(true);
      getQuotationDetails();
    }
  }, []);

  useEffect(() => {
    if (quotationData) {
      form.setFieldsValue({
        customerId: {
          value: quotationData?.quotation?.customer?._id,
          label: quotationData?.quotation?.customer?.user?.name,
        },
        date: dayjs(quotationData?.quotation?.date),
        subject: quotationData?.quotation?.subject,

        discount: quotationData?.quotation?.discount,
        sgst: quotationData?.quotation?.sgst,
        cgst: quotationData?.quotation?.cgst,
      });
      setSelectedItems(quotationData?.items);
      changeNumerics({ itemsSelected: quotationData?.items });
    }
  }, [quotationData]);

  const onFinish = async (values) => {
    if (selectedItems.length === 0) {
      message.error("Please add items");
      return;
    }
    setIsLoading(!isLoading);
    if (isEdit) {
      const data = { ...values };
      data.customerId = typeof data.customerId === "object" ? data.customerId.value : data.customerId;
      data.discount = data.discount || 0;
      data.cgst = parseInt(data.cgst) || 0;
      data.sgst = parseInt(data.sgst) || 0;
      data.discountAmount = discount;
      data.cgstAmount = cgst;
      data.sgstAmount = sgst;
      data.amount = amount;
      data.totalAmount = Math.round(totalAmount);
      data.items = [...selectedItems];
      data.subject = Array.isArray(data.subject) ? data.subject[0] : data.subject;
      const response = await APICall(`/quotation/${params.id}`, data, 3);
      if (response.data) {
        setIsLoading(false);
        message.success("Quotations has been updated successfully.");
        navigate("/Quotations");
      }
      try {
      } catch (error) {
        setIsLoading(false);
        message.error(error.response.data.message);
      }
    } else {
      try {
        const data = { ...values };
        console.log(data);
        data.discount = data.discount || 0;
        data.cgst = parseInt(data.cgst) || 0;
        data.sgst = parseInt(data.sgst) || 0;
        data.discountAmount = discount;
        data.cgstAmount = cgst;
        data.sgstAmount = sgst;
        data.amount = amount;
        data.termsAndConditions = data.termsAndConditions;
        data.totalAmount = Math.round(totalAmount);
        data.items = [...selectedItems];
        data.subject = Array.isArray(data.subject) ? data.subject[0] : data.subject;

        const response = await APICall(`/quotation`, data, 2);
        setIsLoading(false);

        if (response.data) {
          message.success("Quotations has been created successfully.");
          navigate(`/quotations/${response.data.data._id}`);
        }
      } catch (error) {
        setIsLoading(false);
        message.error(error.response.data.message);
      }
    }
  };


  const fetchCustomerList = async (searchValue) => {
    const res = await APICall(
      `/customer?page=1&limit=30&query=${searchValue}`,
      {},
      1
    );
    return res.data.documents.map((customer) => ({
      label: customer.user.name,
      value: customer._id,
    }));
  };

  const fetchItemList = async (searchValue) => {
    const res = await APICall(
      `/item?page=1&limit=30&name=${searchValue}`,
      {},
      1
    );
    return res.data.documents.map((item) => ({
      label: `${item.name}`,
      value: item._id,
      data: item,
    }));
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_, record) => {
        return record?.item?.name;
      },
    },
    {
      title: "HSN",
      dataIndex: "hsn",
      render: (_, record) => {
        return record?.item?.hsn;
      },
    },
    {
      title: "CGST (%)",
      dataIndex: "cgst",
      render: (_, record) => {
        return record?.item?.cgst ? record?.item?.cgst + "%" : 0;
      },
    },
    {
      title: "SGST (%)",
      dataIndex: "sgst",
      render: (_, record) => {
        return record?.item?.sgst ? record?.item?.sgst + "%" : 0;
      },
    },
    {
      title: "Price (₹)",
      dataIndex: "price",
      width: "15%",
      render: (_, record) => {
        return record?.price;
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      width: "10%",
      render: (_, record) => {
        return record?.quantity;
      },
    },
    {
      title: "Total Price (₹)",
      dataIndex: "total price",
      width: "15%",
      render: (_, record) => {
        return record?.price * record?.quantity;
      },
    },
    {
      title: "Actions",
      key: "actions",
      width: "15%",
      render: (_, record) => (
        <Dropdown
          menu={{
            items: [
              {
                key: 1,
                label: (
                  <span
                    style={{ color: "red" }}
                    onClick={() => {
                      confirm({
                        title: `Remove ${record?.item?.name}`,
                        content: `Do you want to remove ${record?.item?.name} from quotation table?`,
                        okText: "Yes",
                        okType: "danger",
                        cancelText: "No",
                        onOk: () => {
                          const itemsSelected = [...selectedItems];
                          setSelectedItems([
                            ...itemsSelected.filter(
                              (i) => i.itemId !== record.itemId
                            ),
                          ]);
                          message.success("Removed item!");
                          changeNumerics({
                            itemsSelected: [
                              ...itemsSelected.filter(
                                (i) => i.itemId !== record.itemId
                              ),
                            ],
                          });
                        },
                        onCancel: () => {
                          // message.info("Delete canceled");
                        },
                      });
                    }}
                  >
                    Remove
                  </span>
                ),
              },
            ],
          }}
          placement="bottom"
        >
          <Button type="link">Actions</Button>
        </Dropdown>
      ),
      align: "center",
    },
  ];

  const changeNumerics = ({ itemsSelected }) => {
    const formData = form.getFieldsValue();
    // const dis = parseInt(formData.discount) || 0;
    const dis = formData.discount || 0;
    let totalDiscount = 0;
    let totalDiscountedAmount = 0;
    let totalCGST = 0;
    let totalSGST = 0;
    let totalAmount = 0;
    if (itemsSelected) {
      for (const item of itemsSelected) {
        totalAmount += item.price * item.quantity;
        totalDiscount += item.price * item.quantity * (dis / 100);
        totalCGST +=
          (item.price * item.quantity -
            item.price * item.quantity * (dis / 100)) *
            (parseInt(item.item.cgst) / 100) || 0;
        totalSGST +=
          (item.price * item.quantity -
            item.price * item.quantity * (dis / 100)) *
            (parseInt(item.item.sgst) / 100) || 0;
      }
    } else {
      for (const item of selectedItems) {
        totalAmount += item.price * item.quantity;
        totalDiscount += item.price * item.quantity * (dis / 100);
        totalCGST +=
          (item.price * item.quantity -
            item.price * item.quantity * (dis / 100)) *
            (parseInt(item.item.cgst) / 100) || 0;
        totalSGST +=
          (item.price * item.quantity -
            item.price * item.quantity * (dis / 100)) *
            (parseInt(item.item.sgst) / 100) || 0;
      }
    }
    totalDiscountedAmount = totalAmount - totalDiscount;

    setAmount(parseFloat(totalAmount).toFixed(2));
    setDiscount(parseFloat(totalDiscount).toFixed(2));
    setDiscountedAmount(parseFloat(totalDiscountedAmount).toFixed(2));
    setCgst(parseFloat(totalCGST).toFixed(2));
    setSgst(parseFloat(totalSGST).toFixed(2));
    setTotalAmount(
      Math.round(
        parseFloat(totalDiscountedAmount + totalCGST + totalSGST).toFixed(2)
      )
    );
  };

  const validatePercentage = (_, value) => {
    if (!value) return Promise.resolve();

    const percentageRegex = /^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/;

    if (!percentageRegex.test(value)) {
      return Promise.reject("Please enter a valid percentage");
    }
    return Promise.resolve();
  };

  // function disabledDate(current) {
  //   const now = moment();
  //   return current && current < now.startOf("day");
  // }

  return (
    <>
      <Row gutter={10}>
        <Col span={24}>
          <Card title={"Create Quotation"}>
            <Form
              form={form}
              layout="vertical"
              // wrapperCol={{
              //     span: 20,
              // }}
              onFinish={onFinish}
              initialValues={{
                termsAndConditions: `Billing For Accessories will be after actual usage
Delivery and Installation charges will be borne by us
Free Demo And Training
Payment Terms: 100% Advance`,
              }}
            >
              <Row gutter={16}>
                <Col span={24}>
                  <Card title="Quotation Details" bordered={false}>
                    <Row gutter={10}>
                      <Col span={8}>
                        <Form.Item
                          name="customerId"
                          label="Select Customer"
                          rules={[
                            {
                              required: true,
                              message: "Please select the customer",
                            },
                          ]}
                        >
                          <DebounceSelect
                            value={value}
                            placeholder="John Doe"
                            fetchOptions={fetchCustomerList}
                            onChange={(newValue) => {
                              setValue(newValue);
                            }}
                            style={{ width: "100%" }}
                            tabIndex={1}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Item
                          label="Date"
                          name="date"
                          rules={[
                            {
                              required: true,
                              message: "Please enter date",
                            },
                          ]}
                        >
                          <DatePicker
                            format={'DD-MM-YYYY'}
                            // disabledDate={disabledDate}
                            placeholder="16/07/2023"
                            style={{ width: "100%" }}
                            tabIndex={2}
                          />
                        </Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          label="Quotation Subject"
                          name="subject"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the quotation subject",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Proposal for missing accessories"
                            tabIndex={3}
                            mode="tags"
                            options={[
                              {
                                label: "Proposal for fire extinguisher",
                                value: "Proposal for fire extinguisher",
                              },
                              {
                                label: "Proposal for AMC quotation",
                                value: "Proposal for AMC quotation",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={10}>
                      <Col span={8}>
                        {" "}
                        <Item
                          label="Discount (%)"
                          name="discount"
                          rules={[
                            {
                              validator: validatePercentage,
                            },
                          ]}
                        >
                          <Input
                            placeholder="10"
                            min={0}
                            type="number"
                            onChange={(e) =>
                              changeNumerics({
                                updatedDiscount: e.target.value,
                              })
                            }
                            tabIndex={4}
                          />
                        </Item>
                      </Col>
                      <Col span={12}>
                        <Item
                          label="Terms And Conditions"
                          name="termsAndConditions"
                          rules={[
                            {
                              required: true,
                              message: "Please enter terms and conditions",
                            },
                          ]}
                        >
                          <Input.TextArea rows={4} />
                        </Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              <br />

              <Card
                title="Items"
                extra={
                  <>
                    <Button
                      type="primary"
                      icon={<LuPlus style={{ paddingTop: "2px" }} />}
                      onClick={showModal}
                      tabIndex={7}
                    >
                      Add Item
                    </Button>
                  </>
                }
              >
                <Table
                  loading={isLoading}
                  columns={columns}
                  dataSource={selectedItems}
                  size="large"
                  footer={() => (
                    <>
                      <Row
                        style={{
                          paddingBottom: "10px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <Col span={20}>Amount</Col>
                        <Col>₹{amount}</Col>
                      </Row>

                      <Row
                        style={{
                          paddingBottom: "10px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <Col span={20}>Discount</Col>
                        <Col>₹{discount}</Col>
                      </Row>

                      <Row
                        style={{
                          paddingBottom: "10px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <Col span={20}>
                          <b>Discounted Amount</b>
                        </Col>
                        <Col>
                          <b>₹{discountedAmount}</b>
                        </Col>
                      </Row>

                      <Row
                        style={{
                          paddingBottom: "10px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <Col span={20}>CGST</Col>
                        <Col>₹{cgst}</Col>
                      </Row>

                      <Row
                        style={{
                          borderBottom: "2px solid gray",
                          paddingBottom: "10px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <Col span={20}>SGST</Col>
                        <Col>₹{sgst}</Col>
                      </Row>

                      <Row
                        style={{
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <Col span={20}>Total Price</Col>
                        <Col
                          style={{ fontWeight: "600", fontSize: "20px    " }}
                        >
                          ₹{totalAmount}
                        </Col>
                      </Row>
                    </>
                  )}
                />
              </Card>
              <Item style={{ marginTop: "20px", marginLeft: "20px" }}>
                <Button type="primary" htmlType="submit" tabIndex={8}>
                  {isEdit ? "Save" : "Create"}
                </Button>
              </Item>
            </Form>
          </Card>
        </Col>
      </Row>
      <Modal
        title="Add Item"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        okText="Add"
      >
        <Form
          form={itemForm}
          layout="vertical"
          onFinish={(value) => console.log(value)}
        >
          <Form.Item
            name="itemId"
            label="Select Item"
            rules={[
              {
                required: true,
                message: "Please select the customer",
              },
            ]}
          >
            <DebounceSelect
              value={itemValue}
              placeholder="e.g. Cylinder"
              fetchOptions={fetchItemList}
              onChange={(newValue, selectedItem) => {
                setItemValue(newValue);
                console.log(selectedItem);
                itemForm.setFieldsValue({
                  item: selectedItem.data,
                  price: selectedItem.data.price,
                  quantity: 1,
                });
              }}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Row gutter={8}>
            <Col span={12}>
              <Item label="Data" name="item" hidden></Item>
              <Item
                label="Price (₹)"
                name="price"
                rules={[
                  {
                    required: true,
                    message: "Please enter the price",
                  },
                ]}
              >
                <Input placeholder="e.g. 1000" type="number" />
              </Item>
            </Col>

            <Col span={12}>
              <Item
                label="Quantity"
                name="quantity"
                rules={[
                  {
                    required: true,
                    message: "Please enter quantity",
                  },
                ]}
              >
                <Input placeholder="e.g. 3" type="number" min={1} />
              </Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ManageQuoatation;
